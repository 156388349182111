import Transactions from './Tabs/Transactions'
import BreakdownDataContextProvider from './utils/context/PayoutsBreakdownDataContext'

export default function Finance() {
  return (
    <BreakdownDataContextProvider>
      <Transactions />
    </BreakdownDataContextProvider>
  )
}
