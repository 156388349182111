import { Box, Button, Dialog, DialogContent, Divider, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import React from 'react'
import TableChip from 'src/components/TableChip'
import Template from 'src/components/Template'
import { DisputeReasonConfigRow } from 'src/services/openApi'
import { Modules } from 'src/utils/config/config'
import { getShortOrderId } from 'src/utils/functions'
import { useReasonContext } from './ReasonContext'

export type RaiseDialogDataType = {
  order_id: string
  vb_platform: string
  slug: string
  item_level_error?: boolean
  num_days_to_dispute: number
}

interface RaiseDisputeDialogProps {
  data: null | RaiseDialogDataType
  onClose: () => void
  onRaise: (reason: string, data: RaiseDialogDataType, description?: string) => void
}

export default function RaiseDisputeDialog(props: RaiseDisputeDialogProps) {
  const reasons = useReasonContext()
  const [reason, setReason] = React.useState<DisputeReasonConfigRow>(null)
  const [textAreaValue, setTextAreaValue] = React.useState('')
  const variant = props.data?.num_days_to_dispute > 10 ? 'primary' : props.data?.num_days_to_dispute >= 5 ? 'warning' : 'error'

  const raiseDispute = async () => {
    props.onRaise(reason.reason, props.data, textAreaValue)
  }

  React.useEffect(() => {
    setReason(null)
    setTextAreaValue('')
  }, [props.data])

  return (
    <Dialog
      open={props.data !== null}
      maxWidth={'md'}
      fullWidth={true}
      onClose={() => {
        props.onClose()
      }}>
      {props.data !== null && (
        <DialogContent>
          <Typography
            variant="h3"
            sx={{ mb: 1 }}>
            {Modules.ErrorCharges} - Order #{getShortOrderId(props.data.vb_platform, props.data.order_id)}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mb: 2 }}>
            You have{' '}
            <TableChip
              label={`${props.data.num_days_to_dispute} days`}
              variant={variant}
              size={'small'}
            />{' '}
            left to dispute the {Modules.ErrorCharges.toLowerCase()} for this order
          </Typography>
          <FormControl
            fullWidth
            sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Choose a reason</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={reason ? reason.reason : ''}
              label="Choose a reason"
              onChange={(e) => {
                setReason(reasons.find((d) => d.reason === e.target.value))
              }}>
              {reasons
                .filter(
                  (e) =>
                    e.platform === props.data.vb_platform && ((props.data.item_level_error && e.item_level) || (!props.data.item_level_error && e.order_level))
                )
                .map((e) => {
                  return (
                    <MenuItem
                      key={e.reason}
                      value={e.reason}
                      sx={{ textTransform: 'capitalize' }}>
                      {e.reason.split('_').join(' ').toLowerCase()}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>

          <Box
            mb={2}
            sx={{
              visibility: reason && reason.description ? 'visible' : 'hidden'
            }}>
            <Typography variant="h5">Details</Typography>
            <Typography
              variant="caption"
              color="rgba(0,0,0,0.5)"
              component={'p'}
              sx={{ mb: 1 }}>
              Enter detail or choose a template
            </Typography>
            <TextField
              label="Enter Description"
              placeholder="Describe why"
              value={textAreaValue}
              onChange={(e) => {
                setTextAreaValue(e.target.value)
              }}
              multiline
              maxRows={4}
              minRows={4}
            />
            {!!reason && !!reason.templates.length && (
              <>
                <Stack
                  direction="row"
                  alignItems={'center'}
                  spacing={1}
                  sx={{ my: 2 }}>
                  <Box flex={1}>
                    <Divider />
                  </Box>
                  <Typography variant={'subtitle2'}>OR</Typography>
                  <Box flex={1}>
                    <Divider />
                  </Box>
                </Stack>

                <Stack
                  direction="column"
                  spacing={1}
                  maxHeight="200px"
                  overflow={'auto'}>
                  {reason.templates.map((item) => {
                    return (
                      <Template
                        description={item.text}
                        onClick={() => {
                          setTextAreaValue(item.text)
                        }}
                      />
                    )
                  })}
                </Stack>
              </>
            )}
          </Box>

          <Box
            display="flex"
            flexDirection={'row'}
            justifyContent="flex-end"
            gap={2}>
            <Button
              variant="text"
              onClick={() => {
                props.onClose()
              }}>
              Cancel
            </Button>
            <Button
              variant="contained"
              disabled={reason === null}
              onClick={() => {
                raiseDispute()
              }}>
              SUBMIT
            </Button>
          </Box>
        </DialogContent>
      )}
    </Dialog>
  )
}
