import { Close, Search } from '@mui/icons-material'
import { Box, IconButton, InputAdornment, TextField } from '@mui/material'
import React from 'react'

interface SidebarSearchProps {
  searchText: string
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
}

const SidebarSearch = ({ searchText, handleChange }: SidebarSearchProps) => {
  return (
    <Box sx={{ my: '10px', mx: '15px' }}>
      <TextField
        sx={{
          '& .MuiOutlinedInput-root': {
            bgcolor: '#091F2D',
            color: '#FFF',
            fontSize: '12px'
          },
          '& .MuiOutlinedInput-input': {
            px: '13px',
            py: '13px'
          }
        }}
        placeholder="Search navigation bar"
        value={searchText}
        onChange={handleChange}
        InputLabelProps={{ shrink: false }}
        InputProps={{
          startAdornment: (
            <InputAdornment
              position="start"
              sx={{
                m: 0,
                color: '#FFF',
                opacity: 0.3
              }}>
              <Search
                sx={{
                  width: '16px',
                  height: '16px'
                }}
              />
            </InputAdornment>
          ),
          endAdornment: (
            <IconButton
              sx={{
                height: '22px',
                width: '22px',
                display: searchText ? 'flex' : 'none',
                '&:hover': {
                  bgcolor: '#fff3'
                }
              }}
              onClick={() => handleChange(null)}>
              <Close
                sx={{
                  width: '18px',
                  height: '18px',
                  color: '#fff'
                }}
              />
            </IconButton>
          )
        }}
      />
    </Box>
  )
}

export default SidebarSearch
