import { PsychologyAlt } from '@mui/icons-material'
import { Box, Skeleton, Stack, SxProps, Tooltip } from '@mui/material'
import { ReactNode } from 'react'
import { usePayoutsBreakdownDataContext } from '../utils/context/PayoutsBreakdownDataContext'

interface DataCompProps {
  sx?: SxProps
  rows: {
    sx?: SxProps
    indicatorColor?: string
    hide?: boolean
    hideIndicator?: boolean
    onClick?: () => void
    cols: {
      label: ReactNode
      flex: number | string
      sx?: SxProps
      tooltip?: string
      tooltipColor?: string
      loading?: boolean
      loadingSx?: SxProps
    }[]
  }[]
}

export default function DataComp(props: DataCompProps) {
  const { fontSizes } = usePayoutsBreakdownDataContext()

  return (
    <>
      <Box
        sx={{
          borderRadius: '8px',
          px: '20px',
          py: '15px',
          border: '1px solid #E6E6E6',
          ...props.sx
        }}>
        {props.rows.map((rowData, rowIndex) => {
          if (rowData.hide) return null

          return (
            <Stack
              key={rowIndex}
              direction="row"
              alignItems={'center'}
              gap="10px"
              sx={{ px: '10px', ...rowData.sx }}
              onClick={rowData.onClick}>
              {rowData.cols.map((colData, colIndex) => {
                return (
                  <Box
                    key={`${rowIndex}-${colIndex}`}
                    sx={{
                      flex: colData.flex,
                      fontSize: fontSizes['12'],
                      color: 'black',
                      fontWeight: colIndex === 0 ? 600 : undefined,
                      display: colIndex === 0 ? 'flex' : 'block',
                      flexDirection: 'row',
                      alignItems: 'center',
                      gap: '10px',
                      ...colData.sx
                    }}>
                    {colIndex === 0 && !rowData.hideIndicator && (
                      <Box
                        sx={{
                          minWidth: '10px',
                          height: '10px',
                          bgcolor: rowData.indicatorColor
                        }}
                      />
                    )}
                    {colData.loading ? (
                      <Skeleton
                        variant="rounded"
                        sx={{
                          width: '150px',
                          height: '20px',
                          ...colData.loadingSx
                        }}
                      />
                    ) : (
                      <span>{colData.label}</span>
                    )}
                    {colData.tooltip && (
                      <Tooltip
                        title={colData.tooltip || ''}
                        placement="right"
                        componentsProps={{
                          tooltip: {
                            sx: {
                              bgcolor: '#000',
                              px: 2,
                              py: 1.5,
                              maxWidth: '400px',
                              fontSize: fontSizes['12']
                            }
                          },
                          arrow: {
                            sx: {
                              color: '#000'
                            }
                          }
                        }}
                        arrow>
                        <Box
                          display="flex"
                          alignItems="center"
                          border="1px solid #E6E6E6E6"
                          borderRadius="2px"
                          bgcolor="#fff4">
                          <PsychologyAlt sx={{ fontSize: fontSizes['18'], color: colData.tooltipColor ? colData.tooltipColor : 'rgba(47, 74, 90, 0.5)' }} />
                        </Box>
                      </Tooltip>
                    )}
                  </Box>
                )
              })}
            </Stack>
          )
        })}
      </Box>
    </>
  )
}
