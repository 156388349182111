const tableIdMap = new Map()

export function addTableIdToRequest(prop, args) {
  const tableId = tableIdMap.get(prop)

  // Check if the API is paginated, offset is present in request, table_id is there in the map
  if (prop?.toLowerCase().includes('paginated') && Array.isArray(args) && typeof args[0] === 'object' && args[0].offset && args[0].offset > 0 && tableId) {
    // Appending table_id to the request body
    args[0] = {
      ...args[0],
      table_id: tableId
    }
  }

  return args
}

export function updateTableId(prop: string, result: any) {
  // Update the table_id if the API is paginated and table_id is received in the response
  if (prop?.toLowerCase().includes('paginated') && typeof result === 'object' && result.table_id) {
    tableIdMap.set(prop, result.table_id)
  }
}
