import { get } from 'lodash'

function getOrgRoutesInArrayList(orgRoutes) {
  return orgRoutes.map((route) => {
    if (typeof route === 'object') {
      return get(route, 'uid', '')
    }
    return route
  })
}

function getRouteUidByKey(orgRoutes, key) {
  return orgRoutes.find((route) => route.key === key).uid
}

export { getOrgRoutesInArrayList, getRouteUidByKey }
