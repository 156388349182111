import { MultiSelect } from '@LoopKitchen/loop-ui'
import { Box, Button, Checkbox, Skeleton, SxProps, Tooltip, Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useErrorData } from 'src/context/ErrorContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { DefaultService, ErrorFiltersForQuery, OrderChannels } from 'src/services/openApi'
import { pluralize } from 'src/utils/functions/pluralize'

interface ChannelSelectProps {
  extraApiDependencies?: React.DependencyList
  extraRequestBody?: Partial<ErrorFiltersForQuery>
  selectButtonSx?: SxProps
  value: string[]
  onChange: (value: string[]) => void
}

export default function ChannelSelect(props: ChannelSelectProps) {
  const { extraApiDependencies = [], extraRequestBody = {}, selectButtonSx, value, onChange } = props
  const { handleError } = useErrorData()
  const { getFilters, getFiltersV2 } = useNewFilterContext()
  const [channelData, setChannelData] = React.useState<OrderChannels[]>([])
  const [loading, setLoading] = React.useState(false)

  const options = React.useMemo(() => {
    if (!Array.isArray(channelData)) {
      return []
    }
    return channelData.map((item) => ({
      label: item.channel?.replaceAll('_', ' '),
      value: item.channel
    }))
  }, [channelData])

  React.useEffect(() => {
    const getChannelData = async () => {
      setLoading(true)
      try {
        const res = await DefaultService.callOrderChannelsApiOrderChannelsPost({
          ...getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date'], true),
          ...extraRequestBody
        })
        setChannelData(res)
      } catch (err) {
        handleError(err.message)
      }
      setLoading(false)
    }
    getChannelData()
  }, [...getFilters(['b_name', 'vb_name', 'vb_platform', 'chain', 'am_name', 'start_date', 'end_date']), ...extraApiDependencies])

  const height = '40px'
  const width = '120px'
  return (
    <>
      {loading ? (
        <Skeleton
          variant="rectangular"
          height={height}
          width={width}
        />
      ) : (
        <MultiSelect
          disableSort
          disableSearch
          options={options}
          value={value}
          onChange={(value) => {
            onChange(value)
          }}
          renderValue={() => {
            const text = value.length === 0 ? 'Channel' : `(${value.length}) ${pluralize('Channel', value.length)} Selected`
            return (
              <Tooltip
                arrow
                placement="top"
                title={text}>
                <span style={{ whiteSpace: 'nowrap', textOverflow: 'ellipsis', overflow: 'hidden' }}>{text}</span>
              </Tooltip>
            )
          }}
          renderOption={(option, params) => {
            const { label, value: optionValue } = option
            const isSelected = get(params, 'isSelected', false)
            return (
              <>
                <Tooltip title={option.label}>
                  <Box
                    sx={{
                      width: '100%',
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap: '5px',
                      position: 'relative'
                      // height: '100%'
                    }}>
                    <Checkbox
                      checked={isSelected}
                      sx={{
                        '& .MuiSvgIcon-root': { fontSize: 16 },
                        color: 'rgba(230, 230, 230, 0.90)'
                      }}
                    />
                    <Typography
                      fontWeight={400}
                      color={'#000'}
                      sx={{ fontSize: '12px', flex: 1, display: 'inline-block' }}
                      noWrap>
                      {option.label === '' ? `No ${label ? label : 'value'}` : option.label}
                    </Typography>

                    <Button
                      variant="text"
                      className="selectOnlyBtn"
                      onClick={(e) => {
                        e.stopPropagation()
                        onChange([optionValue])
                      }}
                      sx={{
                        display: 'none',
                        color: '#130F26',
                        backgroundColor: '#ECECEC',
                        fontSize: '12px',
                        padding: '4px 8px'
                      }}>
                      Only
                    </Button>
                  </Box>
                </Tooltip>
              </>
            )
          }}
          selectButtonSx={{
            height: height,
            width: width,
            bgcolor: '#F6f6f6',
            '&:hover': {
              bgcolor: '#EEEEEE'
            },
            borderRadius: '4px',
            fontFamily: 'Sora',
            fontWeight: '600 !important',
            fontSize: '12px',
            px: '16px',
            py: '5px',
            ...(selectButtonSx || {})
          }}
        />
      )}
    </>
  )
}
