import { CsvBuilder } from 'filefy'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import { useAuth } from 'src/context/AuthContext'
import { DefaultService, MetricsKPIDrillDownOutput, MetricsKPIDrillDownOutputPaginated, UniqueValuesForColumn } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'
import metricsCSVGenerator from '../../../../../utils/workers/functions/metricsCSVGenerator'
import allJsonData from '../jsonConfigs'
import { BreakdownEnums, BreakdownEnumsType } from '../jsonConfigs/types'

export type DrillDownColumnType = { title: string; field: string; hidden?: boolean }

export type TableDataResponseType = MetricsKPIDrillDownOutputPaginated

export type FilterDataResponseType = UniqueValuesForColumn

export type DateRangeCompareDataResponseType = {
  previous_range: string[]
  current_range: string[]
  current: {
    [key: string]: MetricsKPIDrillDownOutput
  }
  previous: {
    [key: string]: MetricsKPIDrillDownOutput
  }
  percentages: {
    [key: string]: MetricsKPIDrillDownOutput
  }
}

export default function useServiceHook(
  // Should be used later
  tempDrillDownName: keyof typeof allJsonData
) {
  const { currentUser } = useAuth()

  const apiFunctions = React.useMemo(() => {
    const obj = allJsonData[tempDrillDownName].apiFunctions
    const tableData = get(DefaultService, obj.tableData, null)
    const dateRangeCompareData = get(DefaultService, obj.dateRangeCompareData, null)
    const filterData = get(DefaultService, obj.filterData, null)
    const exportData = get(DefaultService, obj.exportData, null)
    return {
      tableData,
      dateRangeCompareData,
      filterData,
      exportData
    }
  }, [get(allJsonData, tempDrillDownName, null)])

  const getTableData = async (obj: {
    b_name_in?: string
    vb_name_in?: string
    vb_platform_in?: string
    breakdown_column: BreakdownEnumsType
    start_date_in: string
    end_date_in: string
    limit: number
    offset: number
    order_by?: string
    ascending?: boolean
  }) => {
    const org = get(currentUser, 'org', '')
    const apiFunc = apiFunctions.tableData
    if (!apiFunc || typeof apiFunc !== 'function') {
      throw new Error(`API function for ${obj.breakdown_column} not available`)
    }
    const res = await apiFunc(
      {
        ...obj,
        chain_in: org
      },
      true
    )

    return res as TableDataResponseType
  }

  const getDateRangeCompareServerData = async (obj: {
    b_name_in?: string
    vb_name_in?: string
    vb_platform_in?: string
    breakdown_column: BreakdownEnumsType
    start_date_in: string
    end_date_in: string
  }) => {
    const org = get(currentUser, 'org', '')
    const apiFunc = apiFunctions.dateRangeCompareData
    if (!apiFunc || typeof apiFunc !== 'function') {
      throw new Error('API not available')
    }
    const res = await apiFunc({
      ...obj,
      compare_by: 'breakdown_column',
      chain_in: org
    })
    return res
  }

  const getDateRangeCompareData = async (obj: {
    b_name_in?: string
    vb_name_in?: string
    vb_platform_in?: string
    breakdown_column: BreakdownEnumsType
    start_date_in: string
    end_date_in: string
  }) => {
    const res = await getDateRangeCompareServerData(obj)

    const current_range = res.current_range
    const previous_range = res.previous_range
    const current = res.current_range_data.reduce(
      (acc, item) => {
        acc[item.breakdown_column] = item
        return acc
      },
      {} as { [key: string]: (typeof res.current_range_data)[0] }
    )
    const previous = res.previous_range_data.reduce(
      (acc, item) => {
        acc[item.breakdown_column] = item
        return acc
      },
      {} as { [key: string]: (typeof res.previous_range_data)[0] }
    )
    const percentages =
      res.percentages?.reduce(
        (acc, item) => {
          acc[item.breakdown_column] = item
          return acc
        },
        {} as { [key: string]: (typeof res.percentages)[0] }
      ) || {}

    return {
      current_range,
      previous_range,
      current,
      previous,
      percentages
    } as DateRangeCompareDataResponseType
  }

  const getFilterData = async (obj: {
    b_name_in?: string
    vb_name_in?: string
    vb_platform_in?: string
    breakdown_column: BreakdownEnumsType
    start_date_in: string
    end_date_in: string
  }) => {
    const org = get(currentUser, 'org', '')
    const apiFunc = apiFunctions.filterData
    if (!apiFunc || typeof apiFunc !== 'function') {
      throw new Error('API not available')
    }
    const res = await apiFunc({
      ...obj,
      chain_in: org
    })

    return res as FilterDataResponseType
  }

  const getExportData = async (obj: {
    b_name_in?: string
    vb_name_in?: string
    vb_platform_in?: string
    breakdown_column: BreakdownEnumsType
    start_date_in: string
    end_date_in: string
  }) => {
    const org = get(currentUser, 'org', '')
    const apiFunc = apiFunctions.exportData
    if (!apiFunc || typeof apiFunc !== 'function') {
      throw new Error('API not available')
    }
    const res = await apiFunc({
      ...obj,
      chain_in: org
    })

    return res
  }

  const exportDataWithDeltaValues = async (obj: {
    fileName: string
    columns: DrillDownColumnType[]
    filterObj: {
      b_name_in?: string
      vb_name_in?: string
      vb_platform_in?: string
      breakdown_column: BreakdownEnumsType
      start_date_in: string
      end_date_in: string
    }
    onSuccess: () => void
    onError: (err: string) => void
  }) => {
    const { fileName, columns, filterObj, onSuccess, onError } = obj
    if (!columns || columns.length === 0) {
      throw new Error('No columns provided for export')
    }
    const res = await getDateRangeCompareServerData(filterObj)
    const tempColumns = [{ title: 'Store ID', field: 'store_id' }]
    columns
      .filter((e) => !e.hidden)
      .forEach((curr) => {
        if (curr.field === BreakdownEnums.SLUG) {
          tempColumns.push({ title: filterNames.vb_name, field: 'vb_name' }, { title: filterNames.vb_platform, field: 'vb_platform' })
        } else {
          tempColumns.push(curr)
        }
      })
    metricsCSVGenerator({
      cols: tempColumns,
      d: res,
      onSuccess: (data) => {
        const { headers, rows } = data
        const csvBuilder = new CsvBuilder(fileName)
        csvBuilder.setColumns(headers)
        csvBuilder.addRows(rows)
        csvBuilder.exportFile()
        onSuccess()
      },
      onError: onError
    })
  }

  const getDeltaValue = (dateRangeCompareData: DateRangeCompareDataResponseType, breakdownKey: string, key: keyof MetricsKPIDrillDownOutput) => {
    if (!dateRangeCompareData) {
      return {}
    }
    const current = get(dateRangeCompareData, ['current', breakdownKey, key], 0)
    const previous = get(dateRangeCompareData, ['previous', breakdownKey, key], 0)
    const delta = get(dateRangeCompareData, ['percentages', breakdownKey, key], 0)
    const currentRange = get(dateRangeCompareData, 'current_range', [] as string[])
    const previousRange = get(dateRangeCompareData, 'previous_range', [] as string[])
    const previousRangeTooltip =
      previousRange[0] && previousRange[1]
        ? `From ${moment(previousRange[0]).format('D MMM, YYYY')}, To ${moment(previousRange[1]).format('D MMM, YYYY')}`
        : null
    if (typeof current !== 'number' || typeof previous !== 'number' || typeof delta !== 'number') {
      return {}
    }
    return { delta, current, previous, currentRange, previousRange, previousRangeTooltip }
  }

  return { getTableData, getFilterData, getExportData, exportDataWithDeltaValues, getDateRangeCompareData, getDeltaValue }
}
