import { CustomTypoContainer, Topbar as LoopUITopbar, Typo, theme } from '@LoopKitchen/loop-ui'
import styled from '@emotion/styled'
import { FilterAltSharp, Help, KeyboardArrowDownRounded, PestControl, Phone, Search, SearchOutlined, SubdirectoryArrowRight } from '@mui/icons-material'
import EditNotificationsIcon from '@mui/icons-material/EditNotifications'
import {
  AppBar,
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogContent,
  IconButton,
  InputAdornment,
  List,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  Toolbar,
  Typography,
  useMediaQuery,
  useScrollTrigger
} from '@mui/material'
import { debounce, get, uniqBy } from 'lodash'
import { posthog } from 'posthog-js'
import React, { useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import HamburgerIcon from 'src/components/Icon/HamburgerIcon'
import NewFilterComponent from 'src/components/NewFilterComponent/NewFilterComponent'
import { useAuth } from 'src/context/AuthContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import { useSnackData } from 'src/context/SnackContext'
import { useTopbarContext } from 'src/context/TopbarContext'
import routeMap, { RouteMap } from 'src/routes/routeMap'
import { BASE_AUTH_URL, BASE_DOCS_URL, Modules } from 'src/utils/config/config'
import { createAuthTokens } from 'src/utils/firebaseFunctions/createAuthTokens'
import { canAccessNotificationsRoute, canAccessUserManagementRoute, getAccessLevel } from 'src/utils/functions/accessLevel'
import { drawerCloseWidthPx, drawerWidthPx } from './components/PrivateLayoutDrawer'

export default function Topbar({
  drawerState,
  children,
  title,
  handleReport,
  disableReport = false
}: {
  title: JSX.Element | React.ReactNode
  drawerState: boolean
  children: JSX.Element | React.ReactNode
  handleReport: () => void
  disableReport?: boolean
}) {
  const navigate = useNavigate()
  const sm = useMediaQuery(theme.breakpoints.down('sm'))
  const lg = useMediaQuery(theme.breakpoints.down('lg'))
  const { toggleDrawer } = usePrivateLayoutDrawerContext()
  const { logout, currentUser, orgConfig } = useAuth()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0
  })
  const { hideFilters, filterProps } = useTopbarContext()
  const [openFilterDrawer, setOpenFilterDrawer] = React.useState(false)
  const [filterBarOffsetHeight, setFilterBarOffsetHeight] = React.useState<number>(0)
  const filterBarRef = React.useRef<HTMLElement>(null)

  React.useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      setFilterBarOffsetHeight(filterBarRef.current?.offsetHeight || 0)
    })

    if (filterBarRef.current) resizeObserver.observe(filterBarRef.current)

    return () => {
      resizeObserver.disconnect()
    }
  }, [])

  const menuOptions = React.useMemo(() => {
    const result: {
      label: string
      path: string
      icon: React.ReactNode | JSX.Element
      accessReqd: boolean
    }[] = []

    result.push({
      path: '/user/profile',
      label: 'User Profile',
      icon: 'account_circle',
      accessReqd: false
    })

    if (canAccessUserManagementRoute(orgConfig, currentUser)) {
      result.push({
        label: 'User Management',
        icon: 'people',
        path: '/user/management',
        accessReqd: false
      })
    }

    if (canAccessNotificationsRoute(orgConfig, currentUser)) {
      result.push({
        label: 'Notifications',
        icon: <EditNotificationsIcon />,
        path: '/notification-settings',
        accessReqd: false
      })
    }

    return result
  }, [currentUser, orgConfig])

  const renderOptions = () => {
    const [search, setSearch] = React.useState('')
    const { openError } = useSnackData()
    const [result, setResult] = React.useState<RouteMap[]>([])
    const [open, setOpen] = React.useState(false)
    const inputRef = React.useRef(null)
    const [accessRoutes, setAccessRoutes] = React.useState<any[]>([])
    const macPlatform = navigator.platform.toUpperCase().indexOf('MAC') !== -1
    const [searchKeyword, setSearchKeyword] = React.useState('')
    const [magicLinkLoading, setMagicLinkLoading] = React.useState<boolean>(false)

    const StyledListItem = styled(MenuItem)({
      fontSize: 18
    })

    // ===== Functions =====
    function getKeys(data: any) {
      const result = []
      for (let item of data) {
        result.push(`/${item.key}`)
        if (item.subNav) {
          for (let subItem of item.subNav) {
            result.push(`/${item.key}/${subItem.key}`)
          }
        }
      }
      return result
    }

    const accessLevelFunc = useMemo(() => getAccessLevel(currentUser), [currentUser])

    const handleNavigation = (path: string | RouteMap | any) => {
      posthog?.capture('Search result click', {
        email: currentUser.email,
        name: currentUser.name,
        org: currentUser.org,
        path: path?.path || path,
        pathName: path.name || path
      })
      if (typeof path === 'string') navigate(path)
      else navigate(path.path)
      setOpen(false)
    }

    const focusOnInput = () => {
      setTimeout(() => {
        try {
          // @ts-ignore
          inputRef.current.focus()
        } catch (error) {
          // do nothing
        }
      }, 500)
    }

    function searchTags(tags: string[], keyword: string) {
      return tags.filter((tag: string) => tag.toLocaleLowerCase().includes(keyword.toLocaleLowerCase()))
    }
    function searchKeywordFromRouteMap(keyword: string) {
      let result: any[] = []
      routeMap.every((route: RouteMap) => {
        let filteredItems = []
        filteredItems = searchTags(route.tags, keyword)

        if (filteredItems.length > 0) {
          result.push(route)
        }

        if (route.childRoutes && route.childRoutes.length > 0) {
          const childRoutes: any[] = []
          get(route, 'childRoutes', []).forEach((childRoute: RouteMap) => {
            const filteredChildRouteTags = searchTags(childRoute.tags, keyword)
            if (filteredChildRouteTags.length > 0) childRoutes.push(childRoute)
          })
          if (childRoutes.length > 0) result.push({ ...route, childRoutes })
        }
        return true
      })
      //filtering allowed routes
      if (accessRoutes.length > 0) result = result.filter((item: any) => accessRoutes.includes(item.path))
      return uniqBy(result, 'name')
    }
    const postHogSeachKeywordTyped = React.useCallback(
      debounce(function () {
        posthog?.capture('Search keyword typed', {
          email: currentUser.email,
          name: currentUser.name,
          org: currentUser.org,
          keyword: searchKeyword
        })
      }, 2000),
      []
    )

    const callFunction = () => (window.location.href = `tel:+17076414146`)
    const helpFunction = async () => {
      setMagicLinkLoading(true)
      try {
        const res = await createAuthTokens({
          userUidList: [currentUser.uid]
        })
        if (res && res.userList && res.userList.length > 0) {
          const url = new URL(BASE_AUTH_URL)
          url.searchParams.set('magic_token', res.userList[0].token)
          url.searchParams.set('redirect_uri', BASE_DOCS_URL)
          window.open(url.href, '_blank')
        } else {
          throw new Error('Error generating magic link')
        }
      } catch (err) {
        openError(err.message)
      }
      setMagicLinkLoading(false)
    }

    // ===== Effects =====
    React.useEffect(() => {
      if (currentUser && orgConfig) {
        const accessLevel = accessLevelFunc
        const tempRoutes = get(orgConfig, `accessConfig.${accessLevel}.navConfig`, [])
        const tempData = getKeys(tempRoutes)

        setAccessRoutes(tempData)
      }
    }, [currentUser, orgConfig])

    React.useEffect(() => {
      open &&
        posthog?.capture('Search initiated', {
          email: currentUser.email,
          name: currentUser.name,
          org: currentUser.org
        })
    }, [open])

    React.useEffect(() => {
      function handleKeyDown(event: any) {
        if (event.keyCode === 75 && event.ctrlKey) {
          // Ctrl+K key pressed
          event.preventDefault()
          setOpen(true)
          focusOnInput()
        }
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
          // Command + K pressed
          event.preventDefault()
          setOpen(true)
          focusOnInput()
        }
      }
      window.addEventListener('keydown', handleKeyDown)
      return () => {
        window.removeEventListener('keydown', handleKeyDown)
      }
    }, [])

    React.useEffect(() => {
      if (search) {
        const matchingRoutes = searchKeywordFromRouteMap(search)
        setResult(matchingRoutes)
        setOpen(true)
      }
    }, [search, routeMap])

    React.useEffect(() => {
      postHogSeachKeywordTyped()
    }, [searchKeyword])

    return (
      <>
        <Dialog
          open={open}
          onClose={() => setOpen(false)}
          fullWidth
          maxWidth={false}
          PaperProps={{
            sx: {
              position: 'absolute',
              top: 0,
              width: drawerState ? 'calc(100vw - 400px)' : 'calc(100vw - 160px)',
              left: drawerState ? '300px' : 60
            }
          }}>
          <DialogContent>
            <Box
              display="flex"
              justifyContent="center"
              flexWrap="wrap">
              <TextField
                focused
                inputRef={inputRef}
                sx={{
                  '& .MuiOutlinedInput-notchedOutline': {
                    border: 'none',
                    borderBottom: '1px solid rgba(0,0,0,0.1)'
                  },
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderRadius: 0
                    }
                  },
                  // ml: 'auto',
                  width: '95%',
                  fontSize: 16,
                  fontWeight: 600
                }}
                value={search}
                onChange={(e) => {
                  setSearch(e.target.value)
                  setSearchKeyword(e.target.value)
                }}
                inputProps={{
                  style: {
                    padding: 30
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search />
                    </InputAdornment>
                  ),
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box
                        sx={{
                          py: 1,
                          px: 2,
                          bgcolor: '#F0F0F0',
                          border: '1px solid #C0C0C0',
                          borderRadius: '4px',
                          m: 1
                        }}>
                        <Typo
                          fs={12}
                          fw={400}
                          sx={{ opacity: 0.5 }}>
                          {macPlatform ? 'Cmd' : 'Ctrl'}+K
                        </Typo>
                      </Box>
                    </InputAdornment>
                  )
                }}
                placeholder="Search anything in platform"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  width: '100%',
                  paddingLeft: 32,
                  paddingRight: 32,
                  paddingTop: 16,
                  paddingBottom: 16
                }}>
                {(accessRoutes.includes('/guard/trends') || accessRoutes.includes('/chargebacks/overview') || accessRoutes.includes('/marketing/trends')) && (
                  <CustomTypoContainer fw={400}>Popular searches: </CustomTypoContainer>
                )}
                {accessRoutes.includes('/guard/trends') && (
                  <Chip
                    onClick={() => handleNavigation('/guard/trends')}
                    label={Modules.StoreAvailability}
                    sx={{ ml: 2 }}
                  />
                )}
                {accessRoutes.includes('/chargebacks/overview') && (
                  <Chip
                    onClick={() => handleNavigation('/chargebacks/overview')}
                    label={Modules.Chargebacks}
                    sx={{ ml: 2 }}
                  />
                )}
                {accessRoutes.includes('/marketing/trends') && (
                  <Chip
                    onClick={() => handleNavigation('/marketing/trends')}
                    label={Modules.Marketing}
                    sx={{ ml: 2 }}
                  />
                )}
              </div>
            </Box>
            <List sx={{ px: 2 }}>
              {result.slice(0, 3).map((item, index) => {
                if (item.childRoutes && item.childRoutes.length > 0) {
                  return (
                    <Box key={`${item.name}-${index}`}>
                      <StyledListItem
                        onClick={() => {
                          return handleNavigation(item)
                        }}>
                        <strong>{item.name} </strong>
                      </StyledListItem>
                      {item.childRoutes.slice(0, 3).map((item) => (
                        <StyledListItem
                          key={`${item.name}-${index}`}
                          onClick={() => handleNavigation(item)}>
                          <ListItemIcon>
                            <SubdirectoryArrowRight fontSize="small" />
                          </ListItemIcon>
                          <ListItemText>{item.name}</ListItemText>
                        </StyledListItem>
                      ))}
                    </Box>
                  )
                }
                return (
                  <StyledListItem
                    key={`${item.name}-${index}`}
                    onClick={() => handleNavigation(item)}>
                    <strong>{item.name}</strong>
                  </StyledListItem>
                )
              })}
            </List>
          </DialogContent>
        </Dialog>
        {sm ? (
          <Box width="100%">
            <IconButton
              sx={{ width: 45, height: 45, color: 'black' }}
              onClick={toggleDrawer}>
              <HamburgerIcon
                sx={{
                  color: '#D9D9D9'
                }}
              />
            </IconButton>
          </Box>
        ) : (
          <>
            <IconButton
              size="large"
              aria-label="open searchbar"
              onClick={() => {
                // For preview only
                if (posthog) {
                  setOpen(true)
                  focusOnInput()
                }
              }}
              sx={{
                width: 45,
                height: 45,
                color: 'black'
              }}>
              <SearchOutlined />
            </IconButton>

            {lg ? (
              <Box display="flex">
                <IconButton
                  sx={{ width: 45, height: 45, color: 'black' }}
                  onClick={callFunction}>
                  <Phone />
                </IconButton>
                <IconButton
                  sx={{ width: 45, height: 45, color: 'black' }}
                  onClick={helpFunction}>
                  {magicLinkLoading ? <CircularProgress size={18} /> : <Help />}
                </IconButton>
                <IconButton
                  disabled={disableReport}
                  sx={{ width: 45, height: 45, color: 'black' }}
                  onClick={handleReport}>
                  <PestControl />
                </IconButton>
              </Box>
            ) : (
              <ButtonGroup
                variant="outlined"
                sx={{
                  mx: 2,
                  maxHeight: 35
                }}>
                <Button
                  sx={{
                    border: '1px solid #E6E6E6',
                    borderRadius: '4px',
                    color: 'black',
                    fontSize: 12,
                    fontWeight: 400
                  }}
                  startIcon={<Phone />}
                  onClick={callFunction}>
                  {'+1 7076414146'}
                </Button>
                <Button
                  sx={{
                    border: '1px solid #E6E6E6',
                    borderRadius: '4px',
                    color: 'black',
                    fontSize: 12,
                    fontWeight: 400
                  }}
                  startIcon={magicLinkLoading ? <CircularProgress size={18} /> : <Help />}
                  onClick={helpFunction}>
                  {'Help'}
                </Button>
                <Button
                  disableRipple={true}
                  sx={{
                    border: '1px solid #E6E6E6',
                    borderRadius: '4px',
                    color: 'black',
                    fontSize: 12,
                    fontWeight: 400,
                    '&:disabled': {
                      color: 'black',
                      opacity: 0.5,
                      background: 'white'
                    }
                  }}
                  disabled={disableReport}
                  startIcon={<PestControl />}
                  onClick={handleReport}>
                  {'Report an Issue'}
                </Button>
              </ButtonGroup>
            )}
          </>
        )}
      </>
    )
  }

  return (
    <LoopUITopbar
      title={sm ? null : title}
      currentUser={currentUser}
      drawerState={drawerState}
      navigate={navigate}
      logout={logout}
      menuOptions={menuOptions}
      renderOptions={renderOptions}
      orgConfig={orgConfig}>
      {!hideFilters && (
        <>
          <AppBar
            ref={filterBarRef}
            position="fixed"
            elevation={trigger ? 3 : 0}
            sx={{
              backgroundColor: 'white',
              bgcolor: 'white',
              borderBottom: '1px solid #E6E6E6',
              top: '67px',
              width: {
                sm: drawerState ? `calc(100vw - ${drawerWidthPx})` : `calc(100vw - ${drawerCloseWidthPx})`,
                xs: '100%'
              },
              left: {
                sm: drawerState ? drawerWidthPx : drawerCloseWidthPx,
                xs: 'auto'
              },
              transition: theme.transitions.create(['width', 'left'], {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.leavingScreen
              })
            }}>
            {sm ? (
              <>
                <Button
                  variant="text"
                  sx={{
                    height: '50px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: 0,
                    color: '#000',
                    px: 2.5,
                    bgcolor: openFilterDrawer ? '#F3F3F3' : '#FFFFFF',
                    transition: '0.3s ease'
                  }}
                  onClick={() => setOpenFilterDrawer(!openFilterDrawer)}>
                  <Box
                    display="flex"
                    alignItems="center"
                    gap={1}>
                    <FilterAltSharp />
                    <Typography
                      color="inherit"
                      fontSize={14}>
                      Filters
                    </Typography>
                  </Box>
                  <Box
                    display="flex"
                    alignItems="center">
                    <KeyboardArrowDownRounded sx={{ fontSize: '24px', transform: openFilterDrawer ? 'rotate(180deg)' : '', transition: '0.3s ease' }} />
                  </Box>
                </Button>

                <Collapse in={openFilterDrawer}>
                  <Box
                    sx={{
                      left: 0,
                      width: '100%',
                      height: '100%',
                      minHeight: 'calc(100vh - 120px)',
                      overflow: 'auto',
                      bgcolor: '#fff',
                      zIndex: 1000,
                      py: 1,
                      px: 2
                    }}>
                    <NewFilterComponent
                      showFiltersOutsideDropdown
                      fullWidthFilters
                      closeFilterDrawer={() => setOpenFilterDrawer(false)}
                      {...filterProps}
                    />
                  </Box>
                </Collapse>
              </>
            ) : (
              <Toolbar>
                <NewFilterComponent
                  showFiltersOutsideDropdown
                  {...filterProps}
                />
              </Toolbar>
            )}
          </AppBar>
          <Toolbar sx={{ height: filterBarOffsetHeight }} />
        </>
      )}
      {children}
    </LoopUITopbar>
  )
}
