import Backdrop from '@mui/material/Backdrop'
import { styled } from '@mui/material/styles'
import LottieLoader from '../Loading/LottieLoader'

const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  color: '#fff',
  backgroundColor: '#fff',
  zIndex: theme.zIndex.modal + 1
}))

function Loader({ loading }: { loading: boolean }) {
  return (
    <StyledBackdrop open={loading}>
      <LottieLoader style={{ width: '250px' }} />
    </StyledBackdrop>
  )
}

export default Loader
