import { useEffect } from 'react'
import { useLoadingData } from 'src/context/LoadingContext'

const FallbackLoader = () => {
  const { setBackdropLoading } = useLoadingData()

  useEffect(() => {
    setBackdropLoading(true)
    setTimeout(() => {
      setBackdropLoading(false)
    }, 2000)
  }, [])

  return null
}

export default FallbackLoader
