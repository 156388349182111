import * as Sentry from '@sentry/browser'
import { signInWithCustomToken } from 'firebase/auth'
import { collection, deleteField, doc, getDocs, onSnapshot, query, setDoc, updateDoc, where } from 'firebase/firestore'
import get from 'lodash/get'
import moment from 'moment'
import posthog from 'posthog-js'
import { ReactNode, createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useErrorData } from 'src/context/ErrorContext'
import { db, fetchFirebaseData, setAllAccessLevels } from 'src/services/firebase'
import { OpenAPI as FrontendDataServiceBaseAPI } from 'src/services/openApi'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { getLocal, setLocal } from 'src/utils/functions/localStorage'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import { auth, clearList, fetchGoogleStudioLinks, fetchOrgConfig, fetchSupersetLinks, fetchUserOrgAndRole } from '../services/firebase'

import { useSnackData } from './SnackContext'

import { sendSlackNotification } from 'src/utils/api'
import { BASE_FRONTEND_URL, LOOPAIXYZ_DOMAIN, LOOPKITCHENXYZ_DOMAIN, TRYLOOPAI_DOMAIN } from 'src/utils/config/config'
import { getSigninToken } from 'src/utils/firebaseFunctions/authFunctions'
import { decrypt, encrypt } from 'src/utils/functions/encryptionDecryption'
import { AccessLevelEnum, AccessLevelType, AuthContextInterface, RedirectDialogType, RouteType, SupersetLinkType, User, UserType } from './AuthContext.type'
import { usePublicAuthContext } from './PublicAuthContext'
import { SubChainModules, SubChainOrgUser, getSubchainOrgUsers } from './SubChainContext'

const initialState = {} as AuthContextInterface

export const AuthContext = createContext<AuthContextInterface>(initialState)

export const useAuth = () => useContext(AuthContext)

const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const [params, setParams] = useSearchParams()
  const { currentUser: authCurrentUser, analyticsReset, logout: publicLogout, setLoading } = usePublicAuthContext()
  const { openInfo, openError } = useSnackData()
  const [currentUser, setCurrentUser] = useState<User>()
  // const [loading, setLoading] = useState(true)
  const { handleError, asyncWrapper } = useErrorData()
  const [orgConfig, setOrgConfig] = useState({})
  const [accessLevels, setAccessLevels] = useState<AccessLevelType[]>([])
  const [userList, setUserList] = useState<UserType[]>([])
  const [userListLoading, setUserListLoading] = useState(true)

  const [defaultRoute, setDefaultRoute] = useState('dashboard')
  const [googleStudioLinks, setGoogleStudioLinks] = useState([])
  const [supersetLinks, setSupersetLinks] = useState<SupersetLinkType[]>([])
  const [standaloneRoutesLoaded, setStandaloneRoutesLoaded] = useState(false)
  const [globalRoutes, setGlobalRoutes] = useState<RouteType[]>([])
  const isDemo = get(currentUser, 'access-level', []).includes(AccessLevelEnum.DEMO) || false
  const [redirectLoading, setRedirectLoading] = useState(false)
  const [isCurrentUserSet, setIsCurrentUserSet] = useState(false)
  const [subchainData, setSubchainData] = useState<SubChainOrgUser>(null)
  const [subchainModulesList, setSubchainModulesList] = useState<SubChainModules[]>([])
  const [routeCategories, setRouteCategories] = useState<{ uid: string; routes: string[] }[]>([])
  const [openRedirectionDialog, setOpenRedirectionDialog] = useState<RedirectDialogType>({
    openRedirection: false,
    redirectionCallback: () => Promise.resolve(),
    openRedirected: false,
    redirectedCallback: () => Promise.resolve()
  })

  const subchainModules = useMemo(() => {
    if (!currentUser) return null
    const { uid } = currentUser
    if (subchainData && Array.isArray(subchainData[uid]) && subchainData[uid].length > 0) {
      const { name } = subchainData[uid][0]
      const moduleItem = subchainModulesList.find((item) => item?.name === name)
      if (typeof moduleItem === 'object' && Array.isArray(moduleItem?.modules)) {
        const { modules } = moduleItem
        return modules
      }
    } else {
      return null
    }
  }, [subchainData, currentUser, subchainModulesList])

  const getSubChainRoutes = useCallback(
    (accessLevel: string) => {
      if (
        ![AccessLevelEnum.BUSINESS_ADMIN, AccessLevelEnum.BUSINESS_MANAGER].includes(accessLevel as AccessLevelEnum) &&
        Array.isArray(subchainModules) &&
        subchainModules.length > 0
      ) {
        const defaultAccessLevelConfig = accessLevels.find((item) => item.uid === accessLevel)
        const defaultRoutes: typeof defaultAccessLevelConfig.routes = get(defaultAccessLevelConfig, 'routes', []) || []
        const userIsFinanceTeam = accessLevel === AccessLevelEnum.FINANCE_TEAM
        const subchainRoutes = subchainModules
          .map((module) => {
            return routeCategories
              .map((item) => (item.uid === module.uid ? item.routes : []))
              .flat()
              .filter((route) => (userIsFinanceTeam ? true : defaultRoutes.includes(route)))
              .map((route) => {
                return {
                  uid: route,
                  type: module.type
                }
              })
          })
          .flat()
        return subchainRoutes
      }
      return []
    },
    [subchainModules, routeCategories, accessLevels]
  )

  const getRoutes = useCallback(
    (paramAccessLevel?: AccessLevelEnum) => {
      if (!orgConfig) return []
      const accessLevel = paramAccessLevel || getAccessLevel(currentUser)
      const subChainRoutes = getSubChainRoutes(accessLevel)
      if (Array.isArray(subChainRoutes) && subChainRoutes.length > 0) {
        return subChainRoutes
      }
      return get(orgConfig, `access_levels.${accessLevel}.routes`, [])
    },
    [currentUser, orgConfig, getSubChainRoutes]
  )

  async function changeStatus(id: string) {
    const userRef = doc(db, 'users', id)
    await updateDoc(userRef, { status: deleteField() })
  }
  const getApiData = async (currentUser, data) => {
    try {
      if (!data || !data?.org) {
        throw new Error("Cannot read properties of null (reading 'org')")
      }
      // setLoading(true)
      setCurrentUser({
        ...currentUser,
        ...data
      })
      setIsCurrentUserSet(true)
      changeStatus(currentUser.uid)
      let result: any = await Promise.allSettled([
        fetchGoogleStudioLinks(),
        fetchOrgConfig(data.org, currentUser['access-level']),
        fetchOrgConfig('default', currentUser['access-level']),
        fetchSupersetLinks(),
        setAllAccessLevels(),
        getSubchainOrgUsers(data.org),
        fetchFirebaseData('route_categories')
      ]).catch((error) => {
        console.log(error.message)
        if (handleError) handleError(error.message)
      })
      result = result.map((item) => item.value)
      setGoogleStudioLinks(result[0])
      setSupersetLinks(result[3])
      if (result[1]) {
        const access_levels = get(result, '[1].access_levels', {})
        const accessLevel = Array.isArray(data['access-level']) ? data['access-level'][0] : data['access-level']
        const defaultRouteForRole = get(access_levels, `${accessLevel}.defaultRoute.[0]`, get(access_levels, `${accessLevel}.routes.[0]`, 'dashboard'))
        if (defaultRouteForRole) setDefaultRoute(defaultRouteForRole)
        let navConfig = get(result, `[1].access_levels.[${accessLevel}].routes`, [])
        const logo = get(result, `[1].logo`, undefined)
        let filterConfig = result[1]?.filterConfig
        if (!navConfig.length) {
          navConfig = result[2]?.navConfig
        }
        if (!filterConfig) {
          filterConfig = result[2]?.filterConfig
        }
        const subchainData = result[5]
        const routeCategories = result[6] || []
        setRouteCategories(routeCategories)
        setSubchainData(subchainData)
        const orgData = get(result, '[1]', {})
        const orgConfigData = {
          ...orgData,
          navConfig,
          filterConfig,
          access_levels,
          logo
        }

        setOrgConfig((prev: any) => ({ ...prev, ...orgConfigData }))
        setLocal('org_config', orgConfigData)
        localStorage.setItem('storageDate', new Date().toDateString())

        //set data to localstorage
        setLocal('data', data)
        setLocal('userOrg', data.org)
        setLocal('studio_links', result[0])
      } else {
        params.append('error', 'Sorry, the organization name you entered is incorrect. Please check spelling and capitalization and try again.')
        setParams(params)
        logout()
      }
    } catch (error) {
      console.log(error.message)
      if (handleError) handleError(error.message)
    } finally {
      setLoading(false)
    }
  }

  const hasMagicLink = (obj?: { user?: typeof currentUser; skipExpireDateCheck?: boolean }) => {
    try {
      const { user, skipExpireDateCheck } = obj || {}
      const data = user || currentUser
      const magicLinkExpired = () => {
        const expirationTime = moment(get(data, 'magic_link.expires_at', 0)).toDate()
        const now = new Date()
        return now.getTime() > expirationTime.getTime()
      }
      const result = 'magic_link' in data && (skipExpireDateCheck ? true : !magicLinkExpired())
      return result
    } catch (err) {
      return false
    }
  }

  useEffect(() => {
    const getUserData = async () => {
      try {
        const condition = Boolean(authCurrentUser)

        if (condition) {
          // checking if email is present in the url
          const regex = new RegExp('[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+.[A-Za-z]{2,}')
          if (regex.test(window.location.pathname)) {
            navigate('/')
          }
          Sentry.setUser({ email: authCurrentUser.email })
          FrontendDataServiceBaseAPI.TOKEN = async () => {
            try {
              const token = await authCurrentUser.getIdToken()
              setLocal('authToken', token, true)
              return token
            } catch (err) {
              console.log('error in getting token: ', err)
              return null
            }
          }
          const data = await fetchUserOrgAndRole(authCurrentUser.uid)
          if (getAccessLevel({ ...authCurrentUser, ...data }) === AccessLevelEnum.OBSERVER) {
            setLoading(false)
            logout()
            return openError('Login access denied')
          }
          if (data) {
            if (authCurrentUser && !authCurrentUser.emailVerified) {
              setLoading(false)
              logout()
              return openError('Please verify your email before login')
            }
            setLoading(false)
            if (data.stage === 'waiting' && !hasMagicLink({ user: data })) {
              const waitingLink = '/create-account/wait'
              setCurrentUser({
                ...authCurrentUser,
                ...data
              })
              setIsCurrentUserSet(true)
              changeStatus(authCurrentUser.uid)
              if (location.pathname !== waitingLink) {
                navigate(waitingLink)
              }
              setTimeout(() => {
                analyticsReset()
              }, 2000)
              return
            }

            if (data.stage === 'verified' && !hasMagicLink({ user: data })) {
              const waitingLink = '/org/create'
              setCurrentUser({
                ...authCurrentUser,
                ...data
              })
              setIsCurrentUserSet(true)
              changeStatus(authCurrentUser.uid)
              if (location.pathname !== waitingLink) navigate(waitingLink)
              setTimeout(() => {
                analyticsReset()
              }, 2000)
              setLoading(false)
              return
            } else if (authCurrentUser && get(data, 'stage') === 'org_created' && !hasMagicLink({ user: data })) {
              const waitingLink = '/org/invite'
              setCurrentUser({
                ...authCurrentUser,
                ...data
              })
              setIsCurrentUserSet(true)
              changeStatus(authCurrentUser.uid)
              if (location.pathname !== waitingLink) {
                navigate(waitingLink)
              }
              setTimeout(() => {
                analyticsReset()
              }, 2000)
              setLoading(false)
              return
            } else if (data && data?.org) {
              //FETCH DATA FROM API IF EVERYTHING IS OK AFTER RELOAD
              await getApiData(authCurrentUser, data)
              if (location.pathname === '/create-account/wait') navigate('/')
              setLoading(false)
            } else {
              handleError("Organization information not found. Please contact your organization's admin.")
              setTimeout(() => {
                navigate('/')
                logout()
              })
            }
          } else {
            //create new user entry
            if (authCurrentUser) {
              const newUserObj = {
                name: authCurrentUser.displayName,
                email: authCurrentUser?.email,
                org: null,
                phoneNumber: authCurrentUser.phoneNumber,
                uid: authCurrentUser.uid,
                access_names: [],
                'access-level': [AccessLevelEnum.BUSINESS_ADMIN],
                stage: 'waiting'
              }
              setCurrentUser({
                ...newUserObj,
                stage: 'waiting'
              })
              setIsCurrentUserSet(true)
              navigate('/create-account/wait')
              setTimeout(() => {
                analyticsReset()
              }, 2000)
              await setDoc(doc(db, 'users', authCurrentUser.uid), newUserObj)
              changeStatus(authCurrentUser.uid)
              setLoading(false)
            }
          }

          setRedirectLoading(false)
        } else {
          clearList()
          setCurrentUser(undefined)
          setIsCurrentUserSet(true)
          setLoading(false)
          logout()
        }
      } catch (error) {
        setLoading(false)
        if (handleError) {
          if (error.message.includes("Cannot read properties of null (reading 'org')")) {
            handleError('You are not registered with us')
            logout()
          } else {
            handleError(error.message)
          }
        } else {
          console.log(error.message)
        }
      }
    }
    getUserData()
  }, [authCurrentUser])

  useEffect(() => {
    let unsubscribeSpecificOrg: any
    let unsubscribeAccessLevels: any
    let unsubscribeUserList: any
    let unsubscribeSubchainModules: any
    if (currentUser && currentUser.org) {
      const accessLevel = getAccessLevel(currentUser)
      if (accessLevel === AccessLevelEnum.OBSERVER) {
        logout()
        openError('Login access denied')
      }
      try {
        const q = doc(db, `org_config_2/${currentUser.org}`)
        unsubscribeSpecificOrg = onSnapshot(q, (snapShot) => {
          const tempOrgConfig = getLocal('org_config')
          if (tempOrgConfig) {
            const version1 = get(tempOrgConfig, 'version', 0)
            const data = snapShot.data()
            const version2 = get(data, 'version', 0)

            setOrgConfig((prev: any) => ({ ...prev, ...data }))

            setLocal('org_config', data)
            if (version2.toString() !== version1.toString()) {
              openInfo('Your access level is updated by your organization')
            }
          }
        })
      } catch (error) {
        console.log(error.message)
      }

      try {
        const q = collection(db, 'access_levels')
        unsubscribeAccessLevels = onSnapshot(q, (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => {
            const data = doc.data()
            return { ...data, uid: doc.id, label: data.name, value: doc.id }
          })
          // @ts-ignore
          setAccessLevels(documents)
        })
      } catch (err) {
        console.log('access level error: ', err)
      }

      try {
        if (shouldBlockForUser(currentUser)) {
          setUserList([])
        } else {
          const docRef = query(collection(db, 'users'), where('org', '==', currentUser.org))

          unsubscribeUserList = ![AccessLevelEnum.BUSINESS_ADMIN, AccessLevelEnum.BUSINESS_MANAGER, AccessLevelEnum.INTERNAL_OPERATOR].includes(
            getAccessLevel(currentUser)
          )
            ? null
            : onSnapshot(docRef, (querySnapshot) => {
                setUserListLoading(true)
                // @ts-ignore
                const documents: UserType[] = querySnapshot.docs.map((doc) => {
                  const data = doc.data()
                  return { ...data, access_level: data['access-level'], uid: doc.id }
                })
                const result = documents.filter((item) => {
                  return item.org === currentUser.org
                })
                setUserList(result)
                setUserListLoading(false)
              })
        }
      } catch (err) {
        console.log('user list error: ', err)
      }

      try {
        const q = query(collection(db, 'subchain_modules'), where('org', '==', currentUser.org))
        unsubscribeSubchainModules = onSnapshot(q, (querySnapshot) => {
          const documents = querySnapshot.docs.map((doc) => {
            const data = doc.data()
            return { ...data, uid: doc.id } as SubChainModules
          })
          setSubchainModulesList(documents)
        })
      } catch (err) {
        console.error('error in fetching subchain modules', err)
      }
    }
    return () => {
      if (unsubscribeSpecificOrg) {
        unsubscribeSpecificOrg()
      }
      if (unsubscribeAccessLevels) {
        unsubscribeAccessLevels()
      }
      if (unsubscribeUserList) {
        unsubscribeUserList()
      }
      if (unsubscribeSubchainModules) {
        unsubscribeSubchainModules()
      }
    }
  }, [currentUser])

  useEffect(() => {
    FrontendDataServiceBaseAPI.HEADERS = {
      pageUrl: location.pathname,
      'Cache-Control': 'no-cache'
    }
  }, [location.pathname])

  function posthogCapture(label, data = {}) {
    try {
      posthog.capture(label, {
        email: currentUser?.email,
        name: currentUser?.name,
        org: currentUser?.org,
        time: new Date().toISOString(),
        ...data
      })
    } catch (error) {
      handleError(error.message)
    }
  }

  useEffect(() => {
    const getGlobalRoutes = async () => {
      try {
        if (!authCurrentUser) return
        const result = await getDocs(collection(db, 'routes'))
        const tempRoutes = result.docs.map((doc) => {
          return { ...doc.data(), uid: doc.id } as RouteType
        })
        setGlobalRoutes(tempRoutes)
      } catch (err) {
        setGlobalRoutes([])
      }
    }
    getGlobalRoutes()
  }, [authCurrentUser])

  const logout = () => {
    asyncWrapper(
      (async () => {
        posthogCapture('User logged out')
        publicLogout()
      })()
    )
  }

  const signInWithIdToken = async (idToken: string) => {
    const signInData = await getSigninToken({ idToken })

    if (signInData && signInData.success) {
      const customToken = signInData.token
      await signInWithCustomToken(auth, customToken)
    } else {
      openError('Failed to create session')
    }
  }

  const redirectUsersToTryloop = async () => {
    try {
      const location = window.location.href
      const host = window.location.host
      const isTryloopDomain = host.includes(TRYLOOPAI_DOMAIN)
      const isLoopkitchenDomain = host.includes(LOOPKITCHENXYZ_DOMAIN) || host.includes(LOOPAIXYZ_DOMAIN)
      const shortLocation = location.slice(10)
      const path = shortLocation.slice(shortLocation.indexOf('/'))

      if (isTryloopDomain) {
        const redirected = params.get('redirected') || false

        if (redirected) {
          setParams((prev) => {
            prev.delete('redirected')
            return prev
          })

          setOpenRedirectionDialog((prev) => ({
            ...prev,
            openRedirected: true,
            redirectedCallback: async () => {
              const encryptedIdToken = params.get('id_token')
              const name = get(currentUser, 'name', '-')
              const email = get(currentUser, 'email', '-')
              const org = get(currentUser, 'org', '-')

              if (encryptedIdToken) {
                const idToken = decrypt(encryptedIdToken)

                if (idToken) {
                  await sendSlackNotification({
                    message: `\`Redirected\` user to Tryloop domain`,
                    title: `Details: \n\t Logged in: \`${!!name}\` \n\t Name: \`${name}\` \n\t Email: \`${email}\` \n\t Org: \`${org}\` \n\t Id Token: \`${idToken}\``,
                    channel: 'fe-auth-redirect-logs'
                  })

                  posthogCapture('User redirected to Tryloop domain', {
                    name,
                    email,
                    org,
                    idToken
                  })

                  setRedirectLoading(true)
                  await signInWithIdToken(idToken)
                }
              } else {
                await sendSlackNotification({
                  message: `\`Redirected\` user to Tryloop domain`,
                  title: `Details: \n\t Logged in: \`${!!name}\` \n\t Name: \`${name}\` \n\t Email: \`${email}\` \n\t Org: \`${org}\` \n\t Id Token: \`-\``,
                  channel: 'fe-auth-redirect-logs'
                })

                posthogCapture('User redirected to Tryloop domain', {
                  name,
                  email,
                  org
                })
              }
            }
          }))
        }
      } else {
        if (isLoopkitchenDomain) {
          if (!isCurrentUserSet) return

          setOpenRedirectionDialog((prev) => ({
            ...prev,
            openRedirection: true,
            redirectionCallback: async () => {
              setRedirectLoading(true)

              if (currentUser) {
                const idToken = await auth.currentUser.getIdToken()
                const encryptedIdToken = encrypt(idToken)

                const redirectUrl = `${BASE_FRONTEND_URL}/?redirect=${path}&id_token=${encryptedIdToken}&redirected=true`

                await sendSlackNotification({
                  message: `\`Redirecting user\` to Tryloop domain`,
                  title: `Details: \n\t Logged in: \`true\` \n\t Name: \`${currentUser.name}\` \n\t Email: \`${currentUser.email}\` \n\t Org: \`${currentUser.org}\` \n\t Id Token: \`${idToken}\``,
                  channel: 'fe-auth-redirect-logs'
                })

                posthogCapture('User redirecting to Tryloop domain', {
                  name: currentUser.name,
                  email: currentUser.email,
                  org: currentUser.org,
                  idToken
                })

                logout()
                window.location.href = redirectUrl
              } else {
                const redirectUrl = `${BASE_FRONTEND_URL}/?redirect=${path}&redirected=true`

                await sendSlackNotification({
                  message: `\`Redirecting user\` to Tryloop domain`,
                  title: `Details: \n\t Logged in: \`false\` \n\t Name: \`-\` \n\t Email: \`-\` \n\t Org: \`-\` \n\t Id Token: \`-\``,
                  channel: 'fe-auth-redirect-logs'
                })

                posthogCapture('User redirecting to Tryloop domain')

                window.location.href = redirectUrl
              }

              setRedirectLoading(false)
            }
          }))
        }
      }
    } catch (err) {
      handleError(err)
    }
  }

  useEffect(() => {
    redirectUsersToTryloop()
  }, [currentUser, isCurrentUserSet])

  const contextValue = useMemo(
    () => ({
      currentUser,
      logout,
      orgConfig,
      accessLevels,
      userList,
      userListLoading,
      googleStudioLinks,
      supersetLinks,
      defaultRoute,
      setDefaultRoute,
      setCurrentUser,
      isDemo,
      posthogCapture,
      getApiData,
      hasMagicLink,
      globalRoutes,
      standaloneRoutesLoaded,
      setStandaloneRoutesLoaded,
      redirectLoading,
      openRedirectionDialog,
      setOpenRedirectionDialog,
      getSubChainRoutes,
      getRoutes
    }),
    [
      currentUser,
      logout,
      orgConfig,
      accessLevels,
      userList,
      userListLoading,
      googleStudioLinks,
      supersetLinks,
      defaultRoute,
      setDefaultRoute,
      setCurrentUser,
      isDemo,
      posthogCapture,
      getApiData,
      globalRoutes,
      standaloneRoutesLoaded,
      setStandaloneRoutesLoaded,
      redirectLoading,
      openRedirectionDialog,
      setOpenRedirectionDialog,
      getSubChainRoutes,
      getRoutes
    ]
  )

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
}

export default AuthContextProvider
