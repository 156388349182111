import { ChargesModel } from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
import { Stack } from '@mui/material'
import React from 'react'
import { Modules } from 'src/utils/config/config'
import { formatCurrency } from 'src/utils/functions'
import BorderedPriceBox from './helpers/BorderedPriceBox'
import HeadingContainer from './helpers/HeadingContainer'

interface BreakdownCompProps {
  charges?: ChargesModel
  loading?: boolean
  collapsable?: boolean
}

export default function BreakdownComp(props: BreakdownCompProps) {
  const { charges, loading, collapsable } = props

  const data = React.useMemo(() => {
    const arr: {
      label: string
      price: number
      color: string
    }[] = []
    if (loading || typeof charges?.subtotal === 'number') {
      arr.push({
        label: 'Subtotal',
        price: charges?.subtotal,
        color: '#1E1E1E'
      })
    }
    if (loading || typeof charges?.commission === 'number') {
      arr.push({
        label: `${charges?.platform} commission`,
        price: Math.abs(charges?.commission) * -1,
        color: '#FF0000'
      })
    }
    if (loading || typeof charges?.tip === 'number') {
      arr.push({
        label: 'Tip',
        price: charges?.tip,
        color: '#1E1E1E'
      })
    }
    if (loading || typeof charges?.tax === 'number') {
      arr.push({
        label: 'Tax',
        price: charges?.tax,
        color: 'rgba(30, 30, 30, 1)'
      })
    }
    if (loading || typeof charges?.marketing === 'number') {
      arr.push({
        label: 'Marketing money spend',
        price: Math.abs(charges?.marketing) * -1,
        color: 'rgba(255, 0, 0, 1)'
      })
    }
    if (loading || typeof charges?.ad_fee === 'number') {
      arr.push({
        label: 'Ad Fee',
        price: Math.abs(charges?.ad_fee) * -1,
        color: 'rgba(255, 0, 0, 1)'
      })
    }
    if (loading || typeof charges?.no_breakdown === 'number') {
      arr.push({
        label: 'No Breakdown',
        price: charges?.no_breakdown,
        color: 'rgba(30, 30, 30, 1)'
      })
    }
    if (loading || (typeof charges?.error_charges === 'number' && charges?.error_charges !== 0)) {
      arr.push({
        label: Modules.Chargebacks,
        price: Math.abs(charges?.error_charges) * -1,
        color: 'rgba(255, 0, 0, 1)'
      })
    }
    if (loading || (typeof charges?.unfulfilled_refunds === 'number' && charges?.unfulfilled_refunds !== 0)) {
      arr.push({
        label: Modules.UnfulfilledRefunds,
        price: Math.abs(charges?.unfulfilled_refunds) * -1,
        color: 'rgba(255, 0, 0, 1)'
      })
    }
    if (loading || (typeof charges?.adjustment === 'number' && charges?.adjustment !== 0)) {
      arr.push({
        label: Modules.WonDisputes,
        price: charges?.adjustment,
        color: 'rgba(30, 30, 30, 1)'
      })
    }
    if (loading || (typeof charges?.lost_sale === 'number' && charges?.lost_sale !== 0)) {
      arr.push({
        label: `${Modules.LostSales}`,
        price: charges?.lost_sale * -1,
        color: 'rgba(255, 0, 0, 1)'
      })
    }
    return arr
  }, [charges, loading])

  return (
    <HeadingContainer
      icon={'insert_page_break'}
      title="Breakdown"
      collapsable={collapsable}>
      <Stack
        direction="column"
        alignItems="stretch"
        gap="5px">
        {data.map((item) => {
          return (
            <BorderedPriceBox
              key={item.label}
              loading={loading}
              label={item.label}
              value={<span style={{ color: item.color }}>{formatCurrency(item.price, { maxFractionDigits: 2 })}</span>}
            />
          )
        })}
      </Stack>
    </HeadingContainer>
  )
}
