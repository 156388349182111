import { get } from 'lodash'
import moment, { Moment } from 'moment'
import { getFilterKeys } from 'src/components/NewFilterComponent/utils/filterUtils'
import { FilterNode } from './FilterNode'
import { ApiFilterResponseType, FilterGraphFilterValueType } from './types'

export type DateRangeType = { start: Moment; end: Moment } | undefined

const filterKeys: string[] = getFilterKeys()

export class FilterGraph {
  private filters: {
    [key: string]: FilterGraphFilterValueType
  }
  private dateRange: DateRangeType
  private lastDate: Moment
  private graphId: string

  constructor(graphId: string, filtersData: ApiFilterResponseType[], singleSelectFilterKeys?: string[]) {
    this.graphId = graphId
    this.filters = {}
    filterKeys.forEach((key) => {
      this.filters[key] = {
        allPossible: [],
        visible: new Set<FilterNode>(),
        selected: new Set<FilterNode>(),
        isSingleSelect: singleSelectFilterKeys && singleSelectFilterKeys.includes(key)
      }
    })
    this.dateRange = {
      start: moment().subtract(30, 'days'),
      end: moment()
    }
    this.lastDate = moment()

    this.generateGraph(filtersData)
    this.reset()
  }

  public getFilters() {
    return this.filters ? this.filters : null
  }

  public getAllPossibleNodesByKey(key: string) {
    if (key in this.filters) {
      return this.filters[key].allPossible
    }
    return []
  }

  public getVisibleNodesByKey(key: string) {
    if (key in this.filters) {
      return this.filters[key].visible
    }
    return new Set<FilterNode>()
  }

  public setVisible(key: string, newVisible: Iterable<FilterNode>) {
    if (!(key in this.filters)) {
      return
    }
    this.filters[key].visible = new Set(newVisible)
  }

  public getSelectedNodesByKey(key: string) {
    if (key in this.filters) {
      return this.filters[key].selected
    }
    return new Set<FilterNode>()
  }

  public setSelected(key: string, newSelected: Iterable<FilterNode>) {
    if (!(key in this.filters)) {
      return
    }
    this.filters[key].selected = new Set(newSelected)
  }

  public getSingleSelectByKey(key: string) {
    if (key in this.filters) {
      return this.filters[key].isSingleSelect
    }
    return false
  }

  public getDateRange() {
    return this.dateRange
  }

  public setDateRange(newDateRange: DateRangeType) {
    this.dateRange = { ...newDateRange }
  }

  public getLastDate() {
    return this.lastDate
  }

  public setLastDate(newLastDate: Moment) {
    this.lastDate = newLastDate
  }

  /**
   * If all filters are multiple select, this resets their visible to all possible, and selected to all possible
   * But if there is single select, this does 3 things:
   * - for the single select filter, adds all possible to visible
   * - makes the first value from all possible as selected
   * - makes the visible of all other filters the relations of the selected value
   */
  public reset() {
    let foundSingleSelect = false
    Object.keys(this.filters).forEach((key) => {
      if (foundSingleSelect === false) {
        this.filters[key].visible = new Set(this.filters[key].allPossible)
        if (this.filters[key].isSingleSelect) {
          if (this.filters[key].allPossible.length > 0) {
            const resetSelectedNode = this.filters[key].allPossible[0]
            this.filters[key].selected = new Set([resetSelectedNode])
            Object.keys(this.filters).forEach((relatedKey) => {
              if (key !== relatedKey) {
                this.filters[relatedKey].visible = new Set(Array.from(resetSelectedNode.relations[relatedKey]).map(([key, value]) => value.node))
              }
            })
          } else this.filters[key].selected = new Set(this.filters[key].allPossible)
          foundSingleSelect = true
        } else this.filters[key].selected = new Set(this.filters[key].allPossible)
      }
    })
  }

  public generateGraph(filtersData: ApiFilterResponseType[]) {
    const nodesMap: {
      [key: string]: Map<string | number, FilterNode>
    } = {}
    filterKeys.forEach((key) => {
      nodesMap[key] = new Map<string | number, FilterNode>()
    })

    filtersData.forEach((filtersDataObject) => {
      // * create nodes
      filterKeys.forEach((key) => {
        if (!(key in filtersDataObject)) {
          return
        }
        if (!nodesMap[key].has(filtersDataObject[key])) {
          const node = new FilterNode(filtersDataObject[key], key)
          nodesMap[key].set(filtersDataObject[key], node)
          this.filters[key].allPossible.push(node)
        }
      })

      // * add relations
      filterKeys.forEach((currentKey) => {
        if (!(currentKey in filtersDataObject)) {
          return
        }
        const currentNode = nodesMap[currentKey].get(filtersDataObject[currentKey])

        filterKeys.forEach((relatedKey) => {
          // * a node is not related to itself
          if (currentKey === relatedKey) return

          const relatedNode = nodesMap[relatedKey].get(filtersDataObject[relatedKey])
          // * bidirectional relation
          if (currentNode.relations[relatedKey].has(relatedNode.value) && relatedNode.relations[currentKey].has(currentNode.value)) {
            currentNode.relations[relatedKey].get(relatedNode.value).slugs.add(filtersDataObject.slug)
            relatedNode.relations[currentKey].get(currentNode.value).slugs.add(filtersDataObject.slug)
          } else {
            currentNode.relations[relatedKey].set(relatedNode.value, { node: relatedNode, slugs: new Set([filtersDataObject.slug]) })
            relatedNode.relations[currentKey].set(currentNode.value, { node: currentNode, slugs: new Set([filtersDataObject.slug]) })
          }
        })
      })
    })
  }

  /**
   * this function will take the intersection from visible and selected Set
   * and returns the resultant Set (used in getFilters function, FilterComponent UI)
   * @param key
   * @returns
   */
  public getVisibleSelectedIntersectionNodes(key: string) {
    const result = new Set<FilterNode>()
    if (key in this.filters) {
      const obj = this.filters[key]
      const visibleSet = get(obj, 'visible', new Set<FilterNode>())
      const selectedSet = get(obj, 'selected', new Set<FilterNode>())

      visibleSet.forEach((value) => {
        if (selectedSet.has(value)) {
          result.add(value)
        }
      })
    }
    return result
  }
}
