import React from 'react'

export default function useLayoutDimension() {
  const [headerHeight, setHeaderHeight] = React.useState(0)
  const resizeObserver = React.useRef<ResizeObserver | null>(null)

  React.useEffect(() => {
    const observer = new MutationObserver((mutations) => {
      if (resizeObserver.current) {
        resizeObserver.current.disconnect()
        resizeObserver.current = null
      }
      resizeObserver.current = new ResizeObserver((entries) => {
        setHeaderHeight(
          [...headers].reduce((acc, curr) => {
            return acc + curr.getBoundingClientRect().height
          }, -1)
        )
      })
      const headers = document.querySelectorAll('header')
      headers.forEach((header) => {
        resizeObserver.current.observe(header)
      })
    })

    observer.observe(document.body, {
      childList: true,
      subtree: true
    })

    return () => {
      resizeObserver.current?.disconnect()
      observer.disconnect()
      resizeObserver.current = null
    }
  }, [])

  return {
    headerHeightPx: `${headerHeight}px`,
    headerHeight: headerHeight
  }
}
