import { get } from 'lodash'
import { DrillDownColumnType } from 'src/pages/members/DrillDown/utils/hooks/useServiceHook'
import { MetricsKPIDrillDownOutput, MetricsKPIDrillDownOutputDateRangeCompare } from 'src/services/openApi'
import { FiltersObjType } from './jsonConfigs/types'

export function getFiltersArrStr(filtersArr: FiltersObjType[]) {
  return filtersArr.map((e) => `${e.key}, ${e.value.join(', ')}`).join(', ')
}

export function exportMetricsData(columns: DrillDownColumnType[], data: MetricsKPIDrillDownOutputDateRangeCompare) {
  const visibleColumns = columns.filter((column) => !column.hidden)
  const percentages = data.percentages.reduce(
    (acc, curr) => {
      acc[curr.breakdown_column] = curr
      return acc
    },
    {} as { [key: string]: MetricsKPIDrillDownOutput }
  )

  const fieldsWithDelta = Object.entries(data.percentages[0])
    .filter(([field, value]) => {
      return typeof value === 'number'
    })
    .map((e) => e[0])

  const headers: string[] = []

  visibleColumns.forEach((col) => {
    headers.push(col.title)
    if (fieldsWithDelta.includes(col.field)) {
      headers.push(`${col.title} Difference`)
    }
  })

  const rows = data.current_range_data.map((current) => {
    const row: string[] = []
    visibleColumns.forEach((col) => {
      const x = get(current, col.field, '')
      row.push(`${current[col.field]}`)
      if (fieldsWithDelta.includes(col.field)) {
        row.push(`${get(percentages, `${current.breakdown_column}.${col.field}`)}%`)
      }
    })
    return row
  })

  return { headers, rows }
}
