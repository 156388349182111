import CryptoJS from 'crypto-js'
import { AUTH_ENCRYPTION_KEY } from '../config/config'

const key = AUTH_ENCRYPTION_KEY

export function encrypt(text) {
  try {
    const encJson = CryptoJS.AES.encrypt(JSON.stringify(text), key).toString()
    const ciphertext = CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(encJson))

    return ciphertext
  } catch (error) {
    console.error('Encryption failed:', error)
    return ''
  }
}

export function decrypt(ciphertext) {
  try {
    const decData = CryptoJS.enc.Base64.parse(ciphertext).toString(CryptoJS.enc.Utf8)
    const bytes = CryptoJS.AES.decrypt(decData, key).toString(CryptoJS.enc.Utf8)
    const text = JSON.parse(bytes)

    return text
  } catch (error) {
    console.error('Decryption failed:', error)
    return ''
  }
}
