interface ChartDot {
  cx: number
  cy: number
}

const ChartDot: React.FC<ChartDot> = ({ cx, cy }) => {
  return (
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      x={cx - 7.5}
      y={cy - 7.5}>
      <circle
        opacity="0.2"
        cx="7.5"
        cy="7.5"
        r="7"
        fill="#2B747F"
      />
      <circle
        cx="7.5"
        cy="7.5"
        r="4"
        fill="#2B747F"
      />
    </svg>
  )
}

export default ChartDot
