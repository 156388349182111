import { useDrillDownContext } from 'src/context/DrillDownContext'
import allDrillDownJsonData from 'src/pages/members/DrillDown/utils/jsonConfigs'
import { getBreadcrumbLabel } from '../DrillDown'
import DrillDownDrawer from './DrillDownDrawer'
import DrillDownDrawerStack from './DrillDownDrawerStack'

export interface DrillDownWrapperProps {
  drillDownName?: keyof typeof allDrillDownJsonData
  onClose?: () => void
}

export default function DrillDownWrapper(props: DrillDownWrapperProps) {
  const { drillDownName, onClose } = props
  const { stack, setFilterData } = useDrillDownContext()

  const handleClose = () => {
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      <DrillDownDrawer
        open={!!drillDownName}
        drillDownName={drillDownName}
        expand={stack.length > 0}
        onClose={handleClose}
        setFilterData={(data, drillDownName) => setFilterData(data, drillDownName, getBreadcrumbLabel(data), -1)}
        onFilterUpdate={() => {}}
      />
      {!!drillDownName && <DrillDownDrawerStack />}
    </>
  )
}
