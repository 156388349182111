import { Box, SxProps } from '@mui/material'
import React from 'react'
import DoordashImg from 'src/assets/images/doordash.png'
import EzCaterImg from 'src/assets/images/ezcater.png'
import GrubhubImg from 'src/assets/images/grubhub.png'
import HelpScoutImg from 'src/assets/images/helpscout.png'
import OloImg from 'src/assets/images/olo.png'
import ShortBrickAndMortarMImg from 'src/assets/images/short_b&m.png'
import ShortBrandsImg from 'src/assets/images/short_brands.png'
import ShortCrunchtimeImg from 'src/assets/images/short_crunchtime.png'
import ShortDoordashImg from 'src/assets/images/short_doordash.png'
import ShortEzCaterImg from 'src/assets/images/short_ezcater.png'
import ShortGrubhubImg from 'src/assets/images/short_grubhub.png'
import ShortHelpScoutImg from 'src/assets/images/short_helpscout.png'
import ShortOloImg from 'src/assets/images/short_olo.png'
import ShortToastImg from 'src/assets/images/short_toast.png'
import ShortUberEatsImg from 'src/assets/images/short_ubereats.png'
import ShortVirtualStoreFontsImg from 'src/assets/images/short_virtual_store_fonts.png'
import UberEatsImg from 'src/assets/images/ubereats.png'

const platformImg = {
  doordash: DoordashImg,
  ubereats: UberEatsImg,
  grubhub: GrubhubImg,
  ezcater: EzCaterImg,
  olo: OloImg,
  helpscout: HelpScoutImg,
  short_doordash: ShortDoordashImg,
  short_ubereats: ShortUberEatsImg,
  short_grubhub: ShortGrubhubImg,
  short_ezcater: ShortEzCaterImg,
  short_olo: ShortOloImg,
  short_helpscout: ShortHelpScoutImg,
  short_virtual_store_fronts: ShortVirtualStoreFontsImg,
  short_brands: ShortBrandsImg,
  short_brick_and_mortar: ShortBrickAndMortarMImg,
  short_location: ShortBrickAndMortarMImg,
  short_locations: ShortBrickAndMortarMImg,
  short_toast: ShortToastImg,
  short_crunchtime: ShortCrunchtimeImg
}

interface PlatformLogoProps {
  platformName: string
  height: string
  width?: string
  boxSx?: SxProps
  shortImg?: boolean
}

const PlatformLogo = (props: PlatformLogoProps) => {
  const { platformName, shortImg, height, width = '100%', boxSx } = props

  const key = React.useMemo(() => {
    if (!platformName) return ''

    if (shortImg) {
      return `short_${platformName.toLowerCase().replace(/ /g, '_')}`
    }
    return platformName.toLowerCase()
  }, [platformName, shortImg])

  return (
    <Box
      flex={1}
      display="flex"
      alignItems="center"
      sx={{ ...boxSx }}>
      {key in platformImg && (
        <img
          src={platformImg[key]}
          alt={platformName}
          style={{ width: width, height: height, objectFit: 'contain' }}
        />
      )}
    </Box>
  )
}

export default PlatformLogo
