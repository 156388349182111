import { SvgIcon, SvgIconProps } from '@mui/material'
import { get } from 'lodash'

const WarningDiamondIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="12"
        height="13"
        viewBox="0 0 12 13"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.19311 12.0996C6.03864 12.0996 5.89141 12.0706 5.75142 12.0127C5.61143 11.9548 5.48351 11.8727 5.36766 11.7665L0.719063 7.11793C0.612865 7.00208 0.530802 6.87416 0.472876 6.73417C0.41495 6.59418 0.385986 6.44695 0.385986 6.29248C0.385986 6.13801 0.41495 5.98836 0.472876 5.84355C0.530802 5.69873 0.612865 5.57322 0.719063 5.46703L5.36766 0.818429C5.48351 0.702576 5.61143 0.6181 5.75142 0.565C5.89141 0.511901 6.03864 0.485352 6.19311 0.485352C6.34758 0.485352 6.49723 0.511901 6.64204 0.565C6.78686 0.6181 6.91236 0.702576 7.01856 0.818429L11.6672 5.46703C11.783 5.57322 11.8675 5.69873 11.9206 5.84355C11.9737 5.98836 12.0002 6.13801 12.0002 6.29248C12.0002 6.44695 11.9737 6.59418 11.9206 6.73417C11.8675 6.87416 11.783 7.00208 11.6672 7.11793L7.01856 11.7665C6.91236 11.8727 6.78686 11.9548 6.64204 12.0127C6.49723 12.0706 6.34758 12.0996 6.19311 12.0996ZM5.61385 6.87174H6.77238V3.39616H5.61385V6.87174ZM6.19311 8.60954C6.35724 8.60954 6.49481 8.55402 6.60584 8.443C6.71686 8.33197 6.77238 8.1944 6.77238 8.03027C6.77238 7.86615 6.71686 7.72857 6.60584 7.61754C6.49481 7.50652 6.35724 7.45101 6.19311 7.45101C6.02899 7.45101 5.89141 7.50652 5.78039 7.61754C5.66936 7.72857 5.61385 7.86615 5.61385 8.03027C5.61385 8.1944 5.66936 8.33197 5.78039 8.443C5.89141 8.55402 6.02899 8.60954 6.19311 8.60954Z"
          fill={get(props, 'sx.color', '#000000')}
        />
      </svg>
    </SvgIcon>
  )
}

export default WarningDiamondIcon
