import { MultiSelect, SingleSelect } from '@LoopKitchen/loop-ui'
import React from 'react'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { FilterNode } from 'src/context/NewFilterContext/utils/FilterNode'
import { FilterGraphFilterValueType } from 'src/context/NewFilterContext/utils/types'
import { platformNames } from 'src/utils/config/config'
import { getFilterIcon, getFilterName } from '../utils/filterUtils'
import { SearchableSelectProps } from './SearchableSelect'

interface SelectWrapperProps {
  filterKey: string
  renderValue: (selectedData: (string | number)[]) => React.ReactNode
  showFilterOutsideDropdown?: boolean
  fullWidthFilters?: boolean
}

type ObserverFunctionType = (observerKey: string, subjectSelectedNodes: FilterNode[]) => void

export interface SelectWrapperRef {
  subscribe: (observerKey: string, observerUpdateFn: ObserverFunctionType) => void
  unsubscribe: (observerKey: string) => void
}

const SelectWrapper = React.forwardRef<SelectWrapperRef, SelectWrapperProps>((props, ref) => {
  const { filterKey, showFilterOutsideDropdown = true, fullWidthFilters = false } = props
  const { activeGraph, reRenderFilterContext } = useNewFilterContext()

  const allPossible: FilterGraphFilterValueType['allPossible'] = activeGraph.getAllPossibleNodesByKey(filterKey)
  const visibleSet: FilterGraphFilterValueType['visible'] = activeGraph.getVisibleNodesByKey(filterKey)
  const selectedSet: FilterGraphFilterValueType['selected'] = activeGraph.getSelectedNodesByKey(filterKey)
  const multiple: FilterGraphFilterValueType['isSingleSelect'] = !activeGraph.getSingleSelectByKey(filterKey)

  // ************************** observer pattern code *******************************
  const observers = React.useRef<
    {
      key: string
      update: ObserverFunctionType
    }[]
  >([])

  React.useImperativeHandle(ref, () => {
    return {
      subscribe(observerKey, observerUpdateFn) {
        if (observers.current.find((e) => e.key === observerKey)) return
        observers.current.push({ key: observerKey, update: observerUpdateFn })
      },
      unsubscribe(observerKey) {
        observers.current = observers.current.filter((e) => e.key !== observerKey)
      }
    }
  }, [])

  const notifyObservers = (filterNodes) => {
    observers.current.forEach((observer) => {
      observer.update(observer.key, filterNodes)
    })
  }

  // ************************** memos *******************************
  const options = React.useMemo(() => {
    const visibleArr = [...visibleSet]
    if (filterKey === 'vb_platform') {
      return visibleArr.map((e) => ({ label: platformNames[e.value.toString()], value: e.value }))
    }
    return visibleArr.map((e) => ({ label: e.value.toString(), value: e.value }))
  }, [filterKey, visibleSet])

  const value = React.useMemo(() => {
    const selectedArr = [...selectedSet]
    if (multiple) {
      return selectedArr.map((e) => e.value)
    }
    return selectedArr.length > 0 && selectedArr[0] ? selectedArr[0].value : ''
  }, [multiple, filterKey, selectedSet])

  // *********************** event handlers ***************************
  const handleChange: SearchableSelectProps['onChange'] = (data) => {
    let filterNodes: Set<FilterNode> = new Set()

    if (Array.isArray(data)) {
      if (data.length === 0) {
        filterNodes = new Set(allPossible)
      } else {
        data.forEach((val) => {
          const node = allPossible.find((obj) => obj.value === val)
          if (node) {
            filterNodes.add(node)
          }
        })
      }
    } else {
      const node = allPossible.find((obj) => obj.value === data)
      if (node) {
        filterNodes.add(node)
      }
    }

    activeGraph.setSelected(filterKey, filterNodes)
    notifyObservers(Array.isArray(data) && data.length === 0 ? [] : [...filterNodes])
    reRenderFilterContext()
  }

  return multiple ? (
    <MultiSelect
      options={options}
      value={Array.isArray(value) && value}
      onChange={handleChange}
      label={getFilterName(filterKey)}
      icon={getFilterIcon(filterKey)}
      listAnchorPosition={showFilterOutsideDropdown ? { vertical: 'bottom', horizontal: 'left' } : { vertical: 'top', horizontal: 'right' }}
      renderValue={
        allPossible.length === visibleSet.size && visibleSet.size === selectedSet.size
          ? (selectedValue) => (
              <span style={{ lineHeight: 1.5 }}>
                <span style={{ fontWeight: 600 }}>All ({allPossible.length})</span> {`${getFilterName(filterKey)}s`}
              </span>
            )
          : undefined
      }
      disableScrollLock
      selectButtonSx={{
        width: fullWidthFilters ? '100%' : 'auto',
        py: fullWidthFilters ? 1.5 : '12px',
        px: '8px',
        pr: 'calc(24px + 8px)',
        justifyContent: fullWidthFilters ? 'flex-start' : 'center',
        '& .MuiButton-endIcon': {
          flexGrow: fullWidthFilters ? 1 : 0,
          justifyContent: fullWidthFilters ? 'flex-end' : 'normal'
        }
      }}
      disableAllInLabel
      showSelectedFirst={filterKey !== 'vb_platform'}
    />
  ) : (
    <SingleSelect
      options={options}
      value={!Array.isArray(value) && value}
      onChange={handleChange}
      label={getFilterName(filterKey)}
      icon={getFilterIcon(filterKey)}
      disableScrollLock
      selectButtonSx={{
        width: fullWidthFilters ? '100%' : 'auto',
        py: fullWidthFilters ? 1.5 : '12px',
        px: '8px',
        pr: 'calc(24px + 8px)',
        justifyContent: fullWidthFilters ? 'flex-start' : 'center',
        '& .MuiButton-endIcon': {
          flexGrow: fullWidthFilters ? 1 : 0,
          justifyContent: fullWidthFilters ? 'flex-end' : 'normal'
        }
      }}
    />
  )
})

export default SelectWrapper
