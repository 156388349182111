import { Box, Skeleton, SxProps, Typography } from '@mui/material'
import React from 'react'

interface IconCardProps {
  icon: React.ReactNode
  label: string
  title: string | React.ReactNode
  sx?: SxProps
  bottomComp?: React.ReactNode
  loading?: boolean
}

export default function IconCard(props: IconCardProps) {
  const { icon, label, title, sx, bottomComp, loading } = props
  return (
    <Box
      sx={{
        px: '20px',
        py: '17px',
        bgcolor: 'white',
        border: '1px solid rgba(230, 230, 230, 1)',
        borderRadius: '4px',
        position: 'relative',
        ...sx
      }}>
      <Box sx={{ visibility: 'visible' }}>
        <Box sx={{ fontSize: '30px' }}>
          {loading ? (
            <Skeleton
              variant="text"
              width={'34px'}
            />
          ) : (
            icon
          )}
        </Box>
        <Box sx={{ mt: '15px' }}>
          {loading ? (
            <Skeleton
              variant="text"
              height={36}
              width={200}
            />
          ) : (
            <Typography
              className="card-label"
              fontSize="14px"
              fontWeight={600}
              lineHeight={'18px'}
              sx={{
                height: '36px',
                display: '-webkit-box',
                boxOrient: 'vertical',
                lineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis'
              }}>
              {label}
            </Typography>
          )}
        </Box>
        <Box sx={{ mt: '10px' }}>
          {loading ? (
            <Skeleton variant="text" />
          ) : (
            <Typography
              className="card-title"
              fontSize="32px"
              fontWeight={600}
              lineHeight="100%">
              {title}
            </Typography>
          )}
        </Box>

        {bottomComp && <Box>{bottomComp}</Box>}
      </Box>
    </Box>
  )
}
