import KeyboardArrowDownTwoToneIcon from '@mui/icons-material/KeyboardArrowDownTwoTone'
import KeyboardArrowUpTwoToneIcon from '@mui/icons-material/KeyboardArrowUpTwoTone'
import { Icon, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import React from 'react'
import { NavLink } from 'react-router-dom'
import premium from 'src/assets/icons/premium.svg'
import { useAuth } from 'src/context/AuthContext'
import { activeColor, inactiveColor } from './SubNav'

type LinkProps = {
  icon: string | { label: string; value: string }
  to: string
  label: string
  open: boolean
  closeDrawer: () => void
  isSubNav: boolean
  isLinkActive: boolean
  pageTitle?: string
  type?: string
  closePopper?: () => void
  newRoute?: boolean
}

const CustomLink: React.FC<LinkProps> = ({
  icon,
  to,
  label,
  open,
  closeDrawer,
  isSubNav = 'false',
  isLinkActive = 'false',
  pageTitle,
  type,
  closePopper,
  newRoute
}) => {
  const href = to.includes('datastudio') ? `${window.location.origin}/${to.indexOf('datastudio')}` : to
  const { posthogCapture } = useAuth()

  return (
    <NavLink
      to={href}
      onClick={() => {
        posthogCapture('sidebar_link_clicked', { href })
        closeDrawer()
        closePopper?.()
      }}
      style={{ textDecoration: 'none' }}>
      {({ isActive }) => {
        if (isActive) document.title = pageTitle || `Loop - ${label}`
        return (
          <div>
            <ListItem
              selected={isActive}
              disablePadding
              sx={{
                color: isActive ? activeColor : inactiveColor,
                '&.Mui-selected': { bgcolor: 'transparent' }
              }}>
              <ListItemButton
                sx={{
                  py: '10px',
                  px: '12px',
                  mx: '4px',
                  bgcolor: isActive ? 'rgba(25, 110, 130, 0.5)' : 'transparent',
                  outline: isActive ? '1px solid #196E82' : 'none',
                  outlineOffset: '-1px',
                  '&:hover': {
                    outline: '1px solid #196E82',
                    bgcolor: isActive ? 'rgba(25, 110, 130, 0.5)' : 'transparent'
                  },
                  borderRadius: '4px',
                  position: 'relative'
                }}>
                {/* {newRoute && (
                  <Box sx={{ position: 'absolute', top: '6px', left: '3px', width: '8px', height: '8px', bgcolor: '#47E44E', borderRadius: '50%' }} />
                )} */}
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: '12px',
                    justifyContent: 'center',
                    color: 'inherit'
                  }}>
                  <Icon sx={{ fontSize: '22px' }}>{icon ? (typeof icon === 'string' ? icon : icon.value) : 'block'}</Icon>
                </ListItemIcon>
                <ListItemText
                  primary={label}
                  color="inherit"
                  sx={{
                    '& span': {
                      fontWeight: isActive && 600,
                      whiteSpace: 'normal',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis'
                    }
                  }}
                />
                {type === 'preview' && (
                  <ListItemIcon
                    sx={{
                      minWidth: 20,
                      ml: 0.5,
                      color: 'inherit'
                    }}>
                    <img
                      src={premium}
                      alt="Connect to customer success team"
                      width={20}
                      height={20}
                      style={{ marginTop: '-2px' }}
                    />
                  </ListItemIcon>
                )}
              </ListItemButton>

              {open && isSubNav && (isLinkActive ? <KeyboardArrowUpTwoToneIcon /> : <KeyboardArrowDownTwoToneIcon />)}
            </ListItem>
          </div>
        )
      }}
    </NavLink>
  )
}

export default CustomLink
