import { decrypt, encrypt } from './encryptionDecryption'

export function getLocal(key: string, withDecryption?: boolean) {
  try {
    const localData = localStorage.getItem(key)
    if (!localData) return null
    if (!withDecryption) return JSON.parse(localData)
    return JSON.parse(decrypt(localData))
  } catch (error) {
    return undefined
  }
}
export function setLocal(key: string, data: any, withEncryption?: boolean) {
  try {
    if (!withEncryption) localStorage.setItem(key, JSON.stringify(data))
    else localStorage.setItem(key, encrypt(JSON.stringify(data)))
  } catch (error) {
    return undefined
  }
}
