import { Tonality } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useFilter } from 'src/context/FilterContext'

interface RefreshStatusSmallProps {
  closeDrawer?: () => void
}

const RefreshStatusSmall = ({ closeDrawer }: RefreshStatusSmallProps) => {
  const navigate = useNavigate()
  const { lastDate } = useFilter()

  return (
    <Box
      onClick={() => {
        navigate('/data-refresh-status')
        closeDrawer?.()
      }}
      sx={{
        m: '2px',
        mb: 1,
        px: 1,
        py: 1,
        borderRadius: '2px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        gap: '4px',
        alignItems: 'center',
        backgroundColor: '#091F2D',
        cursor: 'pointer'
      }}>
      <Tonality sx={{ color: '#0F783C' }} />
      <Typography
        variant="subtitle1"
        color="#FFFFFF"
        sx={{ fontSize: 10, fontWeight: 400, lineHeight: 'initial' }}>
        {lastDate ? moment(lastDate).format('D MMM, YY') : 'No Data'}
      </Typography>
    </Box>
  )
}

export default RefreshStatusSmall
