import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace'
import { ButtonBase } from '@mui/material'

interface BackArrowBtnCompProps {
  onClick?: () => void
}

export default function BackArrowBtnComp(props: BackArrowBtnCompProps) {
  const { onClick } = props

  const handleClose = () => {
    if (onClick) {
      onClick()
    }
  }

  return (
    <ButtonBase
      onClick={handleClose}
      sx={{
        bgcolor: 'rgba(238, 237, 237, 1)',
        width: '30px',
        height: '30px',
        borderRadius: '2px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}>
      <KeyboardBackspaceIcon sx={{ fontSize: '20px', color: '#000' }} />
    </ButtonBase>
  )
}
