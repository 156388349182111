import Balance from 'src/assets/icons/sidebar/balance.svg'
import Base from 'src/assets/icons/sidebar/base.svg'
import Guard from 'src/assets/icons/sidebar/guard.svg'
import Others from 'src/assets/icons/sidebar/others.svg'
import ReEngage from 'src/assets/icons/sidebar/re_engage.svg'
import Recover from 'src/assets/icons/sidebar/recover.svg'
import SystemConfiguration from 'src/assets/icons/sidebar/system_configuration.svg'
import TruROI from 'src/assets/icons/sidebar/truroi.svg'
import Wizard from 'src/assets/icons/sidebar/wizard.svg'

export const CategoryIconMapping = {
  wizard: Wizard,
  base_routes: Base,
  recover: Recover,
  guard: Guard,
  balance: Balance,
  truroi: TruROI,
  re_engage: ReEngage,
  system_configuration: SystemConfiguration,
  others: Others
}
