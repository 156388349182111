// ButtonWithLoader.tsx
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import React from 'react'

interface ButtonWithLoaderProps {
  loading: boolean
  onClick: () => void
  buttonText: string
  [key: string]: any
}

const ButtonWithLoader: React.FC<ButtonWithLoaderProps> = ({ loading, onClick, buttonText, ...props }) => {
  return (
    <Button
      {...props}
      disabled={loading || props.disabled}
      onClick={onClick}>
      {loading ? (
        <CircularProgress
          size={24}
          color="inherit"
        />
      ) : (
        buttonText
      )}
    </Button>
  )
}

export default ButtonWithLoader
