import { Box, Icon, Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import premiumOutlined from 'src/assets/icons/premium-outlined.svg'
import premium from 'src/assets/icons/premium.svg'
import { CategorizedRoutesType, usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import { drawerCloseWidth } from '..'
import { CategoryIconMapping } from './CategoryIconMapping'

interface CategoryTabProps {
  category: CategorizedRoutesType
  activeCategory: string
  selectedCategory: string
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>
  firstRoute: string
}

const CategoryTab = ({ category, activeCategory, selectedCategory, setSelectedCategory, setAnchorEl, firstRoute }: CategoryTabProps) => {
  const { open } = usePrivateLayoutDrawerContext()
  const navigate = useNavigate()
  const isPreview = category.routes.every((route) => route.type === 'preview')
  const isPreviewSome = isPreview ? false : category.routes.some((route) => route.type === 'preview')
  const isActive = activeCategory === get(category, 'category.key', null)
  const isSelected = selectedCategory === get(category, 'category.key', null)
  // const newRoute = category.routes.find((route) => route.created_at && moment().diff(moment(route.created_at), 'days') < 7)

  return (
    <Box
      key={get(category, 'category.key', null)}
      width={'100%'}
      height={drawerCloseWidth - 2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        cursor: 'pointer',
        '&:hover > div': {
          outline: '1px solid #196E82'
        }
      }}
      onClick={(e) => {
        setSelectedCategory(get(category, 'category.key', null))
        navigate(firstRoute)
      }}
      onMouseEnter={(e) => {
        if (!open) {
          setAnchorEl(e.currentTarget)
          setSelectedCategory(get(category, 'category.key', null))
        }
      }}>
      <Box
        width={drawerCloseWidth - 6}
        height={drawerCloseWidth - 6}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        borderRadius="4px"
        bgcolor={isActive ? '#196E8273' : 'transparent'}
        sx={{
          transition: 'background-color 0.3s',
          outline: isActive || isSelected ? '1px solid #196E82' : 'none',
          outlineOffset: '-1px',
          cursor: 'pointer',
          '&:hover > div': {
            outline: '1px solid #196E82'
          }
        }}
        onClick={(e) => {
          setSelectedCategory(get(category, 'category.key', null))
          navigate(firstRoute)
        }}
        onMouseEnter={(e) => {
          if (!open) {
            setAnchorEl(e.currentTarget)
            setSelectedCategory(get(category, 'category.key', null))
          }
        }}>
        {(isPreview || isPreviewSome) && (
          <img
            src={isPreview ? premium : premiumOutlined}
            alt="Connect to customer success team"
            width={16}
            height={16}
            style={{
              position: 'absolute',
              top: '4px',
              right: '4px'
            }}
          />
        )}
        {/* {newRoute && (
                              <Box
                                sx={{ position: 'absolute', top: '6px', left: '6px', width: '8px', height: '8px', bgcolor: '#47E44E', borderRadius: '50%' }}
                              />
                            )} */}
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={0.5}
          p={'8px'}
          width="100%">
          {CategoryIconMapping[get(category, 'category.key', null)] ? (
            <img
              src={CategoryIconMapping[get(category, 'category.key', null)]}
              alt="category"
              style={{ width: '24px', height: '24px' }}
            />
          ) : (
            <Icon sx={{ fontSize: '24px' }}>{get(category, 'category.icon.value', 'others')}</Icon>
          )}

          <Typography
            sx={{ color: '#ffffff', fontSize: '11px', textAlign: 'center', textOverflow: 'ellipsis', width: '100%' }}
            noWrap>
            {get(category, 'category.name', 'Others')}
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default CategoryTab
