import axios from 'axios'
import { BASE_FIREBASE_FUNCTIONS_URL } from '../config/config'

interface ValidateUserTokenRequestBody {
  idToken: string
}

export const getSigninToken = async (params: ValidateUserTokenRequestBody) => {
  try {
    const res = await axios.post(`${BASE_FIREBASE_FUNCTIONS_URL}/auth/create/custom_token`, params, {
      withCredentials: true
    })
    return res.data
  } catch (err) {
    console.error('getSigninToken error', err)
  }
}
