import { Skeleton, Typography } from '@mui/material'
import { formatCurrency } from 'src/utils/functions'
import HeadingContainer from './helpers/HeadingContainer'

interface NetPayoutCompProps {
  netPayout?: number
  loading?: boolean
  errorCategory?: string
}

export default function NetPayoutComp(props: NetPayoutCompProps) {
  const { netPayout, loading, errorCategory } = props

  return (
    <HeadingContainer
      icon={'account_balance_wallet'}
      title={'Net Payout'}>
      {loading ? (
        <Skeleton
          variant="rounded"
          width="100%"
          height="32px"
        />
      ) : (
        <Typography
          fontSize="32px"
          fontWeight={600}
          color="#1E1E1E"
          textAlign="right">
          {formatCurrency(netPayout, { maxFractionDigits: 2 })}
        </Typography>
      )}
    </HeadingContainer>
  )
}
