import React from 'react'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { DefaultService, PayoutBreakdownResponse } from 'src/services/openApi'

export interface FontSizesType {
  '11': number
  '12': number
  '13': number
  '14': number
  '16': number
  '18': number
  '20': number
  '22': number
  '24': number
}

const defaultFontSizes = {
  '11': 11,
  '12': 12,
  '13': 13,
  '14': 14,
  '16': 16,
  '18': 18,
  '20': 20,
  '22': 22,
  '24': 24
}

interface PayoutsBreakdownDataContextInterface {
  breakdownData: PayoutBreakdownResponse
  breakdownLoading: boolean
  fontSizes: FontSizesType
  setFontSizes: React.Dispatch<React.SetStateAction<FontSizesType>>
  zoom: number
  setZoom: React.Dispatch<React.SetStateAction<number>>
  channel: string[]
  setChannel: React.Dispatch<React.SetStateAction<string[]>>
  fulfillment: string[]
  setFulfillment: React.Dispatch<React.SetStateAction<string[]>>
}

const PayoutsBreakdownDataContext = React.createContext({} as PayoutsBreakdownDataContextInterface)

export const usePayoutsBreakdownDataContext = () => React.useContext(PayoutsBreakdownDataContext)

interface PayoutsBreakdownDataContextProviderProps {
  children: React.ReactNode
}

export default function PayoutsBreakdownDataContextProvider(props: PayoutsBreakdownDataContextProviderProps) {
  const { getFilters, getFiltersV2 } = useFilter()
  const { handleError } = useErrorData()
  const [channel, setChannel] = React.useState<string[]>([])
  const [fulfillment, setFulfillment] = React.useState<string[]>([])
  const [breakdownData, setBreakdownData] = React.useState<PayoutBreakdownResponse>(null)
  const [breakdownLoading, setBreakdownLoading] = React.useState(false)
  const [fontSizes, setFontSizes] = React.useState<FontSizesType>(defaultFontSizes)
  const [zoom, setZoom] = React.useState(100)

  const filterObj = React.useMemo(() => {
    const obj = getFiltersV2(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'], true)

    return {
      ...obj,
      channel_in: channel?.join('|') || '',
      fulfillment_in: fulfillment?.join('|') || ''
    }
  }, [channel, fulfillment, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'chain', 'start_date', 'end_date'])])

  React.useEffect(() => {
    const getData = async () => {
      setBreakdownLoading(true)
      setBreakdownData(null)
      try {
        let res = await DefaultService.getPayoutBreakdownFinancePayoutBreakdownPost(filterObj)
        setBreakdownData(res)
      } catch (err) {
        handleError(err.message)
      }
      setBreakdownLoading(false)
    }
    getData()
  }, [filterObj])

  const contextValue = React.useMemo(() => {
    return {
      breakdownData,
      breakdownLoading,
      fontSizes,
      setFontSizes,
      zoom,
      setZoom,
      channel,
      setChannel,
      fulfillment,
      setFulfillment
    }
  }, [breakdownData, breakdownLoading, fontSizes, setFontSizes, zoom, setZoom, channel, fulfillment])

  return <PayoutsBreakdownDataContext.Provider value={contextValue}>{props.children}</PayoutsBreakdownDataContext.Provider>
}
