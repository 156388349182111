import { createContext, useContext, useMemo, useState } from 'react'
import Page from 'src/components/mui/Page'
import ChannelSelect from 'src/components/Select/ChannelSelect'
import FulfillmentSelect from 'src/components/Select/FulfillmentSelect'
import { Modules } from 'src/utils/config/config'
import Overview from './components/Overview'

interface MarketingTrendsLocalContext {
  channel?: string[]
  fulfillment?: string[]
}

const MarketingTrendsLocalContext = createContext<MarketingTrendsLocalContext>({})

export const useMarketingTrendsLocalContext = () => useContext(MarketingTrendsLocalContext) || { channel: [], fulfillment: [] }

interface TrendsProps {}

export default function Trends(props: TrendsProps) {
  const [channel, setChannel] = useState<string[]>([])
  const [fulfillment, setFulfillment] = useState<string[]>([])
  const contextValue = useMemo(() => ({ channel, fulfillment }), [channel, fulfillment])

  return (
    <>
      <MarketingTrendsLocalContext.Provider value={contextValue}>
        <Page
          title={`${Modules.Marketing} || Trends`}
          filterProps={{
            showExtraFiltersAtEnd: true,
            extraFilters: [
              <ChannelSelect
                key="channel-select"
                value={channel}
                onChange={setChannel}
                selectButtonSx={{ height: '42px' }}
              />,
              <FulfillmentSelect
                key="fulfillment-select"
                value={fulfillment}
                onChange={setFulfillment}
                selectButtonSx={{ height: '42px' }}
              />
            ],
            onResetCallback: () => {
              setChannel([])
              setFulfillment([])
            }
          }}
          sx={{ mx: 3, my: 4 }}>
          <Overview />
        </Page>
      </MarketingTrendsLocalContext.Provider>
    </>
  )
}
