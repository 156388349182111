import Box from '@mui/material/Box'
import Dialog from '@mui/material/Dialog'
import { useTheme } from '@mui/material/styles'
import React, { Suspense, useRef } from 'react'
import { Outlet } from 'react-router-dom'
import { useAuth } from 'src/context/AuthContext'
// import { fetchPanelSettings } from '../services/firebase'
import { Typography } from '@mui/material'
import html2canvas from 'html2canvas'
import { get } from 'lodash'
import CopyUrlWithFilter from 'src/components/CopyUrlWithFilter'
import LinkPhone from 'src/components/LinkPhone'
import FallbackLoader from 'src/components/Loading/FallbackLoader'
import { useBugReportContext } from 'src/context/BugReportContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import { useTopbarContext } from 'src/context/TopbarContext'
import Topbar from 'src/layout/Topbar'
import { getLocal } from 'src/utils/functions/localStorage'
import PrivateLayoutDrawer from './components/PrivateLayoutDrawer'

const NoAccessPage = React.lazy(() => import('src/pages/noAccess/NoAccess'))

export default function PrivateLayout({ noAccess }) {
  const theme: any = useTheme()
  const { open } = usePrivateLayoutDrawerContext()
  const { currentUser } = useAuth()
  const { handleError } = useErrorData()
  const { isLoading } = useFilter()
  const [showPhone, setShowPhone] = React.useState(false)
  const { title, copyUrl } = useTopbarContext()
  const { bannerOpen, setBannerOpen } = useBugReportContext()
  const pageImageRef = useRef<HTMLElement>(null)

  React.useEffect(() => {
    if (currentUser) {
      const localPhone = getLocal('no-phone')
      if (localPhone || currentUser.phoneNumber) setShowPhone(false)
      if (!currentUser.phoneNumber && !localPhone) setShowPhone(true)
    }
  }, [currentUser])

  const handleCapture = async () => {
    try {
      if (!pageImageRef.current) return

      setBannerOpen((prev) => ({
        open: true,
        data: {
          message: '',
          image: null,
          imageLoading: true
        }
      }))

      const canvas = await html2canvas(pageImageRef.current)
      const img = canvas.toDataURL('image/jpeg')

      if (img) {
        setBannerOpen((prev) => ({
          ...prev,
          data: {
            message: '',
            image: img,
            imageLoading: false
          }
        }))
      } else {
        setBannerOpen((prev) => ({
          ...prev,
          data: {
            message: '',
            image: null,
            imageLoading: false
          }
        }))

        handleError('Error while capturing image')
      }
    } catch (err) {
      handleError(err)
    }
  }

  return (
    <Box
      sx={{ display: 'flex' }}
      id={'main_start_app'}
      ref={pageImageRef}>
      <Dialog open={showPhone}>
        <LinkPhone togglePhone={() => setShowPhone((prev) => !prev)} />
      </Dialog>
      <PrivateLayoutDrawer />

      <Box
        component="main"
        sx={{
          minWidth: '0px',
          flexGrow: 1,
          // zIndex: theme.zIndex.appBar - 2,
          bgcolor: theme.colors.background,
          minHeight: '100vh',
          flex: '1 1 auto',
          overflowX: 'clip',
          textAlign: 'left'
        }}>
        <Box
          sx={{
            zIndex: theme.zIndex.appBar - 10
          }}>
          {!isLoading ? (
            <>
              {noAccess ? (
                <NoAccessPage />
              ) : (
                <Topbar
                  drawerState={open}
                  handleReport={handleCapture}
                  disableReport={get(bannerOpen, 'open', false)}
                  title={
                    <Box ml={open ? '30px' : '20px'}>
                      {typeof title === 'string' ? (
                        <Box
                          sx={{
                            mb: 2,
                            display: 'flex',
                            pt: 2.5,
                            height: '100%',
                            color: 'black'
                          }}>
                          <Typography
                            variant="h3"
                            noWrap
                            sx={{
                              marginLeft: '8px',
                              m: 0,
                              maxWidth: { sm: 120, md: 400 }
                            }}>
                            {title}{' '}
                          </Typography>
                          {copyUrl && <CopyUrlWithFilter />}
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            mb: 2,
                            display: 'flex',
                            pt: 2.5,
                            height: '100%',
                            color: 'black'
                          }}>
                          {title}
                          {copyUrl && <CopyUrlWithFilter />}
                        </Box>
                      )}
                    </Box>
                  }>
                  <Suspense fallback={<FallbackLoader />}>
                    <Outlet context={{ drawerState: open }} />{' '}
                  </Suspense>
                </Topbar>
              )}
            </>
          ) : (
            <></>
          )}
        </Box>
      </Box>
    </Box>
  )
}
