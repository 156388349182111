import { CustomMaterialTable } from '@LoopKitchen/loop-ui'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { Typography } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'
import { DefaultService } from 'src/services/openApi'
import { Modules, filterNames } from 'src/utils/config/config'
import { formatCurrency } from 'src/utils/functions'
import { measurePerformance } from 'src/utils/functions/performance'

interface InnerTableProps {
  b_name: string
  error_category: string
  goToUrlWithVbNameFilters: (b_name: string, vb_name: string) => string
}

type TableType = {
  brands: {
    b_name: string
    vb_name: string
    current_error_orders: number
    previous_error_orders: number
    lost_sale: number
    error_charges: number
    current_error_rate: number
    previous_error_rate: number
    total_current_orders: number
    total_previous_orders: number
    error_rate_delta: number
  }[]
  next_offset: number | null
  max_rows: number
}

export default function InnerTable(props: InnerTableProps) {
  const { currentUser } = useAuth()
  const [tableObj, setTableObj] = React.useState<TableType | null>(null)
  const [loading, setLoading] = React.useState(false)
  const { getFiltersV2 } = useFilter()
  const navigate = useNavigate()

  const getTableData = async (obj: { limit: number; offset: number }) => {
    setLoading(true)
    try {
      const res = (await measurePerformance(
        async () => {
          return await DefaultService.getErrorOverviewByBrandKpiErrorOverviewBrandPost({
            ...getFiltersV2(['vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date'], true),
            b_name_in: props.b_name,
            error_category_in: props.error_category,
            limit: obj.limit,
            offset: obj.offset
          })
        },
        {
          procedure: 'kpi/error_overview/brand',
          email: currentUser.email,
          org: currentUser.org
        }
      )) as TableType
      setTableObj(res)
    } catch (err) {
      console.log(err)
    }
    setLoading(false)
  }

  React.useEffect(() => {
    getTableData({ limit: 100, offset: 0 })
  }, [props.b_name, props.error_category])

  return (
    <CustomMaterialTable
      isLoading={loading}
      data={get(tableObj, 'brands', [] as typeof tableObj.brands)}
      columns={[
        { title: filterNames.vb_name, field: 'vb_name' },
        { title: 'Total Errors', field: 'current_error_orders' },
        {
          title: 'Potential ' + Modules.LostSales,
          field: 'unfulfilled_sales',
          render: (data) => {
            return (
              <>
                <Typography
                  variant="body2"
                  sx={{ mr: 2 }}>
                  {formatCurrency(get(data, 'unfulfilled_sales', 0))}
                </Typography>
              </>
            )
          }
        },
        {
          title: Modules.Chargebacks,
          field: 'customer_refunds',
          render: (data) => {
            return (
              <>
                <Typography
                  variant="body2"
                  sx={{ mr: 2 }}>
                  {formatCurrency(get(data, 'customer_refunds', 0))}
                </Typography>
              </>
            )
          }
        },
        {
          title: Modules.UnfulfilledRefunds,
          field: 'unfulfilled_refunds',
          render: (data) => {
            return (
              <>
                <Typography
                  variant="body2"
                  sx={{ mr: 2 }}>
                  {formatCurrency(get(data, 'unfulfilled_refunds', 0))}
                </Typography>
              </>
            )
          }
        },
        {
          title: 'Error Rate',
          field: 'current_error_rate',
          render: (data) => {
            const errorRate = data.current_error_rate
            return (
              <>
                <Typography
                  variant="body2"
                  sx={{ mr: 2 }}>
                  {errorRate}%
                </Typography>
                {Number(errorRate) !== 0 ? errorRate < 0 ? <TrendingDownIcon sx={{ color: 'green' }} /> : <TrendingUpIcon sx={{ color: 'red' }} /> : null}
              </>
            )
          }
        },
        {
          title: '% Delta',
          field: 'error_rate_delta',
          render: (data) => {
            const percentDelta = data.error_rate_delta
            return (
              <>
                <Typography
                  variant="body2"
                  sx={{ mr: 2 }}>
                  {percentDelta !== null ? percentDelta + '%' : percentDelta}
                </Typography>
                {percentDelta !== null && Number(percentDelta) !== 0 ? (
                  percentDelta < 0 ? (
                    <TrendingDownIcon sx={{ color: 'green' }} />
                  ) : (
                    <TrendingUpIcon sx={{ color: 'red' }} />
                  )
                ) : null}
              </>
            )
          }
        }
      ]}
      options={{
        pagination: true,
        pageSize: 100,
        // pageSizeList: [10, 50, 100],
        totalPaginatedDataLength: get(tableObj, 'max_rows', undefined)
      }}
      onLastPage={() => {
        if (get(tableObj, 'next_offset', 0) > 0) {
          getTableData({ limit: 100, offset: get(tableObj, 'next_offset', 0) })
        }
      }}
      onRowClick={(data) => {
        navigate(props.goToUrlWithVbNameFilters(data.b_name, data.vb_name))
      }}
    />
  )
}
