import { Box } from '@mui/material'
import SyncedChartsProvider from 'src/pages/members/MarketingPages/Trends/context/SyncedChartsContext'
import ConversionMetricsCharts from './ConversionMetricsCharts'

export default function ConversionMetricsComp() {
  return (
    <Box
      display="flex"
      flexDirection="column"
      gap={2}>
      <SyncedChartsProvider>
        <ConversionMetricsCharts />
      </SyncedChartsProvider>
    </Box>
  )
}
