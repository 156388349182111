import { Check } from '@mui/icons-material'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove'
import MoveDownIcon from '@mui/icons-material/MoveDown'
import StackedLineChartIcon from '@mui/icons-material/StackedLineChart'
import { Box, ButtonBase, CircularProgress, Grid, Skeleton, Stack, Typography } from '@mui/material'
import { get } from 'lodash'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import { Link } from 'react-router-dom'
import IconCard from 'src/components/IconCard'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { AccountingPushHistoryFilters, DefaultService } from 'src/services/openApi'
import { Dictionary } from 'src/utils/config/dictionary'
import { formatCurrency, formatNumber } from 'src/utils/functions'
import { pluralize } from 'src/utils/functions/pluralize'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import usePlaidAuth from 'src/utils/hooks/usePlaidAuth'
import { JournalPushStatus } from '../../AccountingSetup/AccountingHistory'
import { usePayoutsBreakdownDataContext } from '../utils/context/PayoutsBreakdownDataContext'

export default function CardLayout() {
  const { currentUser } = useAuth()
  const { getFiltersV2, getFilters } = useFilter()
  const { handleError } = useErrorData()
  const { loading, open, bankConnected, error } = usePlaidAuth()
  const { breakdownData, breakdownLoading, fontSizes } = usePayoutsBreakdownDataContext()
  const [lastJournalEntry, setLastJournalEntry] = useState<string>('')
  const [journalEntryLoading, setJournalEntryLoading] = useState<boolean>(true)
  const [companyConnected, setCompanyConnected] = useState<boolean>(false)
  const [companiesLoading, setCompaniesLoading] = useState<boolean>(true)

  const bookkeepingUrl = useMemo(() => {
    if (shouldBlockForUser(currentUser)) return 'sandboxbookkeeping'
    else return 'bookkeeping'
  }, [currentUser])

  const getJournalEntries = async () => {
    setJournalEntryLoading(true)
    try {
      const filtersForQuery = getFiltersV2(['chain', 'b_name', 'b_name_id', 'start_date', 'end_date'], true)
      const requestBody = {
        chain: filtersForQuery.chain_in,
        b_name: filtersForQuery.b_name_in,
        b_name_id: filtersForQuery.b_name_ids_in,
        start_date: filtersForQuery.start_date_in,
        end_date: filtersForQuery.end_date_in,
        dummy: false
      } as AccountingPushHistoryFilters

      const result = await DefaultService.getPushHistoryAccountingJournalEntriesPushHistoryPost(requestBody)

      setLastJournalEntry(
        result
          ?.filter((push) => push.status === JournalPushStatus.SUCCESS)
          ?.sort((a, b) => new Date(b?.push_timestamp).getTime() - new Date(a?.push_timestamp).getTime())[0]?.push_timestamp ?? null
      )
    } catch (err) {
      handleError(err.message)
    }
    setJournalEntryLoading(false)
  }

  const getCompanies = async (initial = true) => {
    setCompaniesLoading(initial)
    try {
      const res = await DefaultService.getAccountingCompaniesAccountingCompanyAllGet(currentUser.org)

      if (res && res.companies && res.companies.length > 0) {
        setCompanyConnected(true)
      } else {
        setCompanyConnected(false)
      }
    } catch (err) {
      handleError(err || "Couldn't fetch companies")
    }
    setCompaniesLoading(false)
  }

  useEffect(() => {
    getCompanies()
  }, [currentUser.org])

  useEffect(() => {
    getJournalEntries()
  }, [...getFilters(['chain', 'b_name', 'b_name_id', 'start_date', 'end_date'])])

  return (
    <Grid
      container
      spacing="15px">
      <Grid
        item
        md={3.5}
        sm={12}>
        <IconCard
          loading={breakdownLoading}
          icon={<StackedLineChartIcon sx={{ color: '#000', fontSize: '30px' }} />}
          label="Variance: Transaction & Payout"
          title={`${formatNumber(get(breakdownData, 'values.variance_percentage', 0), { maxFractionDigits: 2 })}% (${formatCurrency(
            get(breakdownData, 'values.variance_amount', 0),
            { maxFractionDigits: 2 }
          )})`}
          sx={{ height: '100%' }}
          labelSx={{ height: 'auto', color: '#000', fontSize: fontSizes['14'] }}
          titleSx={{ fontSize: fontSizes['20'], lineHeight: 'normal' }}
          subLabelSx={{ fontSize: fontSizes['14'] }}
          topRightComp={
            <Link
              to="/reconciliation/order/based/transactions?orders_trans=list_of_payouts&show_only_non_variance_zero=true"
              style={{ textDecoration: 'none' }}>
              <Stack
                direction={'row'}
                alignItems="center"
                gap="5px"
                sx={{ color: '#196E82' }}>
                <Typography
                  color="inherit"
                  fontSize={fontSizes['13']}>
                  Variance Breakdown
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    color: 'inherit',
                    fontSize: fontSizes['24']
                  }}>
                  <ChevronRightIcon
                    color="inherit"
                    fontSize="inherit"
                  />
                </Box>
              </Stack>
            </Link>
          }
        />
      </Grid>
      <Grid
        item
        md={3.5}
        sm={12}>
        <IconCard
          icon={<MoveDownIcon sx={{ color: '#000', fontSize: '30px' }} />}
          label="Variance : Payout & Bank"
          subLabel={bankConnected ? 'Bank Connected' : loading ? 'Loading...' : 'Not available. Bank not connected'}
          sx={{ height: '100%' }}
          labelSx={{ height: 'auto', color: '#000', fontSize: fontSizes['14'] }}
          titleSx={{ fontSize: fontSizes['20'], lineHeight: 'normal' }}
          subLabelSx={{ fontSize: fontSizes['14'] }}
          topRightComp={
            bankConnected && (
              <Link
                to={`/reconciliation/bank_reconciliation`}
                style={{ textDecoration: 'none' }}>
                <Stack
                  direction={'row'}
                  alignItems="center"
                  gap="5px"
                  sx={{ color: '#196E82' }}>
                  <Typography
                    color="inherit"
                    fontSize={fontSizes['13']}>
                    See bank reconciliation
                  </Typography>
                  <Box
                    sx={{
                      display: 'flex',
                      color: 'inherit',
                      fontSize: fontSizes['24']
                    }}>
                    <ChevronRightIcon
                      color="inherit"
                      fontSize="inherit"
                    />
                  </Box>
                </Stack>
              </Link>
            )
          }
          bottomComp={
            <Box
              sx={{
                mt: '15px',
                px: '20px',
                py: '7px',
                border: '1px solid rgba(238, 238, 238, 0.93)',
                bgcolor: '#FAFAFA',
                display: 'flex',
                gap: 1
              }}>
              {!bankConnected && !error && (
                <ButtonBase
                  disabled={loading}
                  onClick={() => {
                    open()
                  }}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    gap={1}
                    sx={{ color: 'rgba(25, 110, 130, 1)' }}>
                    {loading && (
                      <CircularProgress
                        size="10px"
                        color="inherit"
                      />
                    )}
                    <Typography
                      fontSize={fontSizes['13']}
                      color="inherit">
                      Connect bank
                    </Typography>
                  </Stack>
                </ButtonBase>
              )}
              {bankConnected && !error && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    fontSize: fontSizes['12'],
                    color: '#12131A',
                    gap: 1
                  }}>
                  <Check sx={{ color: '#000', fontSize: fontSizes['16'] }} />
                  <Typography
                    color="#000"
                    fontSize={fontSizes['13']}>
                    Bank Account connected.{' '}
                    <Link
                      to={`/${bookkeepingUrl}/add-bank`}
                      style={{ color: '#196E82', textDecoration: 'none' }}>
                      See all
                    </Link>
                  </Typography>
                </Box>
              )}

              {error && (
                <Stack
                  direction="row"
                  alignItems="center"
                  gap="5px">
                  <Typography fontSize={fontSizes['13']}>Something went wrong</Typography>
                </Stack>
              )}
              {/* <Link to="/order/based/bank_reconciliation" > */}

              {/* </Link> */}
            </Box>
          }
        />
      </Grid>
      <Grid
        item
        md={3.5}
        sm={12}>
        <IconCard
          icon={<DriveFileMoveIcon sx={{ color: '#000', fontSize: '30px' }} />}
          label={`Last ${pluralize(Dictionary.GL_ENTRIES, 1)}`}
          subLabel={journalEntryLoading ? 'Loading...' : lastJournalEntry ? moment(lastJournalEntry).format('DD MMM YYYY') : 'No pushes yet'}
          sx={{ height: '100%' }}
          labelSx={{ height: 'auto', color: '#000', fontSize: fontSizes['14'] }}
          titleSx={{ fontSize: fontSizes['20'], lineHeight: 'normal' }}
          subLabelSx={{ fontSize: fontSizes['14'] }}
          topRightComp={
            <Link
              to={`/${bookkeepingUrl}/gl-entries`}
              style={{ textDecoration: 'none' }}>
              <Stack
                direction={'row'}
                alignItems="center"
                gap="5px"
                sx={{ color: '#196E82' }}>
                <Typography
                  color="inherit"
                  fontSize={fontSizes['13']}>
                  Bookkeeping data
                </Typography>
                <Box
                  sx={{
                    display: 'flex',
                    color: 'inherit',
                    fontSize: fontSizes['24']
                  }}>
                  <ChevronRightIcon
                    color="inherit"
                    fontSize="inherit"
                  />
                </Box>
              </Stack>
            </Link>
          }
          bottomComp={
            <Box
              sx={{
                mt: '15px',
                px: '20px',
                py: '7px',
                border: '1px solid rgba(238, 238, 238, 0.93)',
                bgcolor: '#FAFAFA',
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}>
              {companiesLoading ? (
                <Skeleton
                  variant="rounded"
                  width="100%"
                  height="25px"
                />
              ) : companyConnected ? (
                <>
                  <Check sx={{ color: '#000', fontSize: fontSizes['16'] }} />
                  <Typography
                    color="#000"
                    fontSize={fontSizes['13']}>
                    Accounting software connected.{' '}
                    <Link
                      to={`/${bookkeepingUrl}/integrations`}
                      style={{ color: '#196E82', textDecoration: 'none' }}>
                      See all
                    </Link>
                  </Typography>
                </>
              ) : (
                <Typography
                  color="#000"
                  fontSize={fontSizes['13']}>
                  No accounting software connected.{' '}
                  <Link
                    to={`/${bookkeepingUrl}/integrations`}
                    style={{ color: '#196E82', textDecoration: 'none' }}>
                    Connect
                  </Link>
                </Typography>
              )}
            </Box>
          }
        />
      </Grid>
    </Grid>
  )
}
