import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import { DataGrid, GridColDef } from '@mui/x-data-grid'
import * as React from 'react'
// import useFilter from 'src/utils/hooks/useFilter'
import { CircularProgress, Stack } from '@mui/material'
import map from 'lodash/map'
import { useNavigate } from 'react-router-dom'
import NoTableDataImg from 'src/assets/images/no-table-data.png'
import EmptyScreen from 'src/components/EmptyScreen/EmptyScreen'
import ExportCSV from 'src/components/ExportCSV'
import { useAuth } from 'src/context/AuthContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { DefaultService } from 'src/services/openApi'
import { Modules } from 'src/utils/config/config'
import { emptyScreenMessages } from 'src/utils/constants/emptyScreen'
import { measurePerformance } from 'src/utils/functions/performance'

interface VirtualBrandProps {
  tab: number
}

const VirtualBrandTable = (props: VirtualBrandProps) => {
  const navigate = useNavigate()
  const { currentUser } = useAuth()
  const [tableData, setTableData] = React.useState([])
  const [tableDataLoading, setTableDataLoading] = React.useState(true)
  const { getFilters, selectFiltersFromContext } = useNewFilterContext()
  const tabNames = ['', '_Missed', '_Cancelled', '_Inaccurate']
  const { orgConfig } = useAuth()

  const columns: GridColDef[] = [
    {
      field: 'bAndM',
      headerName: 'B&M',
      flex: 1,
      hide: !orgConfig?.filterConfig['b_name']
    },
    {
      field: 'vitualBrand',
      headerName: 'Brand',
      flex: 1,
      hide: !orgConfig?.filterConfig['vb_name']
    },
    {
      field: 'totalerrors',
      headerName: 'Total Errors',
      flex: 1
    },
    {
      field: 'lostPotentialSale',
      headerName: 'Lost Potential Sale',
      flex: 1,
      renderCell: (params): React.ReactNode => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              ${params.row.lostPotentialSale}
            </Typography>
          </>
        )
      },
      sortComparator: (v1, v2) => {
        return v1 - v2
      }
    },
    {
      field: 'errorCharges',
      headerName: Modules.ErrorCharges,
      flex: 1,
      renderCell: (params): React.ReactNode => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              ${params.row.errorCharges}
            </Typography>
          </>
        )
      },
      sortComparator: (v1, v2) => {
        return v1 - v2
      }
    },
    {
      field: 'errorRate',
      headerName: 'Error Rate',
      flex: 1,
      renderCell: (params): React.ReactNode => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {params.row.errorRate}%
            </Typography>
            {Number(params.row.errorRate) !== 0 ? (
              params.row.errorRate < 0 ? (
                <TrendingDownIcon sx={{ color: 'green' }} />
              ) : (
                <TrendingUpIcon sx={{ color: 'red' }} />
              )
            ) : null}
          </>
        )
      },
      sortComparator: (v1, v2) => {
        return v1 - v2
      }
    },
    {
      field: 'percentDelta',
      headerName: '% Delta',
      flex: 1,
      renderCell: (params): React.ReactNode => {
        return (
          <>
            <Typography
              variant="body2"
              sx={{ mr: 2 }}>
              {params.row.percentDelta !== 'N/A' ? params.row.percentDelta + '%' : params.row.percentDelta}
            </Typography>
            {params.row.percentDelta !== 'N/A' && Number(params.row.percentDelta) !== 0 ? (
              params.row.percentDelta < 0 ? (
                <TrendingDownIcon sx={{ color: 'green' }} />
              ) : (
                <TrendingUpIcon sx={{ color: 'red' }} />
              )
            ) : null}
          </>
        )
      },
      sortComparator: (v1, v2) => {
        return v1 - v2
      }
    }

    // {
    //   field: 'comments',
    //   headerName: 'Comments',
    //   flex: 1
    // }
  ]

  const headers = columns.filter((el) => el.hide !== true).map(({ field, headerName }) => ({ key: field, label: headerName }))

  React.useEffect(() => {
    ;(async () => {
      setTableDataLoading(true)
      try {
        const responseData: any = await measurePerformance(
          async () => {
            return await DefaultService.runProcedureAndGetDataRunQueryPost(
              'data_visualization_metrics.procedure_error_overview_virtual_brand_',
              getFilters(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', tabNames[props.tab], '', 'start_date', 'end_date'])
            )
          },
          {
            procedure: 'data_visualization_metrics.procedure_error_overview_virtual_brand_',
            email: currentUser.email,
            org: currentUser.org
          }
        )
        if (responseData.status === 200) {
          setTableData(
            map(responseData.data, (item, index) => ({
              id: index + 1,
              bAndM: item.Host_Kitchen,
              vitualBrand: item.Virtual_Brand,
              totalerrors: item.Total_Number_Of_Errors,
              lostPotentialSale: item.Lost_Potential_Sale.toFixed(2),
              errorCharges: item.Error_Charges.toFixed(2),
              errorRate: (item.Error_Rate * 100).toFixed(2),
              percentDelta: item.Percent_Delta !== 'N/A' ? (item.Percent_Delta * 100).toFixed(2) : item.Percent_Delta,
              comments: ``
            }))
          )
          setTableDataLoading(false)
        }
      } catch (error) {
        setTableDataLoading(false)
        console.log(error)
      }
    })()
  }, [props.tab, currentUser, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'start_date', 'end_date'])])

  const sortedTableData = React.useMemo(() => tableData.sort((a, b) => b.totalerrors - a.totalerrors), [tableData])

  const goToUrlWithFilters = (b_name, vb_name) => {
    selectFiltersFromContext('b_name', [b_name])
    selectFiltersFromContext('vb_name', [vb_name])
    return `/errors/overview`
  }

  const NoDataComponent = () => {
    return (
      <Box sx={{ marginTop: '12px', zIndex: '1', position: 'relative' }}>
        <EmptyScreen
          NoDataImg={NoTableDataImg}
          labels={emptyScreenMessages.emptyTable}
        />
      </Box>
    )
  }
  return (
    <Box sx={{ height: 410, width: '95%', m: 'auto' }}>
      {tableDataLoading ? (
        <Box sx={{ p: 3, width: '100%', textAlign: 'center' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack sx={{ textAlign: 'right' }}>
            <ExportCSV
              csvHeaders={headers}
              csvData={sortedTableData}
              fileName={'Brand.csv'}
              showIcon={true}
            />
          </Stack>
          <DataGrid
            rows={sortedTableData}
            onRowClick={(data) => navigate(goToUrlWithFilters(data.row.bAndM, data.row.vitualBrand))}
            columns={columns}
            pageSize={4}
            disableSelectionOnClick
            rowHeight={70}
            components={tableData && tableData.length === 0 && { NoRowsOverlay: NoDataComponent }}
          />
        </>
      )}
    </Box>
  )
}

export default VirtualBrandTable
