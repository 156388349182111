import { PsychologyAlt } from '@mui/icons-material'
import { Box, Skeleton, Stack, SxProps, Tooltip, Typography } from '@mui/material'
import React, { forwardRef } from 'react'

export interface DataCardProps {
  title: string
  amount: string
  subAmount?: string
  tooltip?: string
  outOfAmount?: string
  dense?: boolean
  icon?: JSX.Element
  titleTooltip?: string
  theme?: 'grey'
  sx?: SxProps
  titleSx?: SxProps
  valueSx?: SxProps
  removeTitleHeight?: boolean
  loading?: boolean
  bottomComponent?: React.ReactNode
  adjacentComponent?: React.ReactNode
  rightComponent?: React.ReactNode
}

const DataCard = forwardRef<HTMLDivElement, DataCardProps>((props, ref) => {
  const { backgroundColor, color } = React.useMemo(() => {
    switch (props.theme) {
      case 'grey':
        return {
          backgroundColor: 'rgba(244, 244, 244, 1)',
          color: 'rgba(28, 27, 31, 1)'
        }
      default:
        return {
          backgroundColor: 'rgba(255, 255, 255, 1)',
          color: 'rgba(0, 0, 0, 1)'
        }
    }
  }, [props.theme])

  return (
    <Box
      sx={{
        px: props.dense ? '13px' : '18px',
        py: props.dense ? '9px' : '18px',
        border: '1px solid #EEEEEE',
        borderRadius: '2px',
        backgroundColor,
        position: 'relative',
        ...props.sx
      }}>
      <Box>
        {(props.icon || props.tooltip) && (
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            gap="5px"
            sx={{ height: '24px', mb: '10px' }}>
            {props.icon && <Box sx={{ display: 'flex', fontSize: '24px', color: color }}>{props.icon}</Box>}
            {props.tooltip && (
              <Tooltip
                title={props.tooltip}
                arrow
                placement="top"
                componentsProps={{
                  tooltip: {
                    style: {
                      color: 'white',
                      fontSize: 12,
                      fontWeight: 500,
                      background: 'black',
                      maxWidth: 600,
                      padding: '10px'
                    }
                  },
                  arrow: {
                    style: {
                      color: 'black'
                    }
                  }
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    fontSize: '18px',
                    color: 'rgba(18, 41, 55, 0.32)',
                    p: '3px',
                    borderRadius: '2px',
                    bgcolor: '#E8E8E8'
                  }}>
                  <PsychologyAlt
                    fontSize="inherit"
                    color="inherit"
                  />
                </Box>
              </Tooltip>
            )}
          </Stack>
        )}
        <Box
          display="flex"
          alignItems="center"
          gap={2}>
          <Box ref={ref}>
            <Box
              sx={{
                mb: '6px',
                minHeight: props.removeTitleHeight ? 'none' : '32px',
                display: 'flex',
                alignItems: 'center',
                gap: 1
              }}>
              <Typography
                fontSize="12px"
                fontWeight={400}
                lineHeight={'16px'}
                sx={{ color: color, ...props.titleSx }}>
                {props.title}
              </Typography>

              {props.titleTooltip && (
                <Tooltip
                  title={props.titleTooltip}
                  arrow
                  placement="top"
                  componentsProps={{
                    tooltip: {
                      style: {
                        color: 'white',
                        fontSize: 12,
                        fontWeight: 500,
                        background: 'black',
                        maxWidth: 600,
                        padding: '10px'
                      }
                    },
                    arrow: {
                      style: {
                        color: 'black'
                      }
                    }
                  }}>
                  <Box
                    sx={{
                      display: 'flex',
                      fontSize: '18px',
                      color: 'rgba(18, 41, 55, 0.32)',
                      p: '3px',
                      borderRadius: '2px',
                      bgcolor: '#E8E8E8'
                    }}>
                    <PsychologyAlt
                      fontSize="inherit"
                      color="inherit"
                    />
                  </Box>
                </Tooltip>
              )}
            </Box>

            {props.loading ? (
              <Skeleton
                variant="rounded"
                width="80%"
                height="30px"
                sx={{ ...props.valueSx }}
              />
            ) : (
              <Box
                display="flex"
                gap={2}>
                <Typography
                  fontSize={props.dense ? '12px' : '20px'}
                  fontWeight={600}
                  color={color}
                  sx={{ ...props.valueSx }}>
                  {props.amount}{' '}
                  {props.outOfAmount && (
                    <span style={{ fontSize: '12px', fontWeight: 400, marginLeft: '8px', color: 'rgba(28, 27, 31, 0.5)' }}>out of {props.outOfAmount}</span>
                  )}
                  {props.subAmount && <span style={{ fontSize: '12px', fontWeight: 400, color: 'rgba(28, 27, 31, 0.5)' }}>{props.subAmount}</span>}
                </Typography>

                {props.adjacentComponent}
              </Box>
            )}
          </Box>

          {props.rightComponent}
        </Box>

        {props.bottomComponent}
      </Box>
    </Box>
  )
})

export default DataCard
