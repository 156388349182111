import { Box, Divider, Skeleton, Typography } from '@mui/material'
import moment from 'moment'
import { Fragment, useMemo } from 'react'
import { numberAbbreviation } from 'src/utils/functions'

export interface TooltipLabelType {
  label: string
  shortLabel?: string
  format: 'currency' | 'percentage' | null
  loading: boolean
}

export type TooltipLabelsType = { [key: string]: TooltipLabelType }

interface ChartTooltipProps {
  active?: boolean
  payload?: any
  tooltipLabels: TooltipLabelsType
  aggregateData: any
  visibleTooltip: string
}

const ChartTooltip = ({ active, payload, tooltipLabels, aggregateData, visibleTooltip }: ChartTooltipProps) => {
  if (!active || !payload || payload.length === 0) return null

  const tooltipData: any | null = aggregateData?.find((data) => data.date === payload[0]?.payload?.date) || null

  if (!tooltipData) return null

  const { primarySection, secondarySection } = useMemo(() => {
    if (!visibleTooltip || !tooltipLabels[visibleTooltip]) return null

    const label = tooltipLabels[visibleTooltip].label
    const format = tooltipLabels[visibleTooltip].format ? tooltipLabels[visibleTooltip].format : 'default'
    const value = tooltipData[visibleTooltip]

    const primary = {
      label: label,
      value: value ? numberAbbreviation[format](value) : 0,
      loading: tooltipLabels[visibleTooltip].loading
    }

    const tooltipDataKeys = Object.keys(tooltipData).filter((key) => key !== 'date' && key !== visibleTooltip)

    const secondary = tooltipDataKeys?.map((key) => {
      if (!tooltipLabels[key]) return null

      const label = tooltipLabels[key].shortLabel || tooltipLabels[key].label
      const format = tooltipLabels[key].format || 'default'
      const value = tooltipData[key]

      return {
        label: label,
        value: value ? numberAbbreviation[format](value) : 0,
        loading: tooltipLabels[key].loading
      }
    })

    return { primarySection: primary, secondarySection: secondary }
  }, [visibleTooltip, tooltipData])

  return (
    <Box
      bgcolor="#122937EE"
      p={2}
      position={'relative'}
      zIndex={1000}
      borderRadius="4px"
      width={500}>
      <Box
        display="flex"
        flexDirection="column"
        gap={1}>
        <Typography
          fontSize={12}
          color="#EFEFEF80">
          Performance on {moment(tooltipData.date).format('MMM Do')}
        </Typography>

        {!primarySection?.loading ? (
          <Box>
            <Typography
              fontSize={12}
              color="#FFF"
              noWrap>
              {primarySection.label}
            </Typography>

            <Typography
              fontSize={16}
              color="#FFF"
              fontWeight={600}
              noWrap>
              {primarySection.value}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Skeleton
              variant="rounded"
              sx={{ width: '50%', height: '19px', bgcolor: '#FFF', mt: '2px' }}
            />
            <Skeleton
              variant="rounded"
              sx={{ width: '25%', height: '19px', bgcolor: '#FFF', mt: '2px' }}
            />
          </Box>
        )}
      </Box>

      <Divider sx={{ my: 2, borderColor: '#FFFFFF30' }} />

      <Box
        display="flex"
        flexDirection="column"
        gap={1}>
        <Typography
          fontSize={12}
          color="#EFEFEF80">
          Other Metrics
        </Typography>

        <Box
          display="flex"
          alignItems="center"
          gap={1}>
          {secondarySection?.map((section, index) => (
            <Fragment key={index + section.label}>
              <Box>
                {!section?.loading ? (
                  <Box>
                    <Typography
                      fontSize={12}
                      color="#FFF"
                      noWrap>
                      {section.label}
                    </Typography>

                    <Typography
                      fontSize={16}
                      color="#FFF"
                      noWrap>
                      {section.value}
                    </Typography>
                  </Box>
                ) : (
                  <Box>
                    <Skeleton
                      variant="rounded"
                      sx={{ width: '70px', height: '19px', bgcolor: '#FFF', mt: '2px' }}
                    />
                    <Skeleton
                      variant="rounded"
                      sx={{ width: '60px', height: '19px', bgcolor: '#FFF', mt: '2px' }}
                    />
                  </Box>
                )}
              </Box>

              {index !== secondarySection.length - 1 && (
                <Box
                  width="1px"
                  height="30px"
                  bgcolor="#FFF8"
                />
              )}
            </Fragment>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default ChartTooltip
