import BrowserUpdatedIcon from '@mui/icons-material/BrowserUpdated'
import TonalityIcon from '@mui/icons-material/Tonality'
import IconButton from '@mui/material/IconButton'
import { memo, useEffect, useMemo, useState } from 'react'

import { CustomMaterialTable, TypoContainer } from '@LoopKitchen/loop-ui'
import { ChevronRight, Close, CloseFullscreen, Info, OpenInFull, Warning } from '@mui/icons-material'
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Paper, Skeleton, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import get from 'lodash/get'
import isNaN from 'lodash/isNaN'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import PlatformLogo from 'src/components/PlatformLogo'
import { CustomTypoContainer } from 'src/components/StyledTypo'
import { useAuth } from 'src/context/AuthContext'
import { useErrorData } from 'src/context/ErrorContext'
import { useFilter } from 'src/context/FilterContext'
import { DefaultService } from 'src/services/openApi'
import { filterNames } from 'src/utils/config/config'

export const refreshExpandedWidth = 120
export const refreshCollapsedWidth = 45

interface RefreshProps {
  setRefreshExpanded: React.Dispatch<React.SetStateAction<boolean>>
  closeDrawer?: () => void
}
const RefreshStatus = memo(({ setRefreshExpanded, closeDrawer }: RefreshProps) => {
  const { currentUser, posthogCapture } = useAuth()
  const { handleError } = useErrorData()
  const { getAllPossibleFilters, lastDate, getFilters, getFiltersV2 } = useFilter()
  const navigate = useNavigate()
  const [totalFailedStores, setTotalFailedStores] = useState(0)
  const [tempFailedStores, setTempFailedStores] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [expanded, setExpanded] = useState(false)
  const [showPopUp, setShowPopUp] = useState(false)
  const [storeDivisions, setStoreDivisions] = useState([])
  const [failedResult, setFailedResult] = useState([])

  const showRefreshDateNotification = useMemo(() => {
    const selectedBNames = getFiltersV2(['b_name'])?.b_name_in?.split('|')
    const allBNames = getAllPossibleFilters(['b_name'])?.b_name

    return selectedBNames.length !== 0 && selectedBNames[0] !== '' && selectedBNames.length !== allBNames.length
  }, [getFiltersV2(['b_name'])?.b_name_in, getAllPossibleFilters(['b_name'])?.b_name?.join('|')])

  useEffect(() => {
    async function getData() {
      setIsLoading(true)
      try {
        let result = await getFailedStores()
        const host_kitchens = get(currentUser, 'host_kitchens', []).map((item) => item.label)
        result = host_kitchens.length > 0 ? result.filter((item) => host_kitchens.includes(item.b_name)) : result
        const tempFailedResult = result.filter((item) => isNaN(Date.parse(item.lastDate)))
        setTotalFailedStores(result.length)
        setTempFailedStores(result.length - tempFailedResult.length)
      } catch (error) {
        handleError(error.message)
      }
      setIsLoading(false)
    }
    if (currentUser) getData()
  }, [currentUser, ...getFilters(['b_name'])])

  async function getFailedStores() {
    try {
      const response = await DefaultService.getBusinessRefreshVbNameLevelBusinessRefreshBrandPost({
        chain_in: currentUser.org,
        b_name_in: getFiltersV2(['b_name']).b_name_in
      })

      return response
    } catch (error) {
      throw error
    }
  }

  const handleClick = async () => {
    try {
      posthogCapture('data_freshness_clicked')
      setShowPopUp(true)
      console.log('clicked')

      let result = await getFailedStores()
      const host_kitchens = get(currentUser, 'host_kitchens', []).map((item) => item.label)
      result = host_kitchens.length > 0 ? result.filter((item) => host_kitchens.includes(item.b_name)) : result
      const tempFailedResult = result.filter((item) => isNaN(Date.parse(item.lastDate)))
      setFailedResult(tempFailedResult)
      setTotalFailedStores(result.length)

      const res = Object.values(
        tempFailedResult.reduce((a, curr) => {
          const { lastDate } = curr
          const key = `${lastDate}`
          ;(a[key] = a[key] || []).push(curr)
          return a
        }, {})
      )
      const finalResult = []
      res.forEach((obj) => {
        const lastDate = obj[0]['lastDate']
        if (lastDate !== 'NaT') {
          const noOfStores = Object.keys(obj).length
          const details = {
            lastDate: lastDate,
            stores: noOfStores
          }
          finalResult.push(details)
        }
      })
      setStoreDivisions(finalResult)
    } catch (error) {
      handleError(error)
    }
  }

  const toggleExpand = () => {
    setExpanded(!expanded)
    setRefreshExpanded(!expanded)
  }

  return (
    <>
      <Dialog
        open={showPopUp}
        onClose={() => setShowPopUp(false)}
        sx={{
          '& .MuiDialog-container': {
            '& .MuiPaper-root': {
              width: '100%',
              maxWidth: '1100px'
            }
          }
        }}>
        <Box
          display="flex"
          justifyContent="space-between"
          sx={{ height: '11rem' }}>
          <Box sx={{ display: 'flex', alignItems: 'end' }}>
            <DialogTitle
              sx={{ padding: '0px', marginLeft: '45px' }}
              id="alert-dialog-title">
              <Typography
                align="inherit"
                sx={{ fontSize: '24px', fontWeight: '600', color: '#122937' }}>
                Unrefreshed {filterNames.slug}
              </Typography>
              <Typography
                sx={{
                  fontSize: '14px',
                  fontWeight: '400',
                  color: 'rgba(0, 0, 0, 0.5)'
                }}>
                Last refresh: {moment(lastDate).format('DD MMM, H:HH, YYYY')}
              </Typography>
            </DialogTitle>
          </Box>
          <TypoContainer
            sx={{
              pr: 2,
              display: 'flex',
              justifyContent: 'flex-start',
              mt: 2
            }}>
            <IconButton
              onClick={() => setShowPopUp(false)}
              sx={{ width: 35, height: 35 }}>
              <Close sx={{ fontSize: '24px' }} />
            </IconButton>
          </TypoContainer>
        </Box>

        <DialogContent>
          <Typography
            sx={{
              width: 'max-content',
              fontSize: '14px',
              fontWeight: '600',
              marginLeft: '23px'
            }}>
            {filterNames.vb_name}s Refreshed
          </Typography>
          <Grid
            container
            spacing={2}
            sx={{ my: 2 }}>
            {storeDivisions.map((item, index) => {
              return (
                <Grid
                  key={index}
                  item
                  xs={6}
                  md={4}
                  sx={{ textAlign: 'center' }}>
                  <Box
                    sx={{ p: 3, textAlign: 'center' }}
                    component={Paper}
                    elevation={1}>
                    <Box
                      display="flex"
                      textAlign="center"
                      justifyContent="center">
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '32px',
                          fontWeight: '400',
                          color: 'rgba(108, 171, 186, 0.5)'
                        }}>
                        {item.stores}
                      </Typography>
                      <Typography
                        sx={{
                          fontSize: '18px',
                          fontWeight: '400',
                          color: 'rgba(0, 0, 0, 0.5)',
                          display: 'flex',
                          alignItems: 'center',
                          margin: '0px 5px'
                        }}>
                        /
                      </Typography>
                      <Typography
                        sx={{
                          display: 'flex',
                          alignItems: 'center',
                          fontSize: '32px',
                          fontWeight: '600',
                          color: 'rgba(108, 171, 186, 1)'
                        }}>
                        {totalFailedStores}
                      </Typography>
                    </Box>
                    <Typography
                      sx={{
                        fontSize: '14px',
                        fontWeight: '400',
                        color: 'rgba(0, 0, 0, 0.5)'
                      }}>
                      stores have data until
                    </Typography>
                    <Typography sx={{ fontSize: '14px', fontWeight: '400' }}>{item.lastDate}</Typography>
                  </Box>
                </Grid>
              )
            })}
          </Grid>
          <Box>
            <Typography
              sx={{
                width: 'max-content',
                fontSize: '14px',
                fontWeight: '600',
                color: '#F15412',
                marginLeft: '23px'
              }}>
              {failedResult.length} stores with older data
            </Typography>
          </Box>
          <CustomMaterialTable
            data={failedResult}
            columns={[
              {
                title: filterNames.vb_name,
                field: 'vb_name',
                render: (params) => {
                  return (
                    <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                      <Warning
                        sx={{ marginRight: '5px' }}
                        color="error"
                      />
                      {params.vb_name}
                    </Typography>
                  )
                }
              },
              {
                title: filterNames.b_name,
                field: 'b_name',
                textStyle: { color: 'rgba(0, 0, 0, 0.5)' }
              },
              {
                title: filterNames.vb_platform,
                field: 'vb_platform',
                cellStyle: { width: '20%' },
                render: (params) => {
                  return (
                    <PlatformLogo
                      platformName={params.vb_platform}
                      height="15px"
                      width="auto"
                    />
                  )
                }
              }
            ]}
            options={{
              stickyHeader: '-24px',
              pagination: true
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setShowPopUp(false)}
            autoFocus>
            GOT IT
          </Button>
        </DialogActions>
      </Dialog>

      <Box>
        <Box position="relative">
          <Box
            sx={{
              m: '8px',
              px: 2,
              transition: '0.2s ease',
              backgroundColor: '#091F2D',
              color: '#FFFFFF',
              cursor: expanded ? 'default' : 'pointer',
              borderRadius: '5px',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              height: expanded ? refreshExpandedWidth : refreshCollapsedWidth
            }}
            onClick={() => {
              if (expanded) return
              navigate('/data-refresh-status')
              closeDrawer()
            }}>
            {expanded ? (
              <>
                <Stack
                  direction="row"
                  whiteSpace="pre-line"
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'stretch',
                    marginBottom: 2,
                    whiteSpace: 'pre-line'
                  }}>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    width="100%">
                    <CustomTypoContainer
                      c="white"
                      fs={12}
                      fw={400}>
                      Data Freshness
                    </CustomTypoContainer>
                  </Box>
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ alignItems: 'center', marginBottom: '10px' }}>
                  <BrowserUpdatedIcon sx={{ color: 'grey', whiteSpace: 'initial' }} />
                  {isLoading ? (
                    <Skeleton
                      variant="rounded"
                      sx={{ height: '15px', width: '100%', bgcolor: '#fff2' }}
                    />
                  ) : (
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        cursor: 'pointer',
                        '&:hover .MuiTypography-root': {
                          fontWeight: 700
                        },
                        '&:hover .MuiSvgIcon-root': {
                          marginLeft: '3px'
                        }
                      }}
                      onClick={() => handleClick()}>
                      <Typography
                        variant="subtitle1"
                        color="#FFF"
                        sx={{ fontSize: 10, fontWeight: 400, lineHeight: 'initial', transition: '0.2s ease' }}>
                        {`${tempFailedStores}/${totalFailedStores}`} stores updated
                      </Typography>
                      <ChevronRight sx={{ color: '#fff', fontSize: '18px', transition: '0.2s ease' }} />
                    </Stack>
                  )}
                </Stack>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ alignItems: 'center' }}>
                  <TonalityIcon sx={{ color: '#0F783C' }} />
                  {isLoading ? (
                    <Skeleton
                      variant="rounded"
                      sx={{ height: '15px', width: '100%', bgcolor: '#fff2' }}
                    />
                  ) : (
                    <Stack
                      direction="row"
                      alignItems="center"
                      sx={{
                        cursor: 'pointer',
                        '&:hover .MuiTypography-root': {
                          fontWeight: 700
                        },
                        '&:hover .MuiSvgIcon-root': {
                          marginLeft: '3px'
                        }
                      }}
                      onClick={() => {
                        navigate('/data-refresh-status')
                        closeDrawer()
                      }}>
                      <Typography
                        variant="subtitle1"
                        color="#FFFFFF"
                        sx={{ fontSize: 10, fontWeight: 400, lineHeight: 'initial', transition: '0.2s ease' }}>
                        Data Until : {lastDate ? moment(lastDate).format('D MMM, YYYY') : 'No Data'}
                      </Typography>

                      <ChevronRight sx={{ color: '#fff', fontSize: '18px', transition: '0.2s ease' }} />
                    </Stack>
                  )}
                </Stack>
              </>
            ) : (
              <>
                <Stack
                  direction="row"
                  spacing={1}
                  sx={{ alignItems: 'center' }}>
                  {isLoading ? (
                    <Skeleton
                      variant="rounded"
                      sx={{ height: '14px', width: 'calc(100% - 30px)', bgcolor: '#fff2' }}
                    />
                  ) : (
                    <Typography
                      variant="subtitle1"
                      color="#FFFFFF"
                      sx={{ fontSize: 11, fontWeight: 400, lineHeight: 'initial' }}>
                      Data Until : {lastDate ? moment(lastDate).format('D MMM, YYYY') : 'No Data'}
                    </Typography>
                  )}
                </Stack>
              </>
            )}
          </Box>
          <IconButton
            onClick={toggleExpand}
            sx={{
              borderRadius: '4px',
              position: 'absolute',
              top: '6.5px',
              right: '15px',
              color: '#fffb',
              fontSize: '20px',
              p: '6px',
              bgcolor: 'rgba(255, 255, 255, 0.1)',
              '&:hover': {
                bgcolor: 'rgba(255, 255, 255, 0.2)'
              }
            }}>
            {expanded ? <CloseFullscreen sx={{ color: 'inherit', fontSize: 'inherit' }} /> : <OpenInFull sx={{ color: 'inherit', fontSize: 'inherit' }} />}
          </IconButton>
        </Box>

        {showRefreshDateNotification && (
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            px={2}
            mb={1}>
            <Info
              fontSize="small"
              sx={{ color: '#FFFFFF80' }}
            />
            <Typography
              color="#FFFFFF80"
              fontSize={11}
              whiteSpace="normal">
              Showing data freshness for selected {filterNames.b_name}s
            </Typography>
          </Box>
        )}
      </Box>
    </>
  )
})
export default RefreshStatus
