import ChargebackOverviewComp from 'src/pages/members/Chargebacks/components/ChargebackOverviewComp'
import ErrorOverviewComp from 'src/pages/members/ErrorOverview/ErrorOverview'
import Finance from 'src/pages/members/Finance/Finance'
import MarketingBodyComp from 'src/pages/members/MarketingNew/MarketingBodyComp'
import GenerateAndAutomate from 'src/pages/members/RatingAndReviewsPages/Reviews/components/GenerateAndAutomate'
import SalesOverview from 'src/pages/members/SalesOverview/SalesOverview'

export type ComponentType = <T>(props: T) => JSX.Element

export type ComponentsObjectValueType = {
  Component: ComponentType
  props?: Record<string, any>
}

const componentsObject = {
  review_and_reply: { Component: GenerateAndAutomate } as ComponentsObjectValueType,
  transactions: { Component: Finance } as ComponentsObjectValueType,
  chargebacks: { Component: ChargebackOverviewComp } as ComponentsObjectValueType,
  sales: { Component: SalesOverview } as ComponentsObjectValueType,
  lost_sales: { Component: Finance } as ComponentsObjectValueType,
  marketing: { Component: MarketingBodyComp } as ComponentsObjectValueType,
  error_overview: { Component: ErrorOverviewComp } as ComponentsObjectValueType
}

export default componentsObject
