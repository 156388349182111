import { DateRangePicker } from '@LoopKitchen/loop-ui'
import moment, { Moment } from 'moment'
import React, { useEffect } from 'react'
import 'react-daterange-picker/dist/css/react-calendar.css'
import { useAuth } from 'src/context/AuthContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import useCustomStartDate from 'src/utils/hooks/useCustomStartDate'
import { updateDateRangeFromLastDate } from '../utils/filterUtils'
import { SelectWrapperRef } from './SelectWrapper'

interface DateRangePickerCompProps {
  maxDate: Moment | null
  fullWidthFilters?: boolean
}

const DateRangePickerComp = React.forwardRef<SelectWrapperRef, DateRangePickerCompProps>((props: DateRangePickerCompProps, ref) => {
  const { maxDate, fullWidthFilters = false } = props
  const { dateRange, activeGraph, reRenderFilterContext } = useNewFilterContext()
  const { currentUser } = useAuth()

  useEffect(() => {
    updateDateRangeFromLastDate(activeGraph, maxDate)
  }, [maxDate?.format('YYYY-MM-DD'), activeGraph])

  return (
    <>
      <DateRangePickerWrapper
        minDate={shouldBlockForUser(currentUser) ? moment('2023-10-15') : null}
        maxDate={shouldBlockForUser(currentUser) ? moment('2024-01-15') : maxDate}
        value={dateRange}
        onChange={(newDateRange) => {
          activeGraph.setDateRange(newDateRange)
          reRenderFilterContext()
        }}
        disableScrollLock
        rootButtonSx={{
          width: fullWidthFilters ? '100%' : 'auto',
          py: fullWidthFilters ? 1.5 : '12px',
          px: '8px',
          justifyContent: fullWidthFilters ? 'flex-start' : 'center',
          '& p': {
            lineHeight: '1.5 !important'
          },
          '& .MuiButton-endIcon': {
            fontSize: '12px',
            height: '12px',
            display: 'flex',
            alignItems: 'center',
            paddingRight: 0.5,
            flexGrow: fullWidthFilters ? 1 : 0,
            justifyContent: fullWidthFilters ? 'flex-end' : 'normal'
          }
        }}
        showLastWeekShortcut
        showMonthSelect
      />
    </>
  )
})

export default DateRangePickerComp

export function DateRangePickerWrapper(props: any) {
  const { lastDate } = useNewFilterContext()
  const { customStartDate, customEndDate } = useCustomStartDate()

  useEffect(() => {
    if (customStartDate && customEndDate) {
      if (props.value.start.isBefore(customStartDate) || props.value.end.isAfter(customEndDate)) {
        const newDateRange = props.value
        newDateRange.start = newDateRange.start.isBetween(customStartDate, customEndDate) ? newDateRange.start : customStartDate
        newDateRange.end = newDateRange.end.isBetween(customStartDate, customEndDate) ? newDateRange.end : customEndDate
        if (newDateRange.start.isAfter(newDateRange.end)) {
          const endDate = newDateRange.end.clone()
          newDateRange.end = newDateRange.start.clone()
          newDateRange.start = endDate
        }
        if (typeof props.onChange === 'function') {
          props.onChange({ ...newDateRange })
        }
      }
    } else if (customStartDate && props.value.start.isBefore(customStartDate)) {
      const newDateRange = props.value
      newDateRange.start = customStartDate
      if (props.value.end.isBefore(customStartDate)) {
        newDateRange.end = moment.min([moment(customStartDate).add(30, 'days'), lastDate])
      }
      if (typeof props.onChange === 'function') {
        props.onChange({ ...newDateRange })
      }
    } else if (customEndDate && props.value.end.isAfter(customEndDate)) {
      const newDateRange = props.value
      newDateRange.end = customEndDate
      if (props.value.start.isAfter(customEndDate)) {
        newDateRange.start = moment(customEndDate).subtract(30, 'days')
      }
      if (typeof props.onChange === 'function') {
        props.onChange({ ...newDateRange })
      }
    }
  }, [props.value, customStartDate, customEndDate])

  return (
    <DateRangePicker
      {...props}
      maxDate={customEndDate || props.maxDate}
      minDate={customStartDate || props.minDate}
      onChange={(newDateRange) => {
        if (customStartDate && newDateRange.start.isBefore(customStartDate)) {
          newDateRange.start = customStartDate
        }
        if (customEndDate && newDateRange.end.isAfter(customEndDate)) {
          newDateRange.end = customEndDate
        }

        if (typeof props.onChange === 'function') {
          props.onChange({ ...newDateRange })
        }
      }}
    />
  )
}
