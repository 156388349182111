import React, { createContext, useContext, useMemo, useState } from 'react'
interface LoadingContextInterface {
  backdropLoading: LoaderTypes
  setBackdropLoading: React.Dispatch<React.SetStateAction<LoaderTypes>>
}

type LoaderTypes = boolean

const initialState = {} as LoadingContextInterface

const LoadingContext = createContext<LoadingContextInterface>(initialState)

export const useLoadingData = () => useContext(LoadingContext)

const LoadingContextProvider = ({ children }: { children: React.ReactNode }) => {
  const [backdropLoading, setBackdropLoading] = useState<LoaderTypes>(false)

  const contextValue = useMemo(() => ({ backdropLoading, setBackdropLoading }), [backdropLoading, setBackdropLoading])

  return <LoadingContext.Provider value={contextValue}>{children}</LoadingContext.Provider>
}

export default LoadingContextProvider
