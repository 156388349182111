import Lottie from 'lottie-react'
import React from 'react'
import loopLoaderWhite from 'src/assets/loader/loop-loader-white.json'
import loopLoader from 'src/assets/loader/loop-loader.json'

interface LottieLoaderProps {
  white?: boolean
  style?: React.CSSProperties
}

const LottieLoader = ({ white, style }: LottieLoaderProps) => {
  return (
    <Lottie
      animationData={white ? loopLoaderWhite : loopLoader}
      loop={true}
      autoplay={true}
      style={{ width: '200px', ...style }}
    />
  )
}

export default LottieLoader
