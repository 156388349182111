import { AttachMoney } from '@mui/icons-material'
import { Box, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import Page from 'src/components/mui/Page'
import ChannelSelect from 'src/components/Select/ChannelSelect'
import FulfillmentSelect from 'src/components/Select/FulfillmentSelect'
import CardLayout from '../../components/CardLayout'
import { usePayoutsBreakdownDataContext } from '../../utils/context/PayoutsBreakdownDataContext'
import TransactionsBreakdownDataContextProvider from '../../utils/context/TransactionsBreakdownDataContext'
import TransactionsChartView from './Tabs/TransactionsChartView'

export default function Transactions() {
  const { fontSizes } = usePayoutsBreakdownDataContext()
  const [channel, setChannel] = useState<string[]>([])
  const [fulfillment, setFulfillment] = useState<string[]>([])

  return (
    <Page title="Income Statement">
      <Box sx={{ px: 3, py: 4, bgcolor: 'white' }}>
        <Box
          display="flex"
          alignItems="center"
          gap="5px">
          <Box sx={{ color: 'black' }}>
            <AttachMoney
              color="inherit"
              sx={{ fontSize: fontSizes['24'] }}
            />
          </Box>
          <Box>
            <Typography
              color="rgba(28, 27, 31, 1)"
              fontSize={fontSizes['16']}
              fontWeight={600}>
              Overview
            </Typography>
          </Box>
        </Box>

        <Box sx={{ mt: '15px' }}>
          <CardLayout />
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          gap="10px"
          sx={{ mt: '30px' }}>
          <ChannelSelect
            value={channel}
            onChange={setChannel}
          />
          <FulfillmentSelect
            value={fulfillment}
            onChange={setFulfillment}
          />
        </Stack>

        <Box sx={{ mt: '15px' }}>
          <TransactionsBreakdownDataContextProvider
            channel={channel}
            fulfillment={fulfillment}>
            <TransactionsChartView />
          </TransactionsBreakdownDataContextProvider>
        </Box>
      </Box>
    </Page>
  )
}
