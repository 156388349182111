import { Box, Typography } from '@mui/material'
import moment from 'moment'
import { numberAbbreviation } from 'src/utils/functions'

interface ChartMarkersProps {
  index: number
  x: number
  y: number
  xValue: number
  yValue: string
  yAxisType: 'percentage' | 'currency' | null
}
const ChartMarkers = ({ index, x, y, xValue, yValue, yAxisType }: ChartMarkersProps) => {
  if (!x || !y) return null

  const formattedXValue = numberAbbreviation[yAxisType ? yAxisType : 'default'](xValue)
  const formattedYValue = moment(yValue).format('MMM DD')

  return (
    <>
      <Box
        bgcolor="#257280"
        py={0.5}
        width="70px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        zIndex={1}
        top={y - 12}
        left={index <= 2 ? '0px' : '35px'}>
        <Typography
          fontSize={12}
          color="#fff">
          {formattedXValue}
        </Typography>
      </Box>
      <Box
        bgcolor="#122937"
        py={0.5}
        width="60px"
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="absolute"
        zIndex={1}
        top={288}
        left={x - 30}>
        <Typography
          fontSize={12}
          color="#fff">
          {formattedYValue}
        </Typography>
      </Box>
    </>
  )
}

export default ChartMarkers
