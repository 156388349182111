import Box from '@mui/material/Box'
import LottieLoader from './LottieLoader'

const Loading = () => (
  <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100vh', width: '100%' }}>
    <LottieLoader />
  </Box>
)

export default Loading
