import { Box, Button, Dialog, DialogContent, Typography } from '@mui/material'
import React from 'react'
import TableChip from 'src/components/TableChip'
import { ChargebackOrderRow } from 'src/services/openApi'
import { Modules } from 'src/utils/config/config'
import { getShortOrderId } from 'src/utils/functions'
import { ItemLevelType, ItemType } from '../Tabs/ErrorsRevenueRecapture'
import ItemView from './ItemView'

export type SelectedItemtype = {
  itemID: number | string
  reason?: string
  description?: string
}

interface ItemLevelRaiseDisputeDialogProps {
  data: ChargebackOrderRow | null
  onClose: () => void
  onRaise: (items: ItemLevelType[]) => void
}

export default function ItemLevelRaiseDisputeDialog(props: ItemLevelRaiseDisputeDialogProps) {
  const [selectedItem, setSelectedItem] = React.useState<SelectedItemtype[]>([])

  const variant = props.data?.num_days_to_dispute > 10 ? 'primary' : props.data?.num_days_to_dispute >= 5 ? 'warning' : 'error'
  const data: ItemType[] = (props.data?.items || []) as any

  const raiseDispute = () => {
    const arr: ItemLevelType[] = selectedItem.map((e) => {
      const itemData = data.find((f) => f.id === e.itemID)
      const obj: ItemLevelType = {
        ...itemData,
        reason: e.reason
      }
      if ('description' in e) {
        obj['description'] = e.description
      }
      return obj
    })
    props.onRaise(arr)
    // ActionService.raiseDisputeDisputeOrderPut(props.data.order_id);
    // ActionService.raiseDisputeDisputeOrderPut()
    // let x: DisputeData;
  }

  React.useEffect(() => {
    if (props.data === null) {
      setSelectedItem([])
    }
  }, [props.data])

  return (
    <>
      <Dialog
        open={props.data !== null}
        fullWidth
        maxWidth="md"
        onClose={() => {
          props.onClose()
        }}>
        {props.data !== null && (
          <DialogContent>
            <Typography
              variant="h3"
              sx={{ mb: 1 }}>
              {Modules.ErrorCharges} - Order #{getShortOrderId(props.data.vb_platform, props.data.order_id)}
            </Typography>
            <Typography
              variant="subtitle2"
              sx={{ mb: 2 }}>
              You have{' '}
              <TableChip
                label={`${props.data.num_days_to_dispute} days`}
                variant={variant}
                size={'small'}
              />{' '}
              left to dispute the {Modules.ErrorCharges.toLowerCase()} for this order
            </Typography>

            <Box>
              {data.map((item, index) => {
                return (
                  <ItemView
                    key={index}
                    data={item}
                    platform={props.data.vb_platform}
                    item_level_error={props.data.item_level_error}
                    onChange={(param) => {
                      setSelectedItem((p) => {
                        const arr: typeof p = JSON.parse(JSON.stringify(p))
                        const index = arr.findIndex((e) => e.itemID === param.obj.itemID)
                        if (index >= 0 && param.type === 'unchecked') {
                          return arr.slice(0, index).concat(arr.slice(index + 1))
                        } else if (index < 0 && param.type === 'checked') {
                          return [...arr, param.obj]
                        } else if (index >= 0 && param.type === 'checked') {
                          arr[index] = param.obj
                          return arr
                        }
                        return p
                      })
                    }}
                  />
                )
              })}
            </Box>

            <Box
              display="flex"
              flexDirection={'row'}
              justifyContent="flex-end"
              gap={2}>
              <Button
                variant="text"
                onClick={() => {
                  props.onClose()
                }}>
                Cancel
              </Button>
              <Button
                variant="contained"
                disabled={selectedItem.length === 0 || selectedItem.length !== selectedItem.filter((e) => 'reason' in e).length}
                onClick={() => {
                  raiseDispute()
                }}>
                SUBMIT
              </Button>
            </Box>
          </DialogContent>
        )}
      </Dialog>
    </>
  )
}
