import { Box } from '@mui/material'
import CampaignTable from './CampaignTable'

export default function CampaignMetricsComp() {
  // const [showRecentlyAdded, setShowRecentlyAdded] = React.useState(false)

  return (
    <>
      {/* <CampaignOverview
      // showRecentlyAdded={showRecentlyAdded}
      // setShowRecentlyAdded={setShowRecentlyAdded}
      /> */}

      <Box sx={{ mt: '26px' }}>
        <CampaignTable
        // showRecentlyAdded={showRecentlyAdded}
        // setShowRecentlyAdded={setShowRecentlyAdded}
        />
      </Box>
    </>
  )
}
