import React from 'react'

interface SyncedChartsContextInterface<T> {
  overview: {
    visibleTooltip: string
    setVisibleTooltip: React.Dispatch<React.SetStateAction<string>>
    activeIndex: number
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>
    aggregateData: Array<T & { date: string }>
    setAggregateData: React.Dispatch<React.SetStateAction<Array<T & { date: string }>>>
  }
  ads: {
    visibleTooltip: string
    setVisibleTooltip: React.Dispatch<React.SetStateAction<string>>
    activeIndex: number
    setActiveIndex: React.Dispatch<React.SetStateAction<number>>
    aggregateData: Array<T & { date: string }>
    setAggregateData: React.Dispatch<React.SetStateAction<Array<T & { date: string }>>>
  }
}

const SyncedChartsContext = React.createContext<SyncedChartsContextInterface<any>>({} as SyncedChartsContextInterface<any>)

export const useSyncedChartsContext = <T,>() => React.useContext<SyncedChartsContextInterface<T & { date: string }>>(SyncedChartsContext)

interface SyncedChartsContextProviderProps<T> {
  children: React.ReactNode
}

export default function SyncedChartsProvider<T>({ children }: SyncedChartsContextProviderProps<T>) {
  const [overviewVisibleTooltip, setOverviewVisibleTooltip] = React.useState<string>(null)
  const [overviewActiveIndex, setOverviewActiveIndex] = React.useState<number>(null)
  const [overviewAggregateData, setOverviewAggregateData] = React.useState<Array<T & { date: string }>>([])

  const [adsVisibleTooltip, setAdsVisibleTooltip] = React.useState<string>(null)
  const [adsActiveIndex, setAdsActiveIndex] = React.useState<number>(null)
  const [adsAggregateData, setAdsAggregateData] = React.useState<Array<T & { date: string }>>([])

  const contextValue = React.useMemo(() => {
    return {
      overview: {
        visibleTooltip: overviewVisibleTooltip,
        setVisibleTooltip: setOverviewVisibleTooltip,
        activeIndex: overviewActiveIndex,
        setActiveIndex: setOverviewActiveIndex,
        aggregateData: overviewAggregateData,
        setAggregateData: setOverviewAggregateData
      },
      ads: {
        visibleTooltip: adsVisibleTooltip,
        setVisibleTooltip: setAdsVisibleTooltip,
        activeIndex: adsActiveIndex,
        setActiveIndex: setAdsActiveIndex,
        aggregateData: adsAggregateData,
        setAggregateData: setAdsAggregateData
      }
    }
  }, [overviewVisibleTooltip, overviewActiveIndex, overviewAggregateData, adsVisibleTooltip, adsActiveIndex, adsAggregateData])

  return <SyncedChartsContext.Provider value={contextValue}>{children}</SyncedChartsContext.Provider>
}
