import moment from 'moment'
import { useMemo } from 'react'
import { useLocation } from 'react-router-dom'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'

export default function useCustomStartDate() {
  const { currentUser } = useAuth()
  const { moduleDateFilterData } = useFilter()
  const location = useLocation()

  const activeModuleDateFilter = useMemo(() => {
    let pathname = location?.pathname || ''
    if (pathname.startsWith('/')) {
      pathname = pathname.substring(1)
    }
    if (moduleDateFilterData) {
      return moduleDateFilterData?.module_metadata?.find((item) => item?.route === pathname)
    }
    return null
  }, [moduleDateFilterData, location.pathname])

  const customStartDate = useMemo(() => {
    if (activeModuleDateFilter?.start_date) {
      return moment(activeModuleDateFilter.start_date)
    } else if (currentUser.org === 'whataburger') {
      return moment('2024-07-10')
    } else if (currentUser.org === 'awrg') {
      return moment('2024-07-01')
    }
    return null
  }, [currentUser, activeModuleDateFilter])

  const customEndDate = useMemo(() => {
    // if (currentUser.org === 'whataburger') {
    //   return moment('2024-08-10')
    // }
    if (activeModuleDateFilter?.end_date) {
      return moment(activeModuleDateFilter.end_date)
    }
    return null
  }, [activeModuleDateFilter])

  return { customStartDate, customEndDate }
}
