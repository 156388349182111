import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'
import { DefaultService } from 'src/services/openApi'
import { calculateDelta, formatCurrency } from 'src/utils/functions'
import useTabUrlState from 'src/utils/hooks/useTabUrlState'
import Overview from './Overview'
import { ApiKpiSalesOverviewTypeV2 } from './types'

export function AmountFormatter(num): string {
  if (Math.abs(num) > 999999999) {
    return ((Math.sign(num) * (Math.abs(num) / 1000000000)).toFixed(1) + 'B').toString()
  } else if (Math.abs(num) > 999999) {
    return ((Math.sign(num) * (Math.abs(num) / 1000000)).toFixed(1) + 'M').toString()
  } else if (Math.abs(num) > 999) {
    return ((Math.sign(num) * (Math.abs(num) / 1000)).toFixed(1) + 'k').toString()
  } else {
    return (Math.sign(num) * Math.abs(num)).toFixed(2).toString()
  }
}

const SalesOverview = () => {
  const { currentUser } = useAuth()
  const [linkTab, setLinkTab] = useTabUrlState('sales_overview', 1)
  const [salesOverviewResponse, setSalesOverviewResponse] = React.useState<ApiKpiSalesOverviewTypeV2 | null>(null)
  const [graphDataLoading, setGraphDataLoading] = React.useState(false)
  const [message, setMessage] = React.useState('')
  const [messageType, setMessageType] = React.useState('')

  const handleLinkTab = (event: React.SyntheticEvent, newValue: number) => {
    setLinkTab(newValue)
  }
  const labelArr = [
    { label: 'Overall Sales', value: 1 },
    { label: 'Orders', value: 2 }
    // { label: 'Payout', value: 3 }
  ]
  const { getFilters, getFiltersV2, dateRange } = useFilter()
  const procedureNameArray = [
    'data_visualization_metrics.procedure_dashboard__sales_overview__sales__date_graph',
    'data_visualization_metrics.procedure_dashboard__sales_overview__ordercount__date_graph_updated',
    'data_visualization_metrics.procedure_dashboard__sales_overview__payout__date_graph'
  ]

  const { total, delta, prevTotal } = React.useMemo(() => {
    if (!salesOverviewResponse) {
      return { total: 0, delta: 0, prevTotal: 0 }
    }
    const res = salesOverviewResponse
    let sum = linkTab === 1 ? get(res, 'current_range_data.sales_aggregate.sales', 0) : get(res, 'current_range_data.sales_aggregate.orders', 0)
    let prevSum = linkTab === 1 ? get(res, 'previous_range_data.sales_aggregate.sales', 0) : get(res, 'previous_range_data.sales_aggregate.orders', 0)
    let delta = calculateDelta(sum, prevSum)

    if (sum === 0) {
      setMessage('Zero Orders ')
      setMessageType('negetive')
    } else {
      setMessage('')
      setMessageType('')
    }
    return { total: sum, prevTotal: prevSum, delta }
  }, [linkTab, salesOverviewResponse])

  const graphData = React.useMemo(() => {
    if (!salesOverviewResponse) {
      return []
    }
    const res = salesOverviewResponse

    const currentPeriodData = res.current_range_data.sales_datewise.sort((a, b) => a.order_date.localeCompare(b.order_date))
    const prevPeriodData = res.previous_range_data.sales_datewise.sort((a, b) => a.order_date.localeCompare(b.order_date))
    const result = []

    for (let i = 0; i < Math.min(currentPeriodData.length, prevPeriodData.length); i++) {
      const current = currentPeriodData[i]
      const prev = prevPeriodData[i]
      const currentValue = linkTab === 1 ? current.sales : current.orders
      const prevValue = linkTab === 1 ? prev.sales : prev.orders
      result.push({
        id: i + 1,
        currentDate: moment(current.order_date).format('D MMM'),
        prevDate: moment(prev.order_date).format('D MMM'),
        currentValue,
        prevValue
      })
    }

    if (result.length === 0 && currentPeriodData.length > 0) {
      for (let i = 0; i < currentPeriodData.length; i++) {
        const current = currentPeriodData[i]
        const currentValue = linkTab === 1 ? get(current, 'sales', '') : get(current, 'orders', '')
        result.push({
          id: i + 1,
          currentDate: moment(current.order_date).format('D MMM'),
          currentValue
        })
      }
    }

    if (result.length === 0 && prevPeriodData.length > 0) {
      for (let i = 0; i < prevPeriodData.length; i++) {
        const prev = prevPeriodData[i]
        const prevValue = linkTab === 1 ? get(prev, 'sales', '') : get(prev, 'orders', '')
        result.push({
          id: i + 1,
          prevDate: moment(prev.order_date).format('D MMM'),
          prevValue
        })
      }
    }

    return result
  }, [salesOverviewResponse, linkTab])

  React.useEffect(() => {
    ;(async () => {
      setGraphDataLoading(true)
      try {
        // let sum = 0
        const res = (await DefaultService.getSalesOverviewV2KpiSalesOverviewV2Post({
          ...getFiltersV2(['b_name', 'vb_name', 'chain', 'vb_platform', 'am_name', 'start_date', 'end_date', 'granularity'], true)
        })) as ApiKpiSalesOverviewTypeV2
        setSalesOverviewResponse(res)
        setGraphDataLoading(false)
      } catch (error) {
        setGraphDataLoading(false)
        console.log(error)
      }
    })()
  }, [currentUser, ...getFilters(['b_name', 'vb_name', 'vb_platform', 'am_name', 'start_date', 'end_date', 'granularity'])])

  return (
    <>
      <Overview
        sectionLink="dashboard-sales-overview"
        name="Sales Overview"
        tabs={labelArr}
        indicator={{
          name: linkTab !== 2 ? 'Sales' : 'Orders',
          sum: linkTab !== 2 ? formatCurrency(total, { maxFractionDigits: 2 }) : total.toString(),
          sumColor: 'red',
          delta: delta,
          deltaColor: delta > 0 ? 'success' : 'error',
          deltaTooltip: `Previous sales: ${formatCurrency(prevTotal, { maxFractionDigits: 2 })}`,
          startDate: dateRange.start.format('D MMM'),
          endDate: dateRange.end.format('D MMM'),
          startDate2: graphData.length && graphData[0].prevDate,
          endDate2: graphData.length && graphData[graphData.length - 1].prevDate
        }}
        procedureNameArray={procedureNameArray}
        graphData={graphData}
        graphDataLoading={graphDataLoading}
        linkTab={linkTab}
        chartClickable={false}
        handleLinkTab={handleLinkTab}
        setGraphDataLoading={setGraphDataLoading}
        type={messageType}
        message={message}
      />
    </>
  )
}

export default SalesOverview
