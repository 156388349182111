import axios from 'axios'
import { BASE_FIREBASE_FUNCTIONS_URL } from '../config/config'

interface ValidateUserTokenRequestBody {
  tokenList: string[]
  getAuthToken: boolean
}

interface ValidateUserTokenResponseBody {
  expired: boolean
  message: string
  data?: {
    userUid: string
    createdDate: string
    expiredDate?: string
    authToken?: string
  }
}

export const validateUserToken = async (params: ValidateUserTokenRequestBody) => {
  return (await axios.post(`${BASE_FIREBASE_FUNCTIONS_URL}/validateUserToken`, params)).data as ValidateUserTokenResponseBody[]
}
