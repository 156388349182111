import { Paper } from '@LoopKitchen/loop-ui'
import LoopRoundedIcon from '@mui/icons-material/LoopRounded'
import ReportProblemRoundedIcon from '@mui/icons-material/ReportProblemRounded'
import { Button, Icon, Stack } from '@mui/material'
import { useContext } from 'react'
import imageComponentFailed from 'src/assets/componentfailed.svg'
import { POST_HOG_BASE_URL } from 'src/assets/configs/globalConst'
import { AuthContext } from 'src/context/AuthContext'
import { sendSlackNotification } from 'src/utils/api'
import { getFrontendAlertChannel } from 'src/utils/functions/slackSupport'
import { CustomTypoContainer } from '../StyledTypo'
const SentryErrorFallback = (error: { error: { message: string } }) => {
  if (!error) {
    return
  }

  if (error.error.message.includes('Loading chunk') && error.error.message.includes('failed')) {
    window.location.reload()
  }

  const { currentUser } = useContext(AuthContext)

  const email = currentUser.email
  const org = currentUser.org

  sendSlackNotification({
    message: `Component load failed for ${email} of ${org};`,
    channel: getFrontendAlertChannel(),
    title: `urls:
      \`<${window.location.href}|${window.location.href}>\`
      \`<${POST_HOG_BASE_URL}/person/${email}#activeTab=sessionRecordings|${email}>\`
      \`<${POST_HOG_BASE_URL}/groups/0/${org}|${org}>\`
      \`${error.error.message}\`
    `
  })

  return (
    <>
      <Stack
        sx={{
          width: '100%',
          position: 'relative',
          height: '91vh',
          background: 'lightgray',
          overflow: 'hidden'
        }}>
        <div style={{ position: 'absolute', top: '0', left: '0', width: '100%', height: '100%' }}>
          <Paper
            elevation={10}
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              background: 'white',
              padding: '5rem',
              borderRadius: 4
            }}>
            <Stack
              sx={{
                justifyContent: 'center',
                alignItems: 'center',
                marginBottom: '2rem'
              }}>
              <img
                src={imageComponentFailed}
                alt="Component Failed Image"
              />
            </Stack>
            <Stack
              direction={'column'}
              gap={2}>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Icon color="error">
                  <ReportProblemRoundedIcon />
                </Icon>
                <CustomTypoContainer>Oops, Something went wrong</CustomTypoContainer>
              </Stack>
              <Stack
                direction={'row'}
                alignItems={'center'}
                justifyContent={'center'}>
                <Button
                  variant="text"
                  onClick={() => {
                    window.location.reload()
                  }}
                  sx={{
                    background: 'rgba(204, 237, 245, 1)',
                    color: 'rgba(25, 110, 130, 1)'
                  }}
                  startIcon={<LoopRoundedIcon />}>
                  <p
                    style={{
                      fontWeight: 600,
                      fontSize: 14
                    }}>
                    Refresh the page
                  </p>
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </div>
      </Stack>
    </>
  )
}
export default SentryErrorFallback
