import Skeleton from '@mui/material/Skeleton'
import { capitalize, get } from 'lodash'
import React, { useCallback } from 'react'
import ReactApexChart from 'react-apexcharts'
import { useFilter } from 'src/context/FilterContext'
import { formatNumber } from 'src/utils/functions'
import { indicatorColor } from '../utils/constants'
import { usePayoutsBreakdownDataContext } from '../utils/context/PayoutsBreakdownDataContext'

export type GraphDataType = {
  date: string
  marketing: number
  refunds: number
  commissions: number
  others?: number
}

interface StackedBarChartProps {
  data: GraphDataType[]
  loading?: boolean
}

export default function StackedBarChart(props: StackedBarChartProps) {
  const { data } = props
  const { getFilters } = useFilter()
  const { fontSizes } = usePayoutsBreakdownDataContext()
  const granularity = getFilters(['granularity'])[0]

  let attachLastDate = useCallback(
    function (startDate: string, findByDates: number): string {
      const start = new Date(startDate) // Parse the start date
      if (!isNaN(start.getTime())) {
        const end = new Date(start)

        end.setDate(end.getDate() + findByDates) // Add one week to the start date

        // Format the dates as "DDMMM" (e.g., "20Aug")
        const startFormatted = `${start.getDate()} ${start.toLocaleString('default', { month: 'short' })}`
        const endFormatted = `${end.getDate()} ${end.toLocaleString('default', { month: 'short' })}`

        return `${startFormatted} - ${endFormatted}`
      }

      // If parsing the start date fails, return the original string
      return startDate
    },
    [data]
  )

  const { xAxisCategory, series } = React.useMemo(() => {
    const xAxisCategory: string[] = []
    const seriesObj: { [key: string]: { data: number[] } } = {}
    let byAdd = 0
    if (granularity === 'week') {
      byAdd = 6
    } else if (granularity === 'month') {
      byAdd = 29
    }
    for (let i = 0; i < data.length; i++) {
      const obj = data[i]
      const keys = Object.keys(obj)
      for (let j = 0; j < keys.length; j++) {
        const key = keys[j]

        if (key === 'date') {
          if (byAdd === 0) {
            xAxisCategory.push(obj[key])
          } else xAxisCategory.push(attachLastDate(obj[key].split(',')[0], byAdd) + ',' + obj[key].split(',')[1])
        } else {
          if (!(key in seriesObj)) {
            seriesObj[key] = { data: [] }
          }
          seriesObj[key].data.push(Number(obj[key]))
        }
      }
    }

    const series: ApexAxisChartSeries | ApexNonAxisChartSeries = Object.keys(seriesObj).map((key) => {
      return {
        name: capitalize(key),
        data: seriesObj[key].data,
        color: get(indicatorColor, `${key}`, '#000')
      }
    })

    return { xAxisCategory, series }
  }, [data])

  return (
    <>
      {props.loading ? (
        <>
          <Skeleton
            variant="rounded"
            width={'100%'}
            height={250}
          />
          <div style={{ display: 'flex' }}>
            <Skeleton
              variant="text"
              width={100}
              height={32}
              sx={{ mr: 1 }}
            />
            <Skeleton
              variant="text"
              width={100}
              height={32}
              sx={{ mr: 1 }}
            />
            <Skeleton
              variant="text"
              width={100}
              height={32}
              sx={{ mr: 1 }}
            />
          </div>
        </>
      ) : (
        <ReactApexChart
          type="bar"
          height={'100%'}
          series={series}
          options={{
            chart: { type: 'bar', height: 300, stacked: true },
            xaxis: {
              categories: xAxisCategory,
              labels: {
                style: {
                  fontSize: fontSizes['12'] + 'px'
                }
              }
            },
            yaxis: {
              // min: 0,
              // max: 100,
              tickAmount: 5,
              labels: {
                show: true,
                style: {
                  fontSize: fontSizes['12'] + 'px'
                },
                formatter: (val) => {
                  return formatNumber(val, { maxFractionDigits: 2 }) + '%'
                }
              }
            },
            dataLabels: {
              enabled: xAxisCategory.length < 10,
              style: {
                fontSize: fontSizes['11'] + 'px',
                colors: ['#000', '#000', '#FFF', '#000']
              },
              formatter: (val) => {
                return typeof val === 'string' ? val : formatNumber(Number(val), { maxFractionDigits: 2 }) + '%'
              }
            },
            plotOptions: {
              bar: {
                horizontal: false,
                dataLabels: {
                  total: {
                    enabled: xAxisCategory.length < 10,
                    style: {
                      fontSize: fontSizes['11'] + 'px',
                      fontWeight: 600
                    }
                  }
                }
              }
            },
            legend: {
              position: 'bottom',
              horizontalAlign: 'left',
              fontSize: fontSizes['12'] + 'px',
              markers: {
                width: 10,
                height: 10,
                radius: 0,
                offsetX: -10
              },
              itemMargin: {
                horizontal: 20
              }
            },
            fill: {
              opacity: 1
            }
          }}
        />
      )}
    </>
  )
}
