import { Box, CircularProgress } from '@mui/material'

const ComponentLoader = () => {
  return (
    <Box
      width="100%"
      height="100%"
      display="flex"
      alignItems="center"
      justifyContent="center">
      {/* <LottieLoader /> */}
      <CircularProgress />
    </Box>
  )
}

export default ComponentLoader
