import { Box, Dialog, Typography } from '@mui/material'
import RedirectionBanner from 'src/assets/images/RedirectionBanner.svg'
import { useAuth } from 'src/context/AuthContext'
import RedirectionButton from './RedirectionButton'

const RedirectionDialog = () => {
  const { openRedirectionDialog, setOpenRedirectionDialog, currentUser } = useAuth()

  const handleClose = async () => {
    if (openRedirectionDialog?.openRedirection) {
      const callbackFn = openRedirectionDialog?.redirectionCallback

      setOpenRedirectionDialog((prev) => ({
        ...prev,
        openRedirection: false,
        redirectionCallback: () => Promise.resolve()
      }))

      await callbackFn()
    }

    if (openRedirectionDialog?.openRedirected) {
      const callbackFn = openRedirectionDialog?.redirectedCallback

      setOpenRedirectionDialog((prev) => ({
        ...prev,
        openRedirected: false,
        redirectedCallback: () => Promise.resolve()
      }))

      if (!currentUser) await callbackFn()
    }
  }

  return (
    <Dialog
      open={openRedirectionDialog?.openRedirection || openRedirectionDialog?.openRedirected}
      maxWidth="sm"
      fullWidth>
      {openRedirectionDialog?.openRedirection && (
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          gap={4}>
          <img
            src={RedirectionBanner}
            alt="Redirection Banner"
            style={{
              width: '100%'
            }}
          />

          <Box
            display="flex"
            flexDirection="column"
            alignItems="center">
            <Typography
              fontSize={22}
              fontWeight={600}
              color="#000"
              textAlign="center">
              We are moving!
            </Typography>
            <Typography
              fontSize={14}
              color="#000"
              textAlign="center"
              mt={1}>
              Redirecting to https://app.tryloop.ai
            </Typography>
            <Typography
              fontSize={14}
              color="#000"
              textAlign="center">
              Please use this primary domain for future sessions. Thank you for understanding!
            </Typography>
          </Box>

          <RedirectionButton
            redirectText="Redirect Now"
            redirectingText="Redirecting in"
            handleClose={handleClose}
          />
        </Box>
      )}
      {openRedirectionDialog?.openRedirected && (
        <Box
          p={2}
          display="flex"
          flexDirection="column"
          gap={4}>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="center"
            mt={2}>
            <Typography
              fontSize={22}
              fontWeight={600}
              color="#000"
              textAlign="center">
              You are now in https://app.tryloop.ai
            </Typography>
            <Typography
              fontSize={14}
              color="#000"
              textAlign="center"
              mt={1}>
              Please use this primary domain for future sessions. Thank you for understanding!
            </Typography>
          </Box>

          <RedirectionButton
            redirectText="Got It"
            redirectingText="Closing in"
            handleClose={handleClose}
          />
        </Box>
      )}
    </Dialog>
  )
}

export default RedirectionDialog
