import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, Collapse, Icon, SxProps, Typography } from '@mui/material'
import React, { useEffect } from 'react'

interface HeadingContainerProps {
  title: string
  icon: React.ReactNode
  children: React.ReactNode
  sx?: SxProps
  collapsable?: boolean
  defaultOpen?: boolean
}

export default function HeadingContainer(props: HeadingContainerProps) {
  const { title, icon, children, sx, collapsable, defaultOpen } = props
  const [opened, setOpened] = React.useState(true)

  useEffect(() => {
    if (collapsable) setOpened(defaultOpen || false)
  }, [collapsable])

  return (
    <Box sx={sx}>
      <Box
        display="flex"
        alignItems="center"
        gap="8px"
        py={1}
        width="100%"
        sx={{ cursor: collapsable ? 'pointer' : 'default' }}
        onClick={() => {
          if (collapsable) setOpened((prev) => !prev)
        }}>
        <Box
          display="flex"
          alignItems="center"
          gap="8px"
          width="100%">
          {typeof icon === 'string' ? <Icon sx={{ fontSize: '24px', color: '#000' }}>{icon}</Icon> : icon}
          <Typography
            fontSize="14px"
            fontWeight={600}
            color="#000"
            sx={{ flex: 1 }}>
            {title}
          </Typography>
        </Box>

        {collapsable && (
          <KeyboardArrowDown sx={{ fontSize: '20px', color: '#000', transform: opened ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.3s ease' }} />
        )}
      </Box>
      <Collapse in={opened}>
        <Box sx={{ my: 2 }}>{children}</Box>
      </Collapse>
    </Box>
  )
}
