import { useMediaQuery, useTheme } from '@mui/material'
import { collection, doc, onSnapshot, query } from 'firebase/firestore'
import { find, uniq } from 'lodash'
import React from 'react'
import { RouteObject } from 'react-router-dom'
import { db } from 'src/services/firebase'
import { AccessLevelEnum } from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'
import { getOrgRoutesInArrayList } from 'src/utils/functions/liveRoutes'
import { useAuth } from './AuthContext'
import { useErrorData } from './ErrorContext'

export interface RouteCategoryType {
  uid: string
  name: string
  key: string
  routes: string[]
  showInSidebar: boolean
}

export interface CategorizedRoutesType {
  category: {
    name: string
    key: string
    uid?: string
    routes: string[]
    showInSidebar: boolean
  }
  routes: {
    icon: { label: string; value: string }
    label: string
    key: string
    subNav: { label: string; key: string }[]
    uid: string
    showInSidebar: boolean
    hidden?: boolean
    type?: string
    pageTitle?: string
    tooltip?: string
    created_at?: string
  }[]
  showInSidebar: boolean
}

interface PrivateLayoutDrawerContextInterface {
  theme: any
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  routes: any[]
  routeLoading: boolean
  routeCategories: RouteCategoryType[]
  categoryLoading: boolean
  categoryOrder: string[]
  toggleDrawer: () => void
  privateDrawerChildren?: React.ReactNode
  setPrivateDrawerChildren: React.Dispatch<React.SetStateAction<React.ReactNode>>
  privateRoutes: RouteObject[]
  setPrivateRoutes: React.Dispatch<React.SetStateAction<RouteObject[]>>
  categorizedRoutes: CategorizedRoutesType[]
}

const PrivateLayoutDrawerContext = React.createContext({} as PrivateLayoutDrawerContextInterface)

export const usePrivateLayoutDrawerContext = () => React.useContext(PrivateLayoutDrawerContext)

interface PrivateLayoutDrawerContextProviderProps {
  children: React.ReactNode
}

export default function PrivateLayoutDrawerContextProvider(props: PrivateLayoutDrawerContextProviderProps) {
  const theme: any = useTheme()
  const { currentUser, orgConfig, globalRoutes, getRoutes } = useAuth()
  const { handleError } = useErrorData()
  const [open, setOpen] = React.useState(true)
  const [routeLoading, setRouteLoading] = React.useState(false)
  const [routes, setRoutes] = React.useState([])
  const [categoryLoading, setCategoryLoading] = React.useState(true)
  const [routeCategories, setRouteCategories] = React.useState<RouteCategoryType[]>([])
  const [categoryOrder, setCategoryOrder] = React.useState([])
  const [privateDrawerChildren, setPrivateDrawerChildren] = React.useState<React.ReactNode>()
  const [privateRoutes, setPrivateRoutes] = React.useState<PrivateLayoutDrawerContextInterface['privateRoutes']>([])
  const sm = useMediaQuery(theme.breakpoints.down('md'))

  const toggleDrawer = () => setOpen(!open)

  React.useEffect(() => {
    if (sm === true) setOpen(false)
    if (sm === false) setOpen(true)
  }, [sm])

  const getRouteObject = (uid, routes) => {
    const route = find(routes, { uid })
    return route
  }

  React.useEffect(() => {
    const unsubscribeCategories = onSnapshot(query(collection(db, 'route_categories')), (snapshot) => {
      setCategoryLoading(true)
      const categories = snapshot.docs.map((doc) => {
        return { ...doc.data() } as RouteCategoryType
      })
      setRouteCategories(categories)
      setCategoryLoading(false)
    })

    const unsubscribeCategoryOrder = onSnapshot(doc(db, 'configs/route_categories'), (snapshot) => {
      setCategoryLoading(true)
      const categories = snapshot.data()?.order || []
      setCategoryOrder(categories)
      setCategoryLoading(false)
    })

    return () => {
      unsubscribeCategories()
      unsubscribeCategoryOrder()
    }
  }, [])

  function getOrgRouteObject(orgRoutes, uid) {
    return orgRoutes.find((route) => typeof route === 'object' && route.uid === uid)
  }

  React.useEffect(() => {
    async function fetchOrgConfigOrder() {
      try {
        setRouteLoading(true)
        const tempLevel = getAccessLevel(currentUser)
        const accessLevel: AccessLevelEnum = Array.isArray(tempLevel) ? tempLevel[0] : tempLevel
        let tempAccessLevel = Object.keys(orgConfig?.access_levels || {}).includes(accessLevel) ? accessLevel : AccessLevelEnum.BUSINESS_ADMIN

        let tempRoutes = []
        if (tempAccessLevel != AccessLevelEnum.DEMO) {
          tempRoutes = getRoutes(tempAccessLevel)
        } else {
          tempRoutes = getRoutes(AccessLevelEnum.BUSINESS_ADMIN)
        }

        tempRoutes = getOrgRoutesInArrayList(tempRoutes).map((item) => {
          let orgRouteObject = getOrgRouteObject(tempRoutes, item)
          if (typeof item === 'string') {
            let routeObject = getRouteObject(item, globalRoutes)

            return { ...routeObject, ...orgRouteObject }
          } else if (typeof item === 'object') {
            return {
              ...getRouteObject(item.uid, globalRoutes),
              hidden: true,
              ...orgRouteObject
            }
          }
          return item
        })

        const uniqRoutes = uniq(tempRoutes)
        setRoutes(uniqRoutes.filter((item) => item !== undefined))
        setRouteLoading(false)
      } catch (error) {
        setRouteLoading(false)
        handleError(error.message)
      }
    }
    if (currentUser) fetchOrgConfigOrder()
  }, [currentUser, orgConfig, globalRoutes, getRoutes])

  const categorizedRoutes = React.useMemo(() => {
    if (!categoryOrder || categoryOrder.length === 0) return []
    const result: CategorizedRoutesType[] = []
    const routesWithCategories = []
    categoryOrder.forEach((categoryUid) => {
      const category = routeCategories.find((item) => item.key === categoryUid)
      if (category) {
        const assignedRoutes = []
        category.routes.forEach((routeUid) => {
          const route = routes.find((item) => item.uid === routeUid)
          if (route && route.showInSidebar && Object.keys(route).length > 0) {
            assignedRoutes.push(route)
            routesWithCategories.push(route.uid)
          }
        })
        if (assignedRoutes.length)
          result.push({
            category: { ...category },
            routes: assignedRoutes,
            showInSidebar: category.showInSidebar
          })
      }
    })
    const routesWithoutCategories = routes.filter((route) => route.showInSidebar && !routesWithCategories.includes(route.uid) && Object.keys(route).length > 0)
    if (routesWithoutCategories.length)
      result.push({
        category: {
          name: 'Others',
          key: 'others',
          routes: routesWithoutCategories.map((route) => route.uid),
          showInSidebar: true
        },
        routes: routesWithoutCategories,
        showInSidebar: true
      })

    return result
  }, [routeCategories, categoryOrder, routes])

  const contextValue = React.useMemo(() => {
    return {
      theme,
      open,
      setOpen,
      routes,
      routeLoading,
      routeCategories,
      categoryOrder,
      categoryLoading,
      toggleDrawer,
      privateDrawerChildren,
      setPrivateDrawerChildren,
      privateRoutes,
      setPrivateRoutes,
      categorizedRoutes
    }
  }, [routeLoading, routes, categoryLoading, routeCategories, categoryOrder, theme, open, privateDrawerChildren, privateRoutes, categorizedRoutes])

  return <PrivateLayoutDrawerContext.Provider value={contextValue}>{props.children}</PrivateLayoutDrawerContext.Provider>
}
