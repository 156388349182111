import React, { Suspense, useEffect } from 'react'
import ReactGA from 'react-ga4'
import { useLocation, useSearchParams } from 'react-router-dom'
import RedirectionDialog from 'src/components/Dialogs/RedirectionDialog/RedirectionDialog'
import CustomDialog from 'src/components/mui/CustomDialog'
import Loading from 'src/components/mui/Loader'
import DrillDownContextProvider from 'src/context/DrillDownContext'
import FilterContextProvider from 'src/context/FilterContext'
import GlobalDialogContextProvider from 'src/context/GlobalDialogContext'
import NewFilterContextProvider from 'src/context/NewFilterContext/NewFilterContext'
import PrivateLayoutDrawerContextProvider from 'src/context/PrivateLayoutDrawerContext'
import { usePublicAuthContext } from 'src/context/PublicAuthContext'
import StoreDetailContextProvider from 'src/context/StoreDetailContext'
import SubChainContextProvider from 'src/context/SubChainContext'
import TopbarContextProvider from 'src/context/TopbarContext'
import WizardContextProvider from 'src/context/WizardContext'
import PrivateRouter from 'src/routes/PrivateRouter'
import AuthContextProvider from '../context/AuthContext'

const NoInternetPage = React.lazy(() => import('src/pages/noInternet/NoInternet'))
const PublicRouter = React.lazy(() => import('src/routes/PublicRouter'))

const Router: React.FC = React.memo(() => {
  const { currentUser, pageDetails, setPageDetails, loading } = usePublicAuthContext()
  const location = useLocation()
  const [params, setParams] = useSearchParams()

  useEffect(() => {
    if (currentUser?.uid) {
      const { uid, email, phoneNumber } = currentUser
      ReactGA.initialize('G-JZW0YC7GZN', { gaOptions: { userId: uid } })
      ReactGA.event({ action: 'User', category: 'user_id', label: uid, nonInteraction: true })

      if (email) {
        const emailLabel = email.replace('@', '-') || phoneNumber
        ReactGA.event({ action: 'User', category: 'user_mail', label: emailLabel, nonInteraction: true })
        ReactGA.event({ action: 'user_mail', category: emailLabel, nonInteraction: true })
      }

      if (phoneNumber) {
        ReactGA.event({ action: 'User', category: 'user_phone', label: phoneNumber, nonInteraction: true })
        ReactGA.event({ action: 'user_phone', category: phoneNumber, nonInteraction: true })
      }

      ReactGA.event({ action: 'user_id', category: uid, nonInteraction: true })
    }
  }, [currentUser])

  useEffect(() => {
    if (pageDetails?.path !== location.pathname) {
      params.delete('section')
      setParams(params)
      const currentTime = new Date().getTime()
      setPageDetails({ path: location.pathname, visitTime: currentTime })
    }
    ReactGA.send({ hitType: 'pageview', page: location.pathname })
  }, [location])

  useEffect(() => {
    const url = new URL(window.location.href)
    if (url.searchParams.has('referred_by')) {
      url.searchParams.delete('referred_by')
      window.history.replaceState('', '', url.toString())
    }
  }, [window.location.href])

  // if (!navigator.onLine) {
  //   return (
  //     <Suspense fallback={<div>Loading...</div>}>
  //       <NoInternetPage />
  //     </Suspense>
  //   )
  // }

  return (
    <GlobalDialogContextProvider>
      <RedirectionDialog />
      <CustomDialog />
      {loading && <Loading loading />}
      {!currentUser ? (
        <Suspense fallback={<Loading loading />}>
          <PublicRouter />
        </Suspense>
      ) : (
        <Suspense fallback={<Loading loading />}>
          <AuthContextProvider>
            <SubChainContextProvider>
              <DrillDownContextProvider>
                <FilterContextProvider>
                  <NewFilterContextProvider>
                    <StoreDetailContextProvider>
                      <PrivateLayoutDrawerContextProvider>
                        <WizardContextProvider>
                          <TopbarContextProvider>
                            <PrivateRouter />
                          </TopbarContextProvider>
                        </WizardContextProvider>
                      </PrivateLayoutDrawerContextProvider>
                    </StoreDetailContextProvider>
                  </NewFilterContextProvider>
                </FilterContextProvider>
              </DrillDownContextProvider>
            </SubChainContextProvider>
          </AuthContextProvider>
        </Suspense>
      )}
    </GlobalDialogContextProvider>
  )
})

export default Router
