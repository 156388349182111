import { ItemModel } from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
import { Box, Stack, SvgIcon, Typography } from '@mui/material'
import React from 'react'
import { formatCurrency } from 'src/utils/functions'
import BorderedPriceBox from './helpers/BorderedPriceBox'
import DataNotAvailable from './helpers/DataNotAvailable'
import HeadingContainer from './helpers/HeadingContainer'

interface ItemsCompProps {
  items?: ItemModel[]
  loading?: boolean
  collapsable?: boolean
}

export default function ItemsComp(props: ItemsCompProps) {
  const { items = [], loading, collapsable } = props

  const itemsList = React.useMemo(() => {
    if (loading) {
      const arr = new Array(3)
      arr.fill(1)
      return arr as typeof items
    }
    return items || []
  }, [items, loading])

  const firstCollapseIndex = React.useMemo(() => {
    return itemsList?.findIndex((item) => item?.modifications?.length > 0)
  }, [itemsList])

  return (
    <HeadingContainer
      icon={'category'}
      title="Items"
      collapsable={collapsable}
      defaultOpen={true}>
      <Stack
        direction="column"
        alignItems="stretch"
        gap="5px">
        {itemsList.length === 0 && !loading && (
          <DataNotAvailable
            icon="category"
            label="Items not available"
          />
        )}
        {itemsList.map((item, index) => {
          return (
            <ItemsComp.ItemCollapse
              collapseDefaultExpanded={index === firstCollapseIndex}
              key={index + (item?.id || item).toString()}
              item={item}
              loading={loading}
            />
          )
        })}
      </Stack>
    </HeadingContainer>
  )
}

ItemsComp.ItemCollapse = (props: { item: ItemModel; loading?: boolean; collapseDefaultExpanded?: boolean }) => {
  const { item, loading, collapseDefaultExpanded } = props

  return (
    <BorderedPriceBox
      collapseDefaultExpanded={collapseDefaultExpanded}
      label={`${item?.quantity} x ${item?.name}`}
      value={formatCurrency(item?.price, { maxFractionDigits: 2 })}
      loading={loading}
      startElement={
        item?.imageurl ? (
          <img
            src={item?.imageurl}
            style={{ width: '28px', height: '28px', objectFit: 'cover' }}
          />
        ) : (
          <SvgIcon sx={{ fontSize: '28px' }}>
            <svg
              width="29"
              height="29"
              viewBox="0 0 29 29"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <rect
                x="0.193359"
                y="0.390839"
                width="27.9999"
                height="27.9999"
                rx="4"
                fill="#F4F4F4"
              />
              <path
                d="M10.0177 12.4459C9.23675 12.4459 8.75718 11.5908 9.1644 10.9245L12.7206 5.10523C13.1105 4.46717 14.0372 4.46717 14.4272 5.10523L17.9834 10.9245C18.3906 11.5908 17.911 12.4459 17.1301 12.4459H10.0177ZM18.9131 23.1244C17.6997 23.1244 16.6682 22.6997 15.8188 21.8503C14.9694 21.0009 14.5447 19.9694 14.5447 18.7559C14.5447 17.5425 14.9694 16.511 15.8188 15.6616C16.6682 14.8122 17.6997 14.3875 18.9131 14.3875C20.1266 14.3875 21.158 14.8122 22.0075 15.6616C22.8569 16.511 23.2816 17.5425 23.2816 18.7559C23.2816 19.9694 22.8569 21.0009 22.0075 21.8503C21.158 22.6997 20.1266 23.1244 18.9131 23.1244ZM5.83691 22.639C5.28463 22.639 4.83691 22.1913 4.83691 21.639V15.8729C4.83691 15.3206 5.28463 14.8729 5.83691 14.8729H11.6031C12.1554 14.8729 12.6031 15.3206 12.6031 15.8729V21.639C12.6031 22.1913 12.1554 22.639 11.6031 22.639H5.83691Z"
                fill="white"
              />
              <path
                d="M10.0177 11.9459C9.62721 11.9459 9.38743 11.5184 9.59104 11.1852L13.1472 5.36596C13.3422 5.04692 13.8056 5.04692 14.0005 5.36596L17.5567 11.1852C17.7603 11.5184 17.5205 11.9459 17.1301 11.9459H10.0177ZM18.9131 22.6244C17.8308 22.6244 16.9269 22.2513 16.1723 21.4967C15.4178 20.7421 15.0447 19.8383 15.0447 18.7559C15.0447 17.6736 15.4178 16.7698 16.1723 16.0152C16.9269 15.2606 17.8308 14.8875 18.9131 14.8875C19.9955 14.8875 20.8993 15.2606 21.6539 16.0152C22.4085 16.7698 22.7816 17.6736 22.7816 18.7559C22.7816 19.8383 22.4085 20.7421 21.6539 21.4967C20.8993 22.2513 19.9955 22.6244 18.9131 22.6244ZM5.83691 22.139C5.56077 22.139 5.33691 21.9152 5.33691 21.639V15.8729C5.33691 15.5967 5.56077 15.3729 5.83691 15.3729H11.6031C11.8792 15.3729 12.1031 15.5967 12.1031 15.8729V21.639C12.1031 21.9152 11.8792 22.139 11.6031 22.139H5.83691Z"
                stroke="#BEBEBE"
                stroke-opacity="0.901961"
              />
            </svg>
          </SvgIcon>
        )
      }
      collapseElement={
        item?.modifications?.length > 0 && (
          <Box
            sx={{
              border: '1px solid rgba(230, 230, 230, 0.9)',
              borderRadius: '4px',
              px: '6px',
              py: '3px',
              mt: '5px'
            }}>
            <Typography
              fontSize="12px"
              color="rgba(0, 0, 0, 0.5)"
              sx={{ mb: '5px' }}>
              Modifications
            </Typography>
            <Stack
              direction="column"
              gap="5px">
              {item?.modifications?.map((mod, index) => {
                return (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    gap="5px">
                    <Typography
                      fontSize="14px"
                      color="#000"
                      sx={{ flex: 1 }}>
                      {mod.quantity} x {mod.name}
                    </Typography>
                    <Typography
                      fontSize="14px"
                      color="#000">
                      {formatCurrency(mod.price, { maxFractionDigits: 2 })}
                    </Typography>
                  </Stack>
                )
              })}
            </Stack>
          </Box>
        )
      }
    />
  )
}
