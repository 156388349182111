import { wrapUseRoutes } from '@sentry/react'
import { doc, onSnapshot } from 'firebase/firestore'
import get from 'lodash/get'
import React, { lazy, useEffect, useState } from 'react'
import { matchPath, Navigate, RouteObject, useLocation, useRoutes } from 'react-router-dom'
import Loader from 'src/components/mui/Loader'
import { useAuth } from 'src/context/AuthContext'
import { useFilter } from 'src/context/FilterContext'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import NotFound from 'src/pages/public/notFound/NotFound'
import { fetchUserOrgAndRole, firebaseDb } from 'src/services/firebase'
import { sendSlackNotification } from 'src/utils/api'
import { getLocal } from 'src/utils/functions/localStorage'
import useStandaloneRouteData from 'src/utils/hooks/useStandaloneRouteData'
import { PrivateLayout } from '../layout'
import CardReconciliation from '../pages/members/Pos/CardReconciliation'
import ThirdPartyOrdersReconciliation from '../pages/members/Pos/ThirdPartyOrdersReconciliation'
import ThirdPartyReconciliation from '../pages/members/Pos/ThirdPartyReconciliation'
import generalRoutes from './generalRoutes'

const useSentryRoutes = wrapUseRoutes(useRoutes)

const HomePage = lazy(() => import('src/pages/members/HomePage/HomePage'))
const WaitingPage = lazy(() => import('src/pages/public/waiting/WaitingPage'))
const AccountingPortal = lazy(() => import('src/pages/members/AccountingPortal/AccountingPortal'))
const NotificationCenter = lazy(() => import('src/pages/members/Notifications/NotificationCenter'))

const SalesOverview = lazy(() => import('src/pages/members/SalesOverview/Dashboard'))
// const Dashboard = lazy( () => import( 'src/pages/members/dashboard/Dashboard' ) )
const DashboardTrendsOnly = lazy(() => import('src/pages/members/dashboard/subPages/TrendsOnly'))
const DashboardMetricsOnly = lazy(() => import('src/pages/members/dashboard/subPages/MetricsOnly'))
const DashboardErrorOverview = lazy(() => import('src/pages/members/dashboard/subPages/ErrorOverview'))
const DashboardSales = lazy(() => import('src/pages/members/dashboard/subPages/Sales'))
const DashboardRatingTrends = lazy(() => import('src/pages/members/dashboard/subPages/DashboardRatingTrends'))
const Chooseplateform = lazy(() => import('src/pages/members/credentials/Chooseplateform'))
const Process = lazy(() => import('src/pages/members/credentials/Process'))
const Orders = lazy(() => import('src/pages/members/Orders/Orders'))
const OrdersOld = lazy(() => import('src/pages/members/OrdersOld/Orders'))
const Reviews = lazy(() => import('src/components/Reviews/Reviews'))
const PlaidAuth = lazy(() => import('src/components/Plaid/PlaidAuth'))
const StudioContainer = lazy(() => import('../pages/members/StudioContainer'))
const SupersetDashboard = lazy(() => import('../pages/members/SupersetDashboard'))
const Landing = lazy(() => import('../pages/members/Landing'))
const Visuals = lazy(() => import('../pages/members/Visuals'))
const LinkAuthProvider = lazy(() => import('src/pages/members/LinkAuthProvider/LinkAuthProvider'))

//ORDER ACCURACY
const Errors = lazy(() => import('../pages/members/Errors/Errors'))
const IssueDiscovery = lazy(() => import('src/pages/members/IssueDiscovery/IssueDiscovery'))
const IssueDiscoveryWithFilter = lazy(() => import('src/pages/members/IssueDiscoveryWithFilter/IssueDiscoveryWithFilter'))
const InAccurateOrders = lazy(() => import('../pages/members/InAccurateOrders/InAccurateOrders'))
const MissedOrders = lazy(() => import('src/pages/members/MissedOrders/MissedOrders'))
const CancelledOrders = lazy(() => import('src/pages/members/CancelledOrders/CancelledOrders'))

const Uploadcsv = lazy(() => import('../pages/members/credentials/Uploadcsv'))
const IntegrationStatus = lazy(() => import('../pages/members/IntegrationStatus'))
const DataRefreshStatus = lazy(() => import('../pages/members/DataRefreshStatus'))
const Shift = lazy(() => import('../pages/members/Shift/Shift'))
const OwnerDetails = lazy(() => import('src/pages/members/OwnerDetails/OwnerDetails'))
const ShiftLeaderboard = lazy(() => import('src/pages/members/Shift/Leaderboard'))
const VideoUpload = lazy(() => import('src/pages/members/video/VideoUpload'))
const DisputableOrders = lazy(() => import('src/pages/members/video/DisputableOrders'))
const UploadCsvDetail = lazy(() => import('../pages/members/credentials/UploadCSVDetails'))
// const Finance = lazy(() => import('src/pages/members/Finance/Finance'));
// const FinanceBreakdown = lazy(() => import("src/pages/members/Finance/FinanceBreakdown"));
const ItemPerformance = lazy(() => import('src/pages/members/ItemPerformance/ItemPerformance'))
const ItemPerformancePage = lazy(() => import('src/pages/members/ItemPerformance/ItemPerformancePage'))
const SingleCampaignAllStores = lazy(() => import('src/pages/members/Marketing/SingleCampaign/SingleCampaignAllStores'))

const WaitingScreen = lazy(() => import('../pages/members/Marketing/SingleCampaign/WaitingScreen'))
const VbCampaignAllStores = lazy(() => import('../pages/members/Marketing/VbCampaignAllStores/VbCampaign'))

// Specific Item Performance
const SpecificItemPerformance = lazy(() => import('src/pages/members/ItemPerformance/SpecificItemPerformance/SpecificItemPerformance'))

// Chargebacks
const Chargebacks = lazy(() => import('src/pages/members/Chargebacks/Chargebacks'))
const SentryError = lazy(() => import('src/pages/members/Test'))
const ChargebacksTransactions = lazy(() => import('src/pages/members/ChargebacksTransactions/ChargebacksTransactions'))
const ChargebacksHistoryStoreView = lazy(() => import('src/pages/members/HistoryByStore/pages/ChargebacksHistoryStoreView/ChargebacksHistoryStoreView'))
const ChargebackConfigs = lazy(() => import('src/pages/members/ChargebackConfigs/ChargebackConfigs'))
const ChargebackDisputeConfigs = lazy(() => import('src/pages/members/ChargebackDisputeConfigs/ChargebackDisputeConfigs'))

// Order Based Paying
const FinancePayouts = lazy(() => import('src/pages/members/Finance/Finance'))
const BankReconciliation = lazy(() => import('src/pages/members/Finance/SubPages/BankReconciliation'))
const ListOfPayouts = lazy(() => import('src/pages/members/Finance/SubPages/ListOfPayouts'))
const OrderBasedPaying = lazy(() => import('src/pages/members/OrderBasedPaying/OrderBasedPaying'))
const OrdersAndTransactions = lazy(() => import('src/pages/members/OrdersAndTransactions/OrdersAndTransactions'))

// Using GPT
const SearchDatabase = lazy(() => import('src/components/ChatGPTComponents/SearchDatabase'))
// Using GPT
const AskQuestion = lazy(() => import('src/pages/members/Ask/AskQuestion'))

// Mapping
const Mapping = lazy(() => import('src/pages/members/Mapping/Mapping'))
const MappingInternalOperator = lazy(() => import('src/pages/members/Mapping/MappingInternalOperator'))
const OnboardStatus = lazy(() => import('src/pages/members/OnboardStatus/OnboardStatus'))

// Slack OAuth
const SlackOAuth = lazy(() => import('src/pages/members/SlackOAuth/SlackOAuth'))

// User Profile
const UserProfile = lazy(() => import('src/pages/members/User/UserProfile/UserProfile'))
const UserManagement = lazy(() => import('src/pages/members/User/Mangement/UserManagement'))
// Redirect using url id
const Redirect = lazy(() => import('src/pages/members/Redirect/Redirect'))
// Marketing New UI
const MarketingNew = lazy(() => import('src/pages/members/MarketingNew/Marketing'))
const MarketingCampaignManagement = lazy(() => import('src/pages/members/MarketingCampaignManagement'))
const MarketingCompetitionAnalysis = lazy(() => import('src/pages/members/MarketingCompetitionAnalysis'))
const MarketingEvaluation = lazy(() => import('src/pages/members/MarketingNew/MarketingEvaluation'))
const MarketingTrends = lazy(() => import('src/pages/members/MarketingPages/Trends/Trends'))
const MarketingStoreMetrics = lazy(() => import('src/pages/members/MarketingPages/StoreMetrics/StoreMetrics'))
const MarketingCampaignMetrics = lazy(() => import('src/pages/members/MarketingPages/CampaignMetrics/CampaignMetrics'))
const MarketingConversionMetrics = lazy(() => import('src/pages/members/MarketingPages/ConversionMetrics/ConversionMetrics'))
const MarketingCompetitiveAnalysisItems = lazy(() => import('src/pages/members/MarketingPages/CompetitionAnalysisItems/CompetitionAnalysisItems'))
const MarketingCompetitiveAnalysisStores = lazy(() => import('src/pages/members/MarketingPages/CompetitionAnalysisStores/CompetitionAnalysisStores'))
const MarketingStoresRanking = lazy(() => import('src/pages/members/MarketingPages/StoresRanking/StoresRanking'))
const MarketingCOGS = lazy(() => import('src/pages/members/MarketingNew/Cogs'))

const ReviewsAndRatings = lazy(() => import('src/pages/members/ReviewsAndRatings/ReviewsAndRatings'))
const ReviewsAndRatingsTrends = lazy(() => import('src/pages/members/RatingAndReviewsPages/Trends/Trends'))
const ReviewsAndRatingsStoreRatings = lazy(() => import('src/pages/members/RatingAndReviewsPages/StoreRatings/StoreRatings'))
const ReviewsAndRatingsReviews = lazy(() => import('src/pages/members/RatingAndReviewsPages/Reviews/Reviews'))
const TestPage = lazy(() => import('src/pages/members/Test/TestPage'))
const Benchmarks = lazy(() => import('src/pages/members/Benchmarks/Benchmarks'))
const OloTransactions = lazy(() => import('src/pages/members/OloTransactions/OloTransactions'))

const OloCustomers = lazy(() => import('src/pages/members/OloCustomers/OloCustomers'))

const OloItems = lazy(() => import('src/pages/members/OloItems/OloItems'))

const BankAccounts = lazy(() => import('src/pages/members/OrderBasedPaying/components/BankAccountMappings/BankAccountMappings'))

const AccountingSetup = lazy(() => import('src/pages/members/AccountingSetup/AccountingSetup'))

const AddAccountingSoftware = lazy(() => import('src/pages/members/AccountingSetup/AddAccountingSoftware'))

const AccountingConfigurationV2 = lazy(() => import('src/pages/members/AccountingSetup/AccountingConfigurationV2'))

const AccountingCadence = lazy(() => import('src/pages/members/AccountingSetup/AccountingCadence'))

const AddAccountingCadence = lazy(() => import('src/pages/members/AccountingSetup/AddAccountingCadence'))

const AccountingTemplate = lazy(() => import('src/pages/members/AccountingSetup/AccountingTemplate'))

const AccountingHistory = lazy(() => import('src/pages/members/AccountingSetup/AccountingHistory'))

const AccountingLocationStatus = lazy(() => import('src/pages/members/AccountingSetup/AccountingLocationStatus'))

const DrillDownExample = lazy(() => import('src/pages/members/DrillDownExample/DrillDownExample'))

const ChargebacksDetails = lazy(() => import('src/pages/members/ChargebacksDetails/ChargebacksDetails'))

const Wizard = lazy(() => import('src/pages/members/Wizard/Wizard'))

const TransactionsByStore = lazy(() => import('src/pages/members/TransactionsByStore/TransactionsByStore'))
const Integrations = lazy(() => import('src/pages/members/Integrations/Integrations'))
const OnboardUberEats = lazy(() => import('src/pages/members/Integrations/OnboardUberEats'))

const ReportDownload = lazy(() => import('src/pages/members/Reporting/ReportDownload'))
const CrunchtimeConfiguration = lazy(() => import('src/pages/members/Crunchtime/CrunchtimeConfiguration'))
const PosReport = lazy(() => import('src/pages/members/Pos/PosReport/PosReport'))

const StorePerformanceIntro = lazy(() => import('src/pages/members/StorePerformance/StorePerformanceIntro'))

const StorePerformanceReport = lazy(() => import('src/pages/members/StorePerformance/StorePerformanceReport'))
const StorePerformanceSubscribeUser = lazy(() => import('src/pages/members/StorePerformance/SubscribeUser'))

const StorePerformanceCadence = lazy(() => import('src/pages/members/StorePerformance/StorePerformanceCadence'))

const WhatsNew = lazy(() => import('src/pages/members/Changelog/WhatsNew'))

const Configurations = lazy(() => import('src/pages/members/Configurations/ConfigurationCenter'))
const StoreAvailabilityReasonsConfigs = lazy(() => import('src/pages/members/StoreAvailabilityReasonsConfigs/StoreAvailabilityReasonsConfigs'))

const GuardTrends = lazy(() => import('src/pages/members/StoreAvailability/Navs/Trends'))
const GuardRestoreHistory = lazy(() => import('src/pages/members/StoreAvailability/Navs/RestoreHistory'))
const GuardRealtimeStatus = lazy(() => import('src/pages/members/StoreAvailability/Navs/RealtimeStatus'))
const GuardNotifications = lazy(() => import('src/pages/members/StoreAvailability/Navs/Notifications'))
const GuardNotificationsSlack = lazy(() => import('src/pages/members/StoreAvailability/Navs/NotificationsSlack'))
const GuardHistoryByLostSales = lazy(() => import('src/pages/members/StoreAvailability/Navs/HistoryByLostSales'))
const GuardHistoryByAvgDowntime = lazy(() => import('src/pages/members/StoreAvailability/Navs/HistoryByAvgDowntime'))
const GuardHistoryByRestoreImpact = lazy(() => import('src/pages/members/StoreAvailability/Navs/HistoryByRestoreImpact'))
const GuardMatrix = lazy(() => import('src/pages/members/StoreAvailability/Navs/Matrix'))
const GuardDriverWaitTime = lazy(() => import('src/pages/members/DriverWaitTime/DriverWaitTime'))
const GuardRestoreConfig = lazy(() => import('src/pages/members/GuardRestoreConfig/GuardRestoreConfig'))

// const DrillDown = lazy(() => import('src/pages/members/DrillDown/DrillDown'))
import { capitalize } from 'lodash'
import posthog from 'posthog-js'
import DrillDown from 'src/pages/members/DrillDown/DrillDown'
import { AccessLevelEnum, APP_NAME, APP_VERSION } from 'src/utils/config/config'
import { getAccessLevel } from 'src/utils/functions/accessLevel'

const PrivateRouter = React.memo(() => {
  const location = useLocation()
  const { generateStandaloneRoutes } = useStandaloneRouteData()
  const { setPrivateRoutes, routes } = usePrivateLayoutDrawerContext()
  const params = new URLSearchParams(location.search)
  const redirectLink = params.get('redirect')
  const [filteredRouteList, setFilteredRouteList] = useState<RouteObject[]>([])
  const { getFilters, dateRange, refreshFilters } = useFilter()
  const { state } = useLocation()
  const { currentUser, googleStudioLinks, supersetLinks, orgConfig, globalRoutes, getApiData, logout, hasMagicLink, setStandaloneRoutesLoaded, getRoutes } =
    useAuth()

  const navigateLink = React.useMemo(() => {
    if (currentUser && get(currentUser, 'stage') === 'waiting' && !hasMagicLink()) {
      return '/create-account/wait'
    }

    if (redirectLink) {
      return redirectLink
    }

    return 'home'
  }, [redirectLink, currentUser])

  React.useEffect(() => {
    setPrivateRoutes(filteredRouteList)
  }, [filteredRouteList])

  React.useEffect(() => {
    let unsubscribe
    if (currentUser) {
      const query = doc(firebaseDb, `users/${currentUser.uid}`)
      const q = doc(firebaseDb, `org_config_2/${currentUser.org}`)
      unsubscribe = onSnapshot(query, async (snapShot) => {
        if (!snapShot.data()) logout()

        const tempUser = getLocal('data')
        if (tempUser) {
          const localSessionVersion = get(tempUser, 'session_version', 0)
          const localFilterVersion = get(tempUser, 'filter_version', 0)
          const localLogoutVersion = get(tempUser, 'logout_version', 0)
          const tempCurrentUser = snapShot.data()
          const sessionVersion = get(tempCurrentUser, 'session_version', 0)
          const filterVersion = get(tempCurrentUser, 'filter_version', 0)
          const logoutVersion = get(tempCurrentUser, 'logout_version', 0)

          if (sessionVersion.toString() !== localSessionVersion.toString()) {
            // refresh session
            const data = await fetchUserOrgAndRole(currentUser.uid)
            await getApiData(currentUser, data)
            refreshFilters(currentUser.uid, currentUser.org)
            sendSlackNotification({
              message: `SESSION REFRESHED FROM ADMIN: for ${currentUser.email} of ${currentUser.org};`,
              channel: 'fe-logs',
              title: `SESSION REFRESHED FROM ADMIN`
            })
          }
          if (filterVersion.toString() !== localFilterVersion.toString()) {
            // refresh filter
            refreshFilters(currentUser.uid, currentUser.org)
            sendSlackNotification({
              message: `FILTER REFRESHED FROM ADMIN: for ${currentUser.email} of ${currentUser.org};`,
              channel: 'fe-logs',
              title: `FILTER REFRESHED FROM ADMIN`
            })
          }
          if (logoutVersion.toString() !== localLogoutVersion.toString()) {
            // logout user
            logout()
            sendSlackNotification({
              message: `USER LOGGED OUT FROM ADMIN: for ${currentUser.email} of ${currentUser.org};`,
              channel: 'fe-logs',
              title: `USER LOGGED OUT FROM ADMIN`
            })
          }
        }
      })
    }

    return () => {
      unsubscribe && unsubscribe()
    }
  }, [currentUser])

  const googleStudioRoutes = googleStudioLinks.map((link) => ({
    path: link.key,
    element: <StudioContainer dashboardLink={link.url} />
  }))
  const privateRouteList: RouteObject[] = [
    {
      path: '/test-internal',
      element: <TestPage />
    },
    {
      path: 'list-orders',
      element: (
        <Navigate
          to="/list-orders/all"
          replace
        />
      )
    },
    { path: 'list-orders/all', element: <OrdersOld status="all" /> },
    {
      path: 'list-orders/cancelled',
      element: <OrdersOld status="cancelled" />
    },
    {
      path: 'list-orders/inaccurate',
      element: <OrdersOld status="inaccurate" />
    },
    { path: 'list-orders/missed', element: <OrdersOld status="missed" /> },
    { path: 'list-orders/latest', element: <Orders status="all" /> },
    {
      path: 'list-orders/latest/cancelled',
      element: <Orders status="cancelled" />
    },
    {
      path: 'list-orders/latest/inaccurate',
      element: <Orders status="inaccurate" />
    },
    { path: 'list-orders/latest/missed', element: <Orders status="missed" /> },
    {
      path: 'errors',
      element: <Navigate to={`/errors/overview${window.location.search}`} />
    },
    { path: 'errors/overview', element: <Errors /> },
    { path: 'errors/inaccurate-orders', element: <InAccurateOrders /> },
    { path: 'errors/inaccurate-orders/:filter', element: <InAccurateOrders /> },
    { path: 'errors/missed-orders', element: <MissedOrders /> },
    { path: 'errors/missed-orders/:filter', element: <MissedOrders /> },
    { path: 'errors/cancelled-orders', element: <CancelledOrders /> },
    { path: 'errors/cancelled-orders/:filter', element: <CancelledOrders /> },
    {
      path: 'errors/issue-discovery/:tab/:filter',
      element: <IssueDiscovery />
    },
    {
      path: 'errors/issue-discovery/:tab/:filter/:filterName',
      element: <IssueDiscoveryWithFilter />
    },
    {
      path: 'issue-discovery/:tab/:filter',
      element: (
        <Navigate
          to="/errors/issue-discovery/discovery/by-reasons"
          replace
        />
      )
    },
    {
      path: 'issue-discovery/:tab/:filter/:filterName',
      element: (
        <Navigate
          to="/errors/issue-discovery/discovery/by-reasons"
          replace
        />
      )
    },
    {
      path: 'dashboard',
      element: <SalesOverview />
    },
    { path: 'dashboard/v1/trends', element: <DashboardTrendsOnly /> },
    { path: 'dashboard/v1/metrics', element: <DashboardMetricsOnly /> },
    {
      path: 'dashboard/v1/error/overview',
      element: <DashboardErrorOverview />
    },
    { path: 'dashboard/v1/sales', element: <DashboardSales /> },
    { path: 'dashboard/v1/rating/trends', element: <DashboardRatingTrends /> },
    { path: 'dashboard/benchmarks', element: <Benchmarks /> },
    // { path: "finance", element: <Finance /> },
    // { path: "finance/summary", element: <Finance /> },
    // { path: "finance/breakdown", element: <FinanceBreakdown /> },
    { path: 'finance/bank/integration', element: <PlaidAuth /> },
    { path: 'reviews/summary', element: <Reviews /> },
    { path: 'reviews/summary/generate', element: <Reviews /> },
    // { path: 'marketing/new', element: <Marketing /> },
    { path: 'marketing/campaign/single', element: <SingleCampaignAllStores /> },
    { path: 'marketing/waiting', element: <WaitingScreen /> },
    {
      path: 'marketing/campaignVBs',
      element: (
        <VbCampaignAllStores
          getFilters={getFilters}
          dateRange={dateRange}
          state={state}
        />
      )
    },
    {
      path: 'item-performance/details/:storeName/:itemName',
      element: <SpecificItemPerformance />
    },
    { path: 'item-performance/item-view', element: <ItemPerformance /> },
    { path: 'item-performance', element: <ItemPerformancePage /> },
    {
      path: 'guard/trends',
      element: <GuardTrends />
    },
    {
      path: 'guard/restore/history',
      element: <GuardRestoreHistory />
    },
    {
      path: 'guard/restore/matrix',
      element: <GuardMatrix />
    },
    {
      path: 'guard/realtime/status',
      element: <GuardRealtimeStatus />
    },
    {
      path: 'guard/configurations/locations',
      element: <Configurations />
    },
    {
      path: 'guard/configurations/reasons',
      element: <GuardRestoreConfig />
    },
    {
      path: 'guard/notifications/slack',
      element: <GuardNotificationsSlack />
    },
    {
      path: 'guard/notifications/email_sms',
      element: <GuardNotifications />
    },
    {
      path: 'guard/history/lost_sales',
      element: <GuardHistoryByLostSales />
    },
    {
      path: 'guard/history/average_downtime',
      element: <GuardHistoryByAvgDowntime />
    },
    {
      path: 'guard/history/restore_impact',
      element: <GuardHistoryByRestoreImpact />
    },
    {
      path: 'guard/driver-wait-time',
      element: <GuardDriverWaitTime />
    },
    { path: 'integration-status', element: <IntegrationStatus /> },
    {
      path: 'datastudio/e04eb29020eaa961e99d3162635e9fe9585c5a1121bd88784c1378aa8837195c=bills',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/77268316-60f5-49cd-961f-46d13f300425/page/FNhwC?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'datastudio/e04eb29020eaa961e99d3162635e9fe9585c5a1121bd88784c1378aa8837195c=bills/overview',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/c4a08a5e-8b45-401c-af16-11b0f35ef950/page/p_l9git8fswc?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'datastudio/e04eb29020eaa961e99d3162635e9fe9585c5a1121bd88784c1378aa8837195c=bills/by-brick-and-mortar',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/c4a08a5e-8b45-401c-af16-11b0f35ef950/page/p_l9git8fswc?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'datastudio/e04eb29020eaa961e99d3162635e9fe9585c5a1121bd88784c1378aa8837195c=bills/by-state-pin',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/c4a08a5e-8b45-401c-af16-11b0f35ef950/page/p_xsbju44n1c?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'datastudio/630a7a053e909af966de42f7248edf9fa2b899f1466b30f669730353799651cf=store_mapping',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/93dab593-0721-433d-80a3-2d6b9c4245fd/page/rM1CD?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'campaign-performance',
      element: (
        <StudioContainer
          parameterized
          dashboardLink={`https://lookerstudio.google.com/embed/u/0/reporting/e0d607a4-71dc-4931-9260-151848ecaad4/page/p_8o0qn3sy5c?params=%7B%22ds0.chain%22:%22${currentUser?.org}%22%7D`}
        />
      )
    },
    { path: 'visuals', element: <Visuals /> },
    { path: 'landing', element: <Landing /> },
    { path: 'shift', element: <Shift /> },
    { path: 'shift/leaderboard', element: <ShiftLeaderboard /> },
    { path: 'choose-platform', element: <Chooseplateform /> },
    { path: 'process', element: <Process /> },
    { path: 'upload-csv', element: <Uploadcsv /> },
    { path: 'upload-csv-detail', element: <UploadCsvDetail /> },
    {
      path: 'finance/datastudio/e39da5b66920a888d643f6fd1d3f178e597d8d5c0d5b9b0823ac727c963ed20a=modelling',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/a1fb2d63-03a7-4193-810e-daa901b4ce4d/page/X4qDD?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'datastudio/e2a530e251d3675034d23f5c5f87f54ec3182a088ba7d13350824794f8e6b76e=marketing',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/3fb6edf7-73cd-4797-9dfc-95f8404d2cf4/page/afCyC?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'datastudio/095833f804496246e7780a988c6a88e88e5f8ae981f292658749f963b5e6e632=item_performance',
      element: (
        <StudioContainer
          dashboardLink={`https://datastudio.google.com/embed/reporting/6552dbee-390c-4a34-acd0-03ac3e915661/page/p_3dfcoep0zc?params={"chain_parameter_id":"${currentUser?.org}"}`}
        />
      )
    },
    {
      path: 'datastudio/e2a530e251d3675034d23f5c5f87f54ec3182a088ba7d13350824794f8e6b76e=marketing/adperformance',
      element: <StudioContainer dashboardLink={`https://ad-dashboard-ul5ne76yva-uc.a.run.app/`} />
    },
    {
      path: 'datastudio/095833f804496246e7780a988c6a88e88e5f8ae981f292658749f963b5e6e632=item_performance/salad-searcher',
      element: <StudioContainer dashboardLink={`https://salad-searcher-ul5ne76yva-uc.a.run.app/`} />
    },
    { path: 'single', element: <SingleCampaignAllStores /> },
    { path: 'waiting', element: <WaitingScreen /> },
    { path: 'chargebacks', element: <Chargebacks tabValue="overview" /> },
    {
      path: 'chargebacks/overview',
      element: <Chargebacks tabValue="overview" />
    },
    { path: 'chargebacks/transactions', element: <ChargebacksTransactions /> },
    {
      path: 'chargebacks/payouts',
      element: (
        <Navigate
          replace
          to="/chargebacks/transactions"
        />
      )
    },
    {
      path: 'chargebacks/stores/view',
      element: <ChargebacksHistoryStoreView />
    },
    {
      path: 'chargebacks/matrix',
      element: <ChargebacksDetails />
    },
    { path: 'shift', element: <Shift /> },
    { path: 'onboard/data', element: <Mapping /> },
    { path: 'onboard/status', element: <OnboardStatus /> },
    { path: 'loop-store-mapping', element: <MappingInternalOperator /> },

    // { path: 'order/based/payouts', element: <FinancePayouts /> },
    { path: 'order/based/payouts', element: <OrderBasedPaying /> },
    { path: 'order/based/reports', element: <OrderBasedPaying /> },
    { path: 'order/based/payouts/list', element: <ListOfPayouts /> },
    {
      path: 'order/based/by_store',
      element: <OrderBasedPaying tab="by_store" />
    },
    {
      path: 'reconciliation/bank_reconciliation',
      element: <OrderBasedPaying tab="bank_reconciliation" />
    },
    {
      path: 'order/based/by_store',
      element: <BankReconciliation />
    },
    // { path: 'order/based/transactions', element: <OrdersAndTransactions /> },
    { path: 'reconciliation/order/based/transactions', element: <OrdersAndTransactions /> },
    { path: 'reconciliation/order/based/transactions/non_variance_zero', element: <OrdersAndTransactions show_only_non_variance_zero /> },
    { path: 'order/based/overview', element: <FinancePayouts /> },
    { path: 'mapping', element: <Mapping /> },
    { path: 'marketing/new', element: <MarketingNew /> },
    {
      path: 'marketing/campaign/management',
      element: <MarketingCampaignManagement />
    },
    {
      path: 'marketing/competition/analysis',
      element: <MarketingCompetitionAnalysis />
    },
    {
      path: 'marketing/evaluation',
      element: <MarketingEvaluation />
    },
    {
      path: 'marketing/trends',
      element: <MarketingTrends />
    },
    {
      path: 'marketing/cogs',
      element: <MarketingCOGS />
    },
    {
      path: 'marketing/store_metrics',
      element: <MarketingStoreMetrics />
    },
    {
      path: 'marketing/campaign_metrics',
      element: <MarketingCampaignMetrics />
    },
    {
      path: 'marketing/conversion_metrics',
      element: <MarketingConversionMetrics />
    },
    {
      path: 'marketing/competition_analysis_items',
      element: <MarketingCompetitiveAnalysisItems />
    },
    {
      path: 'marketing/competition_analysis_stores',
      element: <MarketingCompetitiveAnalysisStores />
    },
    {
      path: 'marketing/stores_ranking',
      element: <MarketingStoresRanking />
    },
    {
      path: 'benchmarks/loop-benchmarks',
      element: <Benchmarks />
    },
    { path: 'accounting-portal/:tab', element: <AccountingPortal /> },
    {
      path: 'order/based/accounting-portal',
      element: (
        <Navigate
          to="/bookkeeping/gl-entries"
          replace
        />
      )
    },
    { path: 'codat/link', element: <AccountingPortal /> },
    { path: 'mapping', element: <Mapping /> },
    { path: 'reviews/ratings', element: <ReviewsAndRatings /> },
    { path: 'reviews/ratings/trends', element: <ReviewsAndRatingsTrends /> },
    { path: 'reviews/ratings/store_ratings', element: <ReviewsAndRatingsStoreRatings /> },
    { path: 'reviews/ratings/reviews', element: <ReviewsAndRatingsReviews /> },
    // { path: 'benchmarks', element: <Benchmarks /> },
    { path: 'olo/transactions', element: <OloTransactions /> },
    { path: 'olo/customers', element: <OloCustomers /> },
    { path: 'olo/items', element: <OloItems /> },
    { path: 'order/based/plaid/accounts', element: <BankAccounts /> },
    { path: 'bookkeeping/gl-entries', element: <AccountingPortal /> },
    { path: 'bookkeeping/add-bank', element: <BankAccounts /> },
    {
      path: 'bookkeeping/integrations',
      element: <AccountingSetup />
    },
    {
      path: 'bookkeeping/integrations/add',
      element: <AddAccountingSoftware />
    },
    { path: 'bookkeeping/map-gl', element: <AccountingConfigurationV2 /> },
    { path: 'accounting/setup', element: <AccountingSetup /> },
    { path: 'accounting/configuration', element: <AccountingConfigurationV2 /> },
    { path: 'order/based/transactions/by/store', element: <TransactionsByStore /> },
    { path: 'bookkeeping/cadence', element: <AccountingCadence /> },
    {
      path: 'bookkeeping/cadence/add',
      element: <AddAccountingCadence />
    },
    {
      path: 'bookkeeping/templates',
      element: <AccountingTemplate />
    },
    { path: 'loop-reports', element: <ReportDownload /> },
    { path: 'pos/crunchtime/configuration', element: <CrunchtimeConfiguration /> },
    { path: 'pos/reports', element: <PosReport /> },
    { path: 'bookkeeping/history', element: <AccountingHistory /> },
    { path: 'bookkeeping/history/internal', element: <AccountingHistory /> },
    { path: 'bookkeeping/location/status', element: <AccountingLocationStatus /> },
    { path: 'breakdown/example', element: <DrillDownExample /> },

    { path: 'store-performance/intro', element: <StorePerformanceIntro /> },
    { path: 'store-performance/report-type', element: <StorePerformanceReport /> },
    { path: 'store-performance/subscribe-user', element: <StorePerformanceSubscribeUser /> },
    { path: 'store-performance/report-cadence', element: <StorePerformanceCadence /> },
    { path: 'pos/card-reconciliation', element: <CardReconciliation /> },
    { path: 'pos/third-party-reconciliation', element: <ThirdPartyReconciliation /> },
    { path: 'pos/third-party-orders-reconciliation', element: <ThirdPartyOrdersReconciliation /> },
    { path: 'sandboxbookkeeping/gl-entries', element: <AccountingPortal /> },
    {
      path: 'sandboxbookkeeping/integrations',
      element: <AccountingSetup />
    },
    {
      path: 'sandboxbookkeeping/integrations/add',
      element: <AddAccountingSoftware />
    },
    { path: 'sandboxbookkeeping/map-gl', element: <AccountingConfigurationV2 /> },
    { path: 'sandboxbookkeeping/location/status', element: <AccountingLocationStatus /> },
    {
      path: 'list-orders',
      element: (
        <Navigate
          to="/list-orders/all"
          replace
        />
      )
    },
    {
      path: 'all-orders/cancelled',
      element: <OrdersOld status="cancelled" />
    },
    {
      path: 'all-orders/inaccurate',
      element: <OrdersOld status="inaccurate" />
    },
    { path: 'all-orders/missed', element: <OrdersOld status="missed" /> },
    { path: 'all-orders/latest', element: <Orders status="all" /> },
    {
      path: 'all-orders/latest/cancelled',
      element: <Orders status="cancelled" />
    },
    {
      path: 'all-orders/latest/inaccurate',
      element: <Orders status="inaccurate" />
    },
    { path: 'all-orders/latest/missed', element: <Orders status="missed" /> },
    {
      path: 'order-accuracy',
      element: <Navigate to={`/order-accuracy/overview${window.location.search}`} />
    },
    { path: 'order-accuracy/overview', element: <Errors /> },
    { path: 'order-accuracy/inaccurate-orders', element: <InAccurateOrders /> },
    { path: 'order-accuracy/inaccurate-orders/:filter', element: <InAccurateOrders /> },
    { path: 'order-accuracy/missed-orders', element: <MissedOrders /> },
    { path: 'order-accuracy/missed-orders/:filter', element: <MissedOrders /> },
    { path: 'order-accuracy/cancelled-orders', element: <CancelledOrders /> },
    { path: 'order-accuracy/cancelled-orders/:filter', element: <CancelledOrders /> },
    { path: 'drill-down/:name', element: <DrillDown /> },
    { path: 'changelog', element: <WhatsNew /> },
    { path: 'configurations', element: <Configurations /> },
    { path: 'configurations/store-availability', element: <Configurations /> },
    { path: 'configurations/store-availability/reasons', element: <StoreAvailabilityReasonsConfigs /> },
    {
      path: 'configurations/chargebacks/categories',
      element: <ChargebackDisputeConfigs />
    },
    {
      path: 'configurations/chargebacks',
      element: <ChargebackConfigs />
    }
  ]

  const formatRoute = async () => {
    if (orgConfig && currentUser) {
      try {
        const navConfigKeyArr = []
        const temporatyRouteList = []
        const accessLevel = getAccessLevel(currentUser)
        let findAccessLevel = Object.keys(orgConfig.access_levels)
        const tempAccessLevel =
          findAccessLevel.includes(accessLevel) && !findAccessLevel.includes(AccessLevelEnum.DEMO) ? accessLevel : AccessLevelEnum.BUSINESS_ADMIN
        let accessRoutes = getRoutes(tempAccessLevel)

        const defaultRoutes = get(orgConfig, 'navConfig', [])
        const filterRoutes = accessRoutes.length > 0 ? accessRoutes : defaultRoutes
        if (filterRoutes) {
          filterRoutes.forEach((item) => {
            if (typeof item === 'string') {
              navConfigKeyArr.push(item)
            } else if (typeof item === 'object') {
              navConfigKeyArr.push(item.uid)
            }
          })
        }
        privateRouteList.forEach((item) => {
          const isPresent = currentUser?.internal || isRouteExist(item.path, navConfigKeyArr)
          if (isPresent) {
            temporatyRouteList.push(item)
          }
        })
        // const standaloneRoutes = await generateStandaloneRoutes(navConfigKeyArr)
        setFilteredRouteList([...temporatyRouteList])

        generateStandaloneRoutes(navConfigKeyArr).then((standaloneRoutes) => {
          // Update filteredRouteList again when standaloneRoutes is ready
          setFilteredRouteList((prevRoutes) => [...prevRoutes, ...standaloneRoutes])
          setStandaloneRoutesLoaded(true)
        })
      } catch (error: any) {
        // YOU CAN IGNORE THIS ERROR
        // BECAUSE THIS IS TOTALLY NEW USER
        // AND USER DOESN'T HAVE ANY ACCESS LEVEL
      }
    }
  }

  useEffect(() => {
    formatRoute()
  }, [orgConfig, currentUser, routes])

  useEffect(() => {
    if (currentUser?.email) {
      window.dataLayer = window.dataLayer || []
      const { uid, email, phoneNumber, org, 'access-level': accessLevel, name: fullName, photoURL: avatar } = currentUser

      const user = {
        id: uid,
        signup_date: Date.now() / 1000,
        userId: uid,
        fullName: capitalize(fullName),
        email,
        phoneNumber,
        company: org,
        accessLevel,
        avatar
      }

      window.dataLayer.push(user)
      window.loop = {
        user,
        meta: {
          name: APP_NAME,
          version: APP_VERSION
        }
      }

      posthog.identify(user.email, { ...window.loop.meta, ...window.loop.user })
      posthog.capture('$pageview', { ...window.loop.meta, ...window.loop.user })
      posthog.group('company', user.company, { ...window.loop.meta, ...window.loop.user })
      posthog.register({ email: user.email })
    }
  }, [currentUser, location.pathname, orgConfig])

  const flatGlobalRoutes = React.useMemo(() => {
    return globalRoutes.reduce(
      (acc, route) => {
        if (route.subNav) {
          route.subNav.forEach((subRoute) => {
            if (subRoute.key) {
              if (subRoute.key[0] === '/') {
                acc.push({ key: subRoute.key, uid: route.uid })
              } else {
                const tempKey = `${route.key}/${subRoute.key}`
                const key = tempKey?.startsWith('/') ? tempKey : '/' + tempKey
                acc.push({ key, uid: route.uid })
              }
            }
          })
        } else {
          const key = route.key?.startsWith('/') ? route.key : '/' + route.key
          acc.push({ key, uid: route.uid })
        }

        return acc
      },
      [] as { key: string; uid: string }[]
    )
  }, [globalRoutes])

  const noAccess = React.useMemo(() => {
    if (currentUser && orgConfig) {
      // debugger
      try {
        const accessLevel = getAccessLevel(currentUser)
        const compareRoutes = getRoutes(accessLevel)
          .map((route) => {
            const routeUid = typeof route === 'string' ? route : route.uid
            const routeObj = globalRoutes.find((e) => e.uid === routeUid)
            return routeObj?.key
          })
          .filter((e) => !!e)

        if (location.pathname === '/' || !compareRoutes.includes(location.pathname)) {
          return false
        }
      } catch (error) {
        console.error("Error in PrivateRouter's noAccess", error)
      }
      return false
    }
    return true
  }, [currentUser, orgConfig, flatGlobalRoutes, globalRoutes, location.pathname])

  const flatRoute = React.useMemo(() => {
    const flatRoutes = routes
      .filter((e) => !!e)
      .map((route) => {
        const arr: string[] = []
        if ('subNav' in route && Array.isArray(route['subNav']) && route['subNav'].length > 0) {
          let subArr: string[] = route['subNav']
            .filter((subNav) => 'key' in subNav && typeof subNav.key === 'string')
            .map((subNav) => (subNav.key[0] === '/' ? subNav.key.slice(1) : `${route.key}/${subNav.key}`))
          arr.push(...subArr)
        } else {
          arr.push(route.key)
        }
        return arr
      })
      .reduce((prev, curr) => [...prev, ...curr], [])
    return flatRoutes
  }, [routes])

  const supersetRoutes = React.useMemo(() => {
    return supersetLinks.map((item) => {
      const route = routes.find(
        (e) =>
          e.key === item.key ||
          (e &&
            Array.isArray(e.subNav) &&
            e.subNav.some((subNav) => (subNav?.key?.startsWith('/') ? subNav?.key === item?.key : `${e.key}/${subNav?.key}` === item.key)))
      )
      return {
        path: item.key,
        element: (
          <SupersetDashboard
            title={get(route, 'label', null)}
            dashboard_id={item.dashboard_id}
            // dashboardLink={`${link.url}?params={"chain_parameter_id":"${currentUser.org}"}`}
          />
        )
      }
    })
  }, [supersetLinks, routes])

  const isRouteExist = (route, array) => {
    const path = flatRoute.find((e) => {
      const match = matchPath({ path: route, caseSensitive: false }, `/${e}`)
      return match
    })
    return !!path
  }

  const privateRoutes = React.useMemo(() => {
    return [
      {
        path: '/',
        element: <PrivateLayout noAccess={noAccess} />,
        children: [
          ...googleStudioRoutes,
          ...supersetRoutes,
          ...filteredRouteList,
          { path: 'wizard/:wizardID', element: <Wizard /> }, // to be shifted to privateRoute array when there functionality of enabling a route without displaying to side bar
          { index: true, element: <Navigate to={navigateLink} /> },
          { path: 'home', element: <HomePage /> },
          { path: 'data-refresh-status', element: <DataRefreshStatus /> },
          // { path: 'dashboard-main', element: <Dashboardmain /> },
          { path: 'owner-details', element: <OwnerDetails /> },
          { path: 'link-phone', element: <LinkAuthProvider /> },
          { path: 'sentry-error-test', element: <SentryError /> },
          { path: 'connect/slack', element: <SlackOAuth /> },
          { path: 'user/profile', element: <UserProfile /> },
          { path: 'user/management', element: <UserManagement /> },
          {
            path: 'notification-settings',
            element: <NotificationCenter />
          },
          { path: 'integrations', element: <Integrations /> },
          { path: 'onboard/uber', element: <OnboardUberEats /> },
          {
            path: 'access-denied',
            element: (
              <NotFound
                to={navigateLink}
                blockedOnDemo
              />
            )
          },
          {
            path: '*',
            element: <NotFound to={navigateLink} />
          }
        ]
      },
      { path: 'create-account/wait', element: <WaitingPage /> },
      { path: '/ask', element: <AskQuestion /> },
      { path: 'search', element: <SearchDatabase /> },
      { path: 'redirect', element: <Redirect /> },
      { path: 'video', element: <VideoUpload /> },
      { path: 'video/:order', element: <DisputableOrders /> },
      ...generalRoutes,
      {
        path: 'access-denied',
        element: (
          <NotFound
            to={navigateLink}
            blockedOnDemo
          />
        )
      },
      {
        path: '*',
        element: <NotFound to={navigateLink} />
      }
    ]
  }, [googleStudioLinks, generalRoutes, filteredRouteList])

  const newRoutes = useSentryRoutes(privateRoutes)

  if (currentUser && currentUser.stage === 'waiting') {
    return <>{newRoutes}</>
  }

  return <>{filteredRouteList.length > 0 ? newRoutes : <Loader loading />}</>
})

export default PrivateRouter
