import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import InfoIcon from '@mui/icons-material/Info'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import { Box, Card, CardContent, Skeleton, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useFilter } from 'src/context/FilterContext'
import ErrorRateMessage from './ErrorRateMessages'
import StyledTypo from './StyledTypo'

export interface DeltaDateRangeType {
  previous_start_date: string | Date
  previous_end_date: string | Date
}

interface ExtraFields {
  label: string
  value?: string | number
  subValue?: string | number
  labelSx?: SxProps
  valueSx?: SxProps
  subValueSx?: SxProps
}

export interface DeltaCardProps {
  title?: string
  subTitle?: string | JSX.Element
  subTitlePosition?: 'right' | 'bottom'
  showSubTitleTooltip?: boolean
  label: string | JSX.Element
  labelTooltip?: string
  subLabel?: string | JSX.Element
  delta?: number
  dateRange?: DeltaDateRangeType
  deltaReversed?: boolean
  titleColor?: string
  // messageComp?: JSX.Element,
  extraComp?: JSX.Element

  mainTitle?: string
  showArrow?: boolean
  removeMaxWidth?: boolean
  removeBorder?: boolean
  bigFont?: boolean
  height?: string | number
  loading?: boolean
  sx?: SxProps<Theme>
  sxLabel?: SxProps<Theme>
  sxTitle?: SxProps<Theme>
  extraFields?: ExtraFields[]
  title2?: string
  subTitle2?: string | JSX.Element
  subTitlePosition2?: 'right' | 'bottom'
  label2?: string | JSX.Element
  labelTooltip2?: string
  subLabel2?: string
  showSubTitleTooltip2?: boolean
  delta2?: number
  dateRange2?: DeltaDateRangeType
  deltaReversed2?: boolean
  rangeText?: string
  route?: string
  icon?: JSX.Element
  // messageComp2?: JSX.Element,
  extraComp2?: JSX.Element
  type?: string
  message?: string

  handleCardClick?: () => void
}

function SkeletonDeltaCard({ isMarketPlace = false }: { isMarketPlace?: boolean }) {
  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        padding: 16,
        border: '1px solid rgba(0,0,0,0.1)'
      }}>
      <Skeleton
        variant="text"
        width="90%"
        height="30px"
        sx={{ my: 1 }}
      />
      <Skeleton
        variant="text"
        width="80%"
        height="30px"
        sx={{ my: 1 }}
      />
      <Skeleton
        variant="text"
        width="80%"
        height="30px"
        sx={{ my: 1 }}
      />
      <Skeleton
        variant="text"
        width="40%"
        height="20px"
        sx={{ my: 1, mb: 3 }}
      />
      {isMarketPlace && (
        <>
          <Box
            style={{ display: 'flex' }}
            sx={{ my: 1 }}>
            <Skeleton
              variant="text"
              width="20px"
              height="20px"
              sx={{ mr: 2 }}
            />
            <Skeleton
              variant="text"
              width="calc(100% - 20px)"
              height="20px"
            />
          </Box>
          <Box
            style={{ display: 'flex' }}
            sx={{ my: 1 }}>
            <Skeleton
              variant="text"
              width="20px"
              height="20px"
              sx={{ mr: 2 }}
            />
            <Skeleton
              variant="text"
              width="calc(100% - 20px)"
              height="20px"
            />
          </Box>
          <Box
            style={{ display: 'flex' }}
            sx={{ my: 1 }}>
            <Skeleton
              variant="text"
              width="20px"
              height="20px"
              sx={{ mr: 2 }}
            />
            <Skeleton
              variant="text"
              width="calc(100% - 20px)"
              height="20px"
            />
          </Box>
        </>
      )}
    </div>
  )
}

function DeltaCard(props: DeltaCardProps) {
  const { dateRange } = useFilter()
  const navigate = useNavigate()
  const formatDate = (date: string | Date) => {
    return moment(date).format('DD MMM')
  }
  const handleClick = (e) => {
    if (props.handleCardClick) {
      return props.handleCardClick()
    }
    if (props.route) {
      navigate(props.route)
    }
  }
  const cardLayout = (
    label: string | JSX.Element,
    title: string,
    obj: {
      labelTooltip?: string
      dateRange?: DeltaDateRangeType
      delta?: string | number
      deltaReveised?: boolean
      subTitle?: string | JSX.Element
      subLabel?: string | JSX.Element
      rangeText?: string
      extraComp?: JSX.Element
      showSubTitleTooltip?: boolean
      subTitlePosition?: typeof props.subTitlePosition
    },
    sxLabel?: SxProps<Theme>,
    sxTitle?: SxProps<Theme>,
    extraFields?: ExtraFields[],
    loading?: boolean
  ) => {
    let deltaDownColors = {
      background: '#FFE5E5',
      color: 'red'
    }
    let deltaUpColors = {
      background: '#E1FFB1',
      color: 'green'
    }
    const deltaZero = {
      background: 'rgba(128,128,128,0.25)',
      color: '#000'
    }
    if (obj.deltaReveised) {
      const obj = { ...deltaDownColors }
      deltaDownColors = { ...deltaUpColors }
      deltaUpColors = { ...obj }
    }

    const subTitleJSX = obj.subTitle ? (
      <Box
        display="inline-flex"
        alignItems="center"
        ml={obj.subTitlePosition === 'right' ? 1 : 0}>
        <Typography
          sx={{
            fontWeight: 600,
            lineHeight: '1em',
            fontSize: '1.2rem',
            color: '#000'
          }}>
          {obj.subTitle}
        </Typography>
        {obj.showSubTitleTooltip && (
          <Tooltip
            title="Available for payout, it can take upto 7 days for this to be transferred to your bank account"
            placement="right"
            componentsProps={{
              tooltip: {
                sx: {
                  bgcolor: 'white',
                  color: 'black',
                  boxShadow: '1px 2px 3px rgba(0,0,0,0.5)'
                }
              }
            }}>
            <InfoIcon
              fontSize="small"
              color="secondary"
            />
          </Tooltip>
        )}
      </Box>
    ) : null

    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', flex: 1, width: '100%' }}>
        <Box
          height="100%"
          display="flex"
          flexDirection="column"
          alignItems="stretch"
          flex={1}>
          <Box
            padding="0.7rem 0.7rem"
            flex={1}
            onClick={handleClick}
            sx={{ '&:hover': { cursor: props.route || props.handleCardClick ? 'pointer' : 'auto' } }}
            display="flex"
            flexDirection="column">
            <Box
              display="flex"
              alignItems="flex-start"
              sx={{ color: '#000' }}>
              <Stack
                direction="row"
                alignItems="flex-start"
                gap="5px"
                sx={{ flex: 1 }}>
                {loading ? (
                  <Skeleton
                    variant="text"
                    sx={{ ...sxLabel }}
                    width="90%"
                    height={17}
                  />
                ) : (
                  <Typography
                    id={title}
                    sx={{
                      fontSize: '0.9rem',
                      fontWeight: props.mainTitle ? 600 : 700,
                      lineHeight: '1.2em',
                      display: '-webkit-box',
                      WebkitBoxOrient: 'vertical',
                      boxOrient: 'vertical',
                      WebkitLineClamp: 2,
                      lineClamp: 2,
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      ...sxLabel
                    }}>
                    {label}
                  </Typography>
                )}
                {obj.labelTooltip && (
                  <Tooltip
                    title={obj.labelTooltip}
                    placement={'top'}
                    arrow>
                    <Box
                      sx={{
                        display: 'flex',
                        fontSize: '14px',
                        color: '#666'
                      }}>
                      <InfoIcon
                        fontSize="inherit"
                        color="inherit"
                      />
                    </Box>
                  </Tooltip>
                )}
              </Stack>
              {props.showArrow && <ChevronRightIcon />}
            </Box>

            <Box marginBottom={subTitleJSX ? 0 : '0.7rem'}>
              {loading ? (
                <Skeleton
                  variant="text"
                  width="70%"
                  height="30px"
                />
              ) : (
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontSize: props.type ? '32px' : props.bigFont ? '2rem' : '1rem',
                    color: props.type ? 'green' : props.titleColor || 'inherit',
                    ...sxTitle
                  }}
                  title={title}>
                  {title}
                  {obj.subTitlePosition === 'right' && subTitleJSX}
                </Typography>
              )}

              {obj.subTitlePosition !== 'right' && subTitleJSX}
            </Box>
            {obj.subLabel &&
              (loading ? (
                <Skeleton
                  variant="text"
                  width="40%"
                  height="20px"
                />
              ) : (
                <Typography
                  variant="caption"
                  color="rgba(0,0,0,0.5)"
                  component="p">
                  {obj.subLabel}
                </Typography>
              ))}
            {Boolean(extraFields) &&
              (loading ? (
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {extraFields.map((field, index) => (
                    <Box
                      width="100%"
                      key={field.label + index}>
                      <Skeleton
                        variant="text"
                        sx={{ ...field.labelSx }}
                        width="80%"
                      />
                      {field.value && (
                        <Skeleton
                          variant="text"
                          sx={{ ...field.valueSx }}
                          width="70%"
                        />
                      )}
                      {field.subValue && (
                        <Skeleton
                          variant="text"
                          sx={{ ...field.subValueSx }}
                          width="60%"
                          height="20px"
                        />
                      )}
                    </Box>
                  ))}
                </Box>
              ) : (
                <Box sx={{ display: 'flex', justifyContent: 'space-between', gap: 1 }}>
                  {extraFields.map((field, index) => (
                    <Box key={field.label + index}>
                      <StyledTypo
                        fs={12}
                        fw={400}
                        sx={{ mb: 0.7, color: 'rgba(0,0,0,0.5)', ...field.labelSx }}>
                        {field.label}
                      </StyledTypo>
                      {field.value && (
                        <StyledTypo
                          fw={400}
                          sx={{ color: 'rgba(0,0,0,0.5)', ...field.valueSx }}>
                          {field.value}
                        </StyledTypo>
                      )}
                      {field.subValue && (
                        <StyledTypo
                          fw={400}
                          sx={{ color: 'rgba(0,0,0,0.5)', ...field.subValueSx }}>
                          {field.subValue}
                        </StyledTypo>
                      )}
                    </Box>
                  ))}
                </Box>
              ))}
            <Box>
              {typeof obj.delta === 'number' && (
                <Box
                  display="inline-flex"
                  alignItems={'center'}
                  gap="0.3rem"
                  sx={{
                    background: obj.delta < 0 ? deltaDownColors.background : obj.delta === 0 ? deltaZero.background : deltaUpColors.background,
                    padding: '0.15rem 0.5rem',
                    borderRadius: '5rem'
                  }}>
                  <>
                    {obj.delta < 0 && (
                      <TrendingDownIcon
                        sx={{ color: deltaDownColors.color }}
                        fontSize="small"
                      />
                    )}
                    {obj.delta > 0 && (
                      <TrendingUpIcon
                        sx={{ color: deltaUpColors.color }}
                        fontSize="small"
                      />
                    )}
                    {obj.delta && (
                      <Typography
                        variant="subtitle2"
                        sx={{
                          color: obj.delta < 0 ? deltaDownColors.color : obj.delta === 0 ? deltaZero.color : deltaUpColors.color,
                          fontSize: '0.6rem'
                        }}>
                        {obj.delta}%
                      </Typography>
                    )}
                  </>
                </Box>
              )}
            </Box>

            {obj.dateRange && (
              <>
                <Typography sx={{ fontSize: '0.8rem', color: 'rgba(18,41,55,0.5)' }}>
                  {obj.rangeText ? obj.rangeText : 'than previous date selected'}
                </Typography>
                <Typography sx={{ fontSize: '0.8rem', color: '#000' }}>
                  ({formatDate(obj.dateRange.previous_start_date)} - {formatDate(obj.dateRange.previous_end_date)} vs {formatDate(dateRange.start)} -{' '}
                  {formatDate(dateRange.end)})
                </Typography>
              </>
            )}
          </Box>
          {/* {obj.messageComp &&
                    <Box paddingX={1} paddingY={1} bgcolor="rgba(230, 230, 230, 1)" >
                        {obj.messageComp}
                    </Box>
                } */}
          {obj.extraComp}
        </Box>
        {props.type && props.message && (
          <ErrorRateMessage
            type={props.type}
            category={props.message}
          />
        )}
        {/* {obj.delta && typeof obj.delta === 'number' && (
          <>
            {obj.delta < -3 && (
              <Box
                p={1}
                bgcolor="#F9F9F9"
                display="flex"
                flexDirection="row"
                gap="3px"
                alignItems="center">
                <Box
                  display="flex"
                  color="rgba(14, 140, 67, 1)"
                  fontSize="15px">
                  <LocalActivityIcon
                    color="inherit"
                    fontSize="inherit"
                  />
                </Box>
                <StyledTypo
                  fw={400}
                  fs={9}
                  sx={{ mr: 1 }}>
                  We like to see <span style={{ fontWeight: 600 }}>{label}</span> <span style={{ color: 'rgba(14, 140, 67, 1)' }}>below 3%.</span> Nice work!
                </StyledTypo>
                <img
                  src={flowerImg}
                  style={{ width: 15, height: 15 }}
                  alt="flower"
                />
              </Box>
            )}
            {obj.delta >= -3 && (
              <Box
                p={1}
                bgcolor="#F9F9F9"
                display="flex"
                flexDirection="row"
                gap="3px"
                alignItems="center">
                <Box
                  display="flex"
                  color="#F8DE22"
                  fontSize="15px">
                  <FlagIcon
                    color="inherit"
                    fontSize="inherit"
                  />
                </Box>

                <StyledTypo
                  fw={400}
                  fs={9}
                  sx={{ mr: 1 }}>
                  Uh-oh!
                  <span style={{ fontWeight: 600 }}>{label}</span> is above <span style={{ color: '#F8DE22' }}>3%.</span>! Let's fix it together
                </StyledTypo>
              </Box>
            )}
          </>
        )} */}
      </Box>
    )
  }

  const secondCardCond = props.title2 && props.label2

  return (
    <>
      <Card
        sx={{
          flex: secondCardCond ? 2 : 1,
          border: props.removeBorder ? '' : '1px solid #E6E6E6',
          height: props.height ?? '100%',
          position: 'relative',
          ...props.sx
        }}>
        <CardContent
          sx={{
            padding: '0px !important',
            height: '100%'
          }}>
          {props.mainTitle && (
            <Box padding="0.7rem 0.7rem 0rem 0.7rem">
              <Typography variant="subtitle2">
                <b>{props.mainTitle}</b>
              </Typography>
            </Box>
          )}

          {props.icon && <Box sx={{ mt: '1rem', ml: '0.8rem', mb: '-1rem' }}>{props.icon}</Box>}

          <Box
            display="flex"
            alignItems="stretch"
            height="100%">
            {cardLayout(
              props.label,
              props.title,
              {
                labelTooltip: props.labelTooltip,
                dateRange: props.dateRange,
                delta: props.delta,
                subTitle: props.subTitle,
                subLabel: props.subLabel,
                deltaReveised: props.deltaReversed,
                rangeText: props.rangeText,
                extraComp: props.extraComp,
                showSubTitleTooltip: props.showSubTitleTooltip,
                subTitlePosition: props.subTitlePosition
              },
              props.sxLabel,
              props.sxTitle,
              props.extraFields,
              props.loading
            )}
            {secondCardCond && (
              <>
                <Box
                  sx={{
                    width: '1px',
                    background: 'rgba(0,0,0,0.1)'
                  }}
                />
                {cardLayout(props.label2, props.title2, {
                  labelTooltip: props.labelTooltip2,
                  dateRange: props.dateRange2,
                  delta: props.delta2,
                  subTitle: props.subTitle2,
                  subLabel: props.subLabel2,
                  deltaReveised: props.deltaReversed2,
                  rangeText: props.rangeText,
                  extraComp: props.extraComp2,
                  showSubTitleTooltip: props.showSubTitleTooltip2,
                  subTitlePosition: props.subTitlePosition2
                })}
              </>
            )}
          </Box>
        </CardContent>
      </Card>
    </>
  )
}

export default DeltaCard
