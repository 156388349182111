import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation'
import CrisisAlertIcon from '@mui/icons-material/CrisisAlert'
import { Chip, Skeleton } from '@mui/material'
import React from 'react'

type ErrorChipProps = {
  icon?: boolean
  error?: boolean
  correct?: boolean
  category: string
  loading?: boolean
}

export default function ErrorChip(props: ErrorChipProps) {
  const { icon, category, error, correct, loading } = props

  const bgcolor = React.useMemo(() => {
    if (error) {
      return 'rgba(255, 239, 239, 1)'
    } else if (correct) {
      return '#DAF2E4'
    }
    return undefined
  }, [error, correct])

  const color = React.useMemo(() => {
    if (error) {
      return 'rgba(255, 61, 61, 1)'
    } else if (correct) {
      return '#0E8C43'
    }
    return undefined
  }, [error, correct])

  const Icon = React.useMemo(() => {
    const c = `${color} !important`
    if (category?.toLowerCase() === 'cancelled') {
      return <CancelPresentationIcon sx={{ color: c }} />
    } else if (category?.toLowerCase() === 'inaccurate') {
      return <CrisisAlertIcon sx={{ color: c }} />
    } else if (category?.toLowerCase() === 'missed') {
      return <CallMissedOutgoingIcon sx={{ color: c }} />
    } else {
      return <></>
    }
  }, [category, color])

  return (
    <Chip
      label={
        <>
          {loading ? (
            <Skeleton
              variant="text"
              sx={{ width: '100px' }}
            />
          ) : (
            <>
              {category && (
                <span style={{ fontWeight: 600 }}>
                  {category[0].toUpperCase()}
                  {category.slice(1)}
                </span>
              )}
            </>
          )}
        </>
      }
      variant="filled"
      sx={{ fontSize: '14px', bgcolor, color }}
      // avatar={icon && Icon && Icon}
      icon={icon && Icon}
    />
  )
}
