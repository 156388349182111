import ReactGA from 'react-ga4'

const schemaError = "Failed to execute 'transaction' on 'IDBDatabase'"
const VersionError = 'VersionError'

export function track(action: string, category: string, label?: string, value?: number, nonInteraction?: boolean, transport?: 'beacon' | 'xhr' | 'image') {
  // if (navigator.onLine) {
  //   ReactGA.event({
  //     category,
  //     action,
  //     label,
  //     value,
  //     nonInteraction, // optional, true/false
  //     transport // optional, beacon/xhr/image
  //   })
  // }
}

export function trackAPILatency(chain, label, value) {
  ReactGA.event({
    action: 'api_latency',
    category: chain,
    label,
    value,
    nonInteraction: true,
    transport: 'xhr'
  })

  ReactGA.event({
    action: chain,
    category: label,
    label: value,
    nonInteraction: true,
    transport: 'xhr'
  })
}

export function trackError(error, chain) {
  ReactGA.event({
    action: 'app_errors',
    category: chain,
    label: error,
    nonInteraction: true,
    transport: 'xhr'
  })
}

export function APIError({ chain, label, value }) {
  ReactGA.event({
    action: 'api_error',
    category: chain,
    label,
    value,
    nonInteraction: true,
    transport: 'xhr'
  })
}
