import { Box, Typography } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { usePrivateLayoutDrawerContext } from 'src/context/PrivateLayoutDrawerContext'
import { drawerCloseWidth } from '..'
import { CategoryIconMapping } from './CategoryIconMapping'

interface WizardTabProps {
  activeCategory: string
  selectedCategory: string
  setSelectedCategory: React.Dispatch<React.SetStateAction<string>>
  setAnchorEl: React.Dispatch<React.SetStateAction<null | HTMLElement>>
}

const WizardTab = ({ activeCategory, selectedCategory, setSelectedCategory, setAnchorEl }: WizardTabProps) => {
  const { open } = usePrivateLayoutDrawerContext()
  const navigate = useNavigate()
  const isActive = activeCategory === 'wizard'
  const isSelected = selectedCategory === 'wizard'

  const handleClick = () => setSelectedCategory('wizard')

  return (
    <Box
      width={'100%'}
      height={drawerCloseWidth - 2}
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        cursor: 'pointer',
        '&:hover > div': {
          outline: '1px solid #196E82'
        }
      }}
      onClick={() => {
        handleClick()
      }}
      onMouseEnter={(e) => {
        if (!open) {
          setAnchorEl(e.currentTarget)
          handleClick()
        }
      }}>
      <Box
        width={drawerCloseWidth - 6}
        height={drawerCloseWidth - 6}
        display="flex"
        alignItems="center"
        justifyContent="center"
        position="relative"
        borderRadius="4px"
        bgcolor={isActive ? '#196E8273' : 'transparent'}
        sx={{
          transition: 'background-color 0.3s',
          outline: isActive || isSelected ? '1px solid #196E82' : 'none',
          outlineOffset: '-1px'
        }}>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          gap={0.5}
          p={'8px'}
          width="100%">
          <img
            src={CategoryIconMapping['wizard']}
            alt="category"
            style={{ width: '24px', height: '24px' }}
          />

          <Typography
            sx={{ color: '#ffffff', fontSize: '11px', textAlign: 'center', textOverflow: 'ellipsis', width: '100%' }}
            noWrap>
            Wizards
          </Typography>
        </Box>
      </Box>
    </Box>
  )
}

export default WizardTab
