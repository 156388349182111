import { Box, Skeleton, Stack, SxProps, Typography } from '@mui/material'
import React from 'react'

interface IconCardProps {
  icon: React.ReactNode
  label: string
  topRightComp?: React.ReactNode
  subLabel?: string
  title?: string
  sx?: SxProps
  labelSx?: SxProps
  titleSx?: SxProps
  subLabelSx?: SxProps
  bottomComp?: React.ReactNode
  loading?: boolean
}

export default function IconCard(props: IconCardProps) {
  const { icon, label, topRightComp, subLabel, title, sx, labelSx, titleSx, subLabelSx, bottomComp, loading } = props

  const px = '20px'
  const pt = '17px'
  const pb = '17px'

  const LoadingSkeleton = <Skeleton sx={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }} />

  return (
    <>
      <Box
        sx={{
          bgcolor: 'white',
          borderRadius: '4px',
          border: '1px solid rgba(230, 230, 230, 1)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'stretch',
          ...sx
        }}>
        <Stack
          direction="row"
          alignItems="flex-start"
          justifyContent="space-between"
          sx={{ px, pt, position: 'relative' }}>
          <Box sx={{ fontSize: '30px', display: 'flex' }}>{icon}</Box>
          {topRightComp && <Box>{topRightComp}</Box>}
        </Stack>

        <Box sx={{ mt: '15px', px }}>
          <Box sx={{ position: 'relative', width: loading ? '55%' : '100%' }}>
            {loading && LoadingSkeleton}
            <Typography
              fontSize="14px"
              fontWeight={600}
              lineHeight={'18px'}
              sx={{
                height: '36px',
                display: '-webkit-box',
                boxOrient: 'vertical',
                lineClamp: 2,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                visibility: loading ? 'hidden' : 'visible',
                ...labelSx
              }}>
              {loading ? 'Loading' : label}
            </Typography>
          </Box>
        </Box>

        {subLabel && (
          <Box sx={{ mt: '10px', px }}>
            <Box sx={{ position: 'relative', width: loading ? '40%' : '100%' }}>
              {loading && LoadingSkeleton}
              <Typography
                variant="subtitle2"
                color="rgba(0, 0, 0, 0.5)"
                fontSize="14px"
                sx={{ visibility: loading ? 'hidden' : 'visible', ...subLabelSx }}>
                {loading ? 'Loading' : subLabel}
              </Typography>
            </Box>
          </Box>
        )}

        {title && (
          <Box
            sx={{
              mt: '10px',
              px,
              pb: bottomComp ? pb : 0
            }}>
            <Box sx={{ position: 'relative', width: loading ? '45%' : '100%' }}>
              {loading && LoadingSkeleton}
              <Typography
                fontSize="32px"
                fontWeight={600}
                lineHeight="100%"
                sx={{ visibility: loading ? 'hidden' : 'visible', ...titleSx }}>
                {loading ? 'Loading' : title}
              </Typography>
            </Box>
          </Box>
        )}
        <Box sx={{ flex: 1, mt: '10px' }} />
        {bottomComp && <Box>{bottomComp}</Box>}
      </Box>
    </>
  )
}
