import { onAuthStateChanged, signInWithCustomToken, signOut, User } from 'firebase/auth'
import localforage from 'localforage'
import { get } from 'lodash'
import posthog from 'posthog-js'
import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { auth } from 'src/services/firebase'
import { validateUserToken } from 'src/utils/firebaseFunctions/validateUserToken'
import useValidateLocalData from 'src/utils/hooks/useValidateLocalData'
import { PageDetails } from './AuthContext.type'
import { useErrorData } from './ErrorContext'
import filterGraphManager from './NewFilterContext/utils/FilterGraphManager'

interface PublicAuthContextInterface {
  currentUser: User | null
  pageDetails: PageDetails
  loading: boolean
  setLoading: Dispatch<SetStateAction<boolean>>
  setPageDetails: Dispatch<SetStateAction<PageDetails>>
  logout: () => void
  analyticsReset: () => void
}

const PublicAuthContext = createContext({} as PublicAuthContextInterface)

export const usePublicAuthContext = () => useContext(PublicAuthContext)

interface PublicAuthContextProviderProps {
  children: ReactNode
}

export default function PublicAuthContextProvider(props: PublicAuthContextProviderProps) {
  const { refreshNeeded, localData, setLocalData } = useValidateLocalData()
  const navigate = useNavigate()
  const { asyncWrapper } = useErrorData()
  const [params, setParams] = useSearchParams()
  const [loading, setLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState<User | null>(null)
  const [pageDetails, setPageDetails] = useState<PageDetails>({
    path: location.pathname,
    visitTime: new Date().getTime()
  })

  const analyticsReset = () => {
    posthog.reset()
    posthog.unregister('email')
  }

  const clearLocalData = () => {
    localStorage.clear()
    sessionStorage.clear()
    sessionStorage.clear()
    localforage
      .clear()
      .then(() => {})
      .catch((err) => {
        console.log('Failed to clear localforage: ', err.message)
      })
    filterGraphManager.clearGraphs()
    setLocalData(false)
    analyticsReset()
  }

  const logout = () => {
    asyncWrapper(
      (async () => {
        setCurrentUser(undefined)
        await signOut(auth)
        clearLocalData()
      })()
    )
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      setCurrentUser(currentUser)
      // setTimeout(() => {
      // })
      // if user is not logged in
      if (!currentUser) {
        clearLocalData()
        setLoading(false)
      }
    })
    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [refreshNeeded, localData])

  useEffect(() => {
    const signInIfMagicLink = async () => {
      if (currentUser) return
      try {
        const token = params.get('magic_token')?.replaceAll(/\s/g, '+')
        if (!token) {
          return
        } else {
          setLoading(true)
        }
        const res = await validateUserToken({ tokenList: [token], getAuthToken: true })
        if (get(res, '[0].expired', true)) {
          navigate('/login/expired')
        } else if (get(res, '[0].data.authToken', null)) {
          setParams((prev) => {
            prev.delete('magic_token')
            return prev
          })
          await signInWithCustomToken(auth, get(res, '[0].data.authToken', null))
        }
        setLoading(false)
      } catch (err) {
        console.log('magic link error', err)
        setLoading(false)
      }
    }
    signInIfMagicLink()
  }, [params, currentUser])

  const contextValue = useMemo(() => {
    return {
      currentUser,
      pageDetails,
      loading,
      setLoading,
      setPageDetails,
      analyticsReset,
      logout
    }
  }, [currentUser, pageDetails, loading])

  return (
    <>
      <PublicAuthContext.Provider value={contextValue}>{props.children}</PublicAuthContext.Provider>
    </>
  )
}
