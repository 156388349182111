import axios from 'axios'
import { APP_NAME, APP_VERSION, BASE_API_URL } from 'src/utils/config/config'

const appName = APP_NAME
const appVersion = APP_VERSION

class Slack {
  baseUrl: string
  constructor(baseUrl: string = BASE_API_URL) {
    this.baseUrl = baseUrl
  }

  postSlack = async ({ message, title, channel }: { title: string; message: string; channel?: string }) => {
    const object = {
      message: message + ` on \`${appName + '@' + appVersion}\``,
      title: title,
      channel: channel
    }

    const queryParams = new URLSearchParams(object).toString()
    // return DefaultService.sendSlackMessageEndpointActionsInternalSendSlackMessagePost(title, message + ` on \`${appName + '@' + appVersion}\``, channel)
    return axios.post(`${this.baseUrl}/actions/internal/send_slack_message?${queryParams}`)
  }
}

export default Slack
