import { KeyboardArrowDown } from '@mui/icons-material'
import { Box, Collapse, Typography } from '@mui/material'
import React from 'react'

interface CollapsableSectionProps {
  children: React.ReactNode | JSX.Element
  icon: React.ReactNode | JSX.Element
  title: string | React.ReactNode
}

const CollapsableSection = ({ children, icon, title }: CollapsableSectionProps) => {
  const [opened, setOpened] = React.useState<boolean>(true)

  return (
    <Box
      border="1px solid #E6E6E6E6"
      borderRadius="4px">
      <Box
        p={2}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        sx={{
          cursor: 'pointer'
        }}
        onClick={() => setOpened((prev) => !prev)}>
        <Box
          display="flex"
          alignItems="center"
          gap={1}>
          {icon}
          {typeof title === 'string' ? (
            <Typography
              fontSize="14px"
              color={'#000000'}
              fontWeight={600}>
              {title}
            </Typography>
          ) : (
            title
          )}
        </Box>

        <KeyboardArrowDown sx={{ fontSize: '20px', color: '#000', transform: opened ? 'rotate(180deg)' : 'rotate(0deg)', transition: '0.3s ease' }} />
      </Box>

      <Collapse in={opened}>
        <Box
          p={2}
          pt={0}>
          {children}
        </Box>
      </Collapse>
    </Box>
  )
}

export default CollapsableSection
