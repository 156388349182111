import chargebackJsonData from './objects/Chargebacks'
import clicksJsonData from './objects/Clicks'
import clicksToOrdersJsonData from './objects/ClicksToOrders'
import errorRateJsonData from './objects/ErrorRate'
import financeJsonData from './objects/FinanceOrderTransactions'
import impressionsJsonData from './objects/Impressions'
import impressionsToClicksJsonData from './objects/ImpressionsToClicks'
import lostSalesJsonData from './objects/LostSales'
import marketingJsonData from './objects/Marketing'
import marketingSpendJsonData from './objects/MarketingSpend'
import metricsJsonData from './objects/MetricsKPI'
import ordersJsonData from './objects/Orders'
import ratingJsonData from './objects/Ratings'
import roiJsonData from './objects/Roi'
import salesJsonData from './objects/Sales'
import salesFromMarketingJsonData from './objects/SalesFromMarketing'
import thirdPartyFeeJsonData from './objects/ThirdPartyFee'
import thirdPartyFeePercJsonData from './objects/ThirdPartyFeePerc'

export type JSONDataType = typeof financeJsonData

const allJsonData = {
  FinanceOrderTransactions: financeJsonData,
  Ratings: ratingJsonData,
  MetricsKPI: metricsJsonData,
  Chargebacks: chargebackJsonData,
  Sales: salesJsonData,
  LostSales: lostSalesJsonData,
  Marketing: marketingJsonData,
  ErrorRate: errorRateJsonData,
  SalesFromMarketing: salesFromMarketingJsonData,
  MarketingSpend: marketingSpendJsonData,
  Roi: roiJsonData,
  ThirdPartyFee: thirdPartyFeeJsonData,
  ThirdPartyFeePerc: thirdPartyFeePercJsonData,
  Clicks: clicksJsonData,
  ClicksToOrders: clicksToOrdersJsonData,
  Impressions: impressionsJsonData,
  ImpressionsToClicks: impressionsToClicksJsonData,
  Orders: ordersJsonData
}

export default allJsonData
