import DocumentScannerIcon from '@mui/icons-material/DocumentScanner'
import ExpandLess from '@mui/icons-material/ExpandLess'
import ExpandMore from '@mui/icons-material/ExpandMore'
import { Box, Collapse, Icon, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { get } from 'lodash'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import premium from 'src/assets/icons/premium.svg'
import { useAuth } from 'src/context/AuthContext'
import { generateKey } from 'src/utils/functions'

export const activeColor = '#FFF'
export const inactiveColor = '#FFF'

interface Page {
  key: string
  label: string
  icon: string
}

interface PageTypes extends Page {
  subNav: Page[]
}

interface SubNavProps {
  open: boolean
  closeDrawer: () => void
  icon: string | { label: string; value: string }
  page: any
  parentKey?: string
  searchText?: string
  type?: string
  closePopper?: () => void
  newRoute?: boolean
}

function SubNav({ page, closeDrawer, icon, parentKey, searchText, type, closePopper, newRoute }: SubNavProps) {
  const location = useLocation()
  const [openMenu, setOpenMenu] = React.useState(false)
  const { posthogCapture } = useAuth()

  const handleClick = () => {
    setOpenMenu(!openMenu)
  }

  function getLink(page: PageTypes, index: number) {
    const parent = parentKey ? `/${parentKey}` : ''
    const tempSubNav = get(page, `subNav.${index}.key.[0]`) === '/' ? get(page, `subNav.${index}.key`) : `/${page.key}/${page.subNav[index].key}`
    const navigateLink = `${parent}${tempSubNav}`
    const href = navigateLink.includes('datastudio') ? `${window.location.origin}/${navigateLink.slice(navigateLink.indexOf('datastudio'))}` : navigateLink
    return href
  }

  React.useEffect(() => {
    if (searchText.length > 0 && page.subNav.length > 0 && page.subNav.some((e) => e.label.toLowerCase().includes(searchText.toLowerCase()))) {
      setOpenMenu(true)
    } else {
      setOpenMenu(false)
    }
  }, [searchText, page])

  const checkIsActive = React.useCallback(
    (parentKey: string, subNavKey?: string) => {
      const key = generateKey(parentKey, subNavKey)
      return key === location.pathname
    },
    [location.pathname]
  )

  const isActive = React.useMemo(() => {
    if (Array.isArray(page.subNav) && page.subNav.length > 0) {
      return page.subNav.some((subPage) => checkIsActive(page.key, subPage.key))
    }
    return checkIsActive(page.key)
  }, [checkIsActive, location.pathname])

  return (
    <div>
      <List sx={{ py: 0 }}>
        <ListItem
          selected={isActive}
          disablePadding
          sx={{
            '&.Mui-selected': { bgcolor: 'transparent' }
          }}>
          <ListItemButton
            sx={{
              color: isActive ? activeColor : inactiveColor,
              bgcolor: isActive && !openMenu ? 'rgba(25, 110, 130, 0.5)' : 'transparent',
              outline: isActive && !openMenu ? '1px solid #196E82' : 'none',
              outlineOffset: '-1px',
              '&:hover': {
                outline: '1px solid #196E82',
                bgcolor: isActive && !openMenu ? 'rgba(25, 110, 130, 0.5)' : 'transparent'
              },
              borderRadius: '4px',
              py: '10px',
              px: '12px',
              mx: '4px',
              // '&:hover': { bgcolor: 'rgba(25, 110, 130, 0.3)' },
              position: 'relative'
            }}
            onClick={handleClick}>
            {/* {newRoute && <Box sx={{ position: 'absolute', top: '6px', left: '3px', width: '8px', height: '8px', bgcolor: '#47E44E', borderRadius: '50%' }} />} */}
            <ListItemIcon sx={{ mr: '12px', minWidth: 0, color: 'inherit' }}>
              <Icon sx={{ fontSize: '22px' }}>{typeof icon === 'string' ? icon : icon.value}</Icon>
            </ListItemIcon>
            <ListItemText
              primary={page.label}
              sx={{
                '& span': {
                  fontWeight: isActive && 600,
                  whiteSpace: 'normal',
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  WebkitLineClamp: 2,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }
              }}
            />
            {openMenu ? <ExpandLess /> : <ExpandMore />}
            {type === 'preview' && (
              <ListItemIcon
                sx={{
                  minWidth: 20,
                  ml: 0.5,
                  color: 'inherit'
                }}>
                <img
                  src={premium}
                  alt="Connect to customer success team"
                  width={20}
                  height={20}
                  style={{ marginTop: '-2px' }}
                />
              </ListItemIcon>
            )}
          </ListItemButton>
        </ListItem>
        <Collapse
          in={openMenu}
          timeout="auto"
          unmountOnExit>
          <List
            component="div"
            sx={{ ml: 3, pl: -1, borderLeft: '3px solid #196E82', py: 0, my: 1, display: 'flex', flexDirection: 'column', gap: '3px' }}>
            {get(page, 'subNav', []).map((subPage, index) => {
              const isActive = checkIsActive(page.key, subPage.key)
              if (isActive) {
                document.title = subPage?.pageTitle || `Loop - ${page.label} - ${subPage.label}`
              }
              if (get(subPage, 'subNav', []).length > 0) {
                return (
                  <Box
                    pl={1}
                    key={subPage.key}>
                    <SubNav
                      open={openMenu}
                      closeDrawer={closeDrawer}
                      page={subPage}
                      parentKey={page.key}
                      icon={subPage.icon || <DocumentScannerIcon />}
                    />
                  </Box>
                )
              }
              return (
                <ListItemButton
                  sx={{
                    color: isActive ? activeColor : inactiveColor,
                    bgcolor: isActive ? 'rgba(25, 110, 130, 0.5)' : 'transparent',
                    outline: isActive ? '1px solid #196E82' : 'none',
                    outlineOffset: '-1px',
                    '&:hover': {
                      outline: '1px solid #196E82',
                      bgcolor: isActive ? 'rgba(25, 110, 130, 0.5)' : 'transparent'
                    },
                    borderRadius: '4px',
                    mx: '16px',
                    px: '10px',
                    py: '10px'
                  }}
                  component={Link}
                  onClick={() => {
                    posthogCapture('sidebar_link_clicked', { href: `${page.label}/${subPage.label}` })
                    closeDrawer()
                    closePopper?.()
                  }}
                  to={getLink(page, index)}
                  key={subPage.key}>
                  <ListItemText
                    primary={subPage.label}
                    sx={{ '& span': { fontWeight: isActive && 600 } }}
                  />
                </ListItemButton>
              )
            })}
          </List>
        </Collapse>
      </List>
    </div>
  )
}

export default SubNav
