import { PLATFORM } from '@LoopKitchen/typescript-client/lib/clients/FrontendDataService'
import { Box, SxProps } from '@mui/material'
import React, { useMemo } from 'react'
import { useFilter } from 'src/context/FilterContext'
import { useNewFilterContext } from 'src/context/NewFilterContext/NewFilterContext'
import { useTransactionsBreakdownDataContext } from 'src/pages/members/Finance/utils/context/TransactionsBreakdownDataContext'
import BreakdownByPlatform from './BreakdownByPlatform'
import BreakdownIndex from './BreakdownIndex'

interface PlatformBreakdownProps {
  sx?: SxProps
}

const PlatformBreakdown = React.forwardRef<HTMLElement, PlatformBreakdownProps>((props, componentRef) => {
  const { sx } = props
  const { getAllPossibleFilters } = useNewFilterContext()
  const { getFilters, getFiltersV2 } = useFilter()
  const { platformBreakdownData, breakdownData, platformBreakdownLoading, breakdownLoading } = useTransactionsBreakdownDataContext()

  const platforms = React.useMemo(() => {
    const vb_platform_in = getFiltersV2(['vb_platform'])?.vb_platform_in

    if (vb_platform_in === '') return getAllPossibleFilters(['vb_platform']).vb_platform as string[]

    return vb_platform_in?.split('|')
  }, [...getFilters(['vb_platform'])])

  const oloExists = useMemo(() => {
    return platforms?.some((platform) => platform === PLATFORM.OLO)
  }, [platforms])

  return (
    <Box
      sx={{ display: 'flex', marginTop: '20px', ...sx }}
      ref={componentRef}>
      <BreakdownIndex
        label="Component"
        data={breakdownData}
        oloExists={oloExists}
      />

      {platforms?.map((platform, index) => {
        return (
          <BreakdownByPlatform
            key={platform + index}
            label={platform}
            image={platform}
            data={platformBreakdownData[platform.toLowerCase()]}
            platformBreakdownLoading={platformBreakdownLoading}
            oloExists={oloExists}
          />
        )
      })}

      <BreakdownByPlatform
        label="Total"
        fw={600}
        data={breakdownData}
        platformBreakdownLoading={breakdownLoading}
        oloExists={oloExists}
      />
    </Box>
  )
})

export default PlatformBreakdown
