import { MultiSelect } from '@LoopKitchen/loop-ui'
import { Stack } from '@mui/material'
import { get } from 'lodash'
import moment from 'moment'
import React from 'react'
import { DateRangePickerWrapper } from 'src/components/NewFilterComponent/components/DateRangePickerComp'
import { getFilterIcon, getFilterName } from 'src/components/NewFilterComponent/utils/filterUtils'
import { useAuth } from 'src/context/AuthContext'
import { DrillDownStackObjType, useDrillDownContext } from 'src/context/DrillDownContext'
import { shouldBlockForUser } from 'src/utils/functions/sandbox'
import { BreakdownEnumsType } from '../utils/jsonConfigs/types'

interface DrillDownFilterComponentProps {
  stack: DrillDownStackObjType[]
  onFilterUpdate: (filterKey: BreakdownEnumsType, newSelected: string[], index: number) => void
}

// TODO implement it with new filters
export default function DrillDownFilterComponent(props: DrillDownFilterComponentProps) {
  const { stack, onFilterUpdate } = props
  const { currentUser } = useAuth()
  const { filtersLoading, lastDate, activeGraph, getFiltersArr, setDateRange, selectFilter } = useDrillDownContext()
  const [loading, setLoading] = React.useState(true)

  const { drillDownName } = React.useMemo(() => {
    if (stack.length === 0) {
      return {} as DrillDownStackObjType
    }
    return stack.at(-1)
  }, [stack])

  const filtersArr = React.useMemo(() => {
    const tempArr = getFiltersArr(stack)
    const arrangementArr = ['b_name', 'vb_name', 'vb_platform', 'am_name']
    return arrangementArr.map((key) => tempArr.find((e) => e.key === key)).filter((e) => !!e)
  }, [stack])

  if (filtersLoading) {
    return <></>
  }

  return (
    <Stack
      direction="row"
      alignItems="center"
      gap="8px">
      <DateRangePickerWrapper
        minDate={shouldBlockForUser(currentUser) ? moment('2023-10-15') : null}
        maxDate={shouldBlockForUser(currentUser) ? moment('2024-01-15') : lastDate}
        value={
          shouldBlockForUser(currentUser)
            ? { start: moment('2023-12-15'), end: moment('2024-01-15') }
            : activeGraph.getDateRange() || { start: moment(), end: moment() }
        }
        onChange={(newDateRange) => {
          setDateRange(newDateRange)
        }}
        showLastWeekShortcut
        showMonthSelect
      />
      {filtersArr.map((filter) => {
        const { key } = filter
        const allPossible = activeGraph.getAllPossibleNodesByKey(key)
        const visibleSet = activeGraph.getVisibleNodesByKey(key)
        const selectedSet = activeGraph.getSelectedNodesByKey(key)
        return (
          <MultiSelect
            key={key}
            label={getFilterName(key)}
            icon={getFilterIcon(key)}
            options={Array.from(visibleSet).map((e) => ({
              label: get(e, 'value', '').toString(),
              value: get(e, 'value', '').toString()
            }))}
            value={Array.from(selectedSet).map((e) => get(e, 'value', '').toString())}
            onChange={(newSelected) => {
              const index = stack.findIndex((e) => get(e, 'filterObj', [] as typeof e.filterObj).some((item) => item.key === key))
              if (index < 0) {
                return
              }
              const selectedNodes = newSelected.map((val) => allPossible.find((e) => e.value === val)).filter((e) => e)
              selectFilter(key, selectedNodes)
              onFilterUpdate(key, newSelected, index)
            }}
            renderValue={
              allPossible.length === visibleSet.size && visibleSet.size === selectedSet.size
                ? (selectedValue) => (
                    <span>
                      <span style={{ fontWeight: 600 }}>All ({allPossible.length})</span> {`${getFilterName(key)}s`}
                    </span>
                  )
                : undefined
            }
            showRemoveFilter
          />
        )
      })}
    </Stack>
  )
}
