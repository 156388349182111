import { Box, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { useAuth } from 'src/context/AuthContext'
import { pluralize } from 'src/utils/functions/pluralize'

const RedirectionButton = ({
  redirectText,
  redirectingText,
  handleClose
}: {
  redirectText: string
  redirectingText: string
  handleClose: () => Promise<void>
}) => {
  const { openRedirectionDialog } = useAuth()
  const [hover, setHover] = useState(false)
  const [countdown, setCountdown] = useState(15)

  useEffect(() => {
    if (openRedirectionDialog?.openRedirection === false && openRedirectionDialog?.openRedirected === false) return

    const timer = countdown > 0 ? setTimeout(() => setCountdown(countdown - 1), 1000) : null

    if (countdown === 0) {
      handleClose()
      clearTimeout(timer)
    }

    return () => clearTimeout(timer)
  }, [countdown, openRedirectionDialog?.openRedirection, openRedirectionDialog?.openRedirected])

  return (
    <Box
      width="100%"
      py={1.5}
      border="1px solid #E6E6E6E6"
      borderRadius="4px"
      display="flex"
      alignItems="center"
      justifyContent="center"
      sx={{
        bgcolor: '#FFF',
        color: '#717171',
        cursor: 'pointer',
        transition: '0.3s ease',
        '&:hover': {
          bgcolor: '#125160',
          color: '#FFF'
        }
      }}
      onClick={handleClose}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}>
      <Typography
        fontSize={14}
        fontWeight={600}
        color="inherit">
        {hover ? redirectText : `${redirectingText} ${countdown} ${pluralize('second', countdown)}`}
      </Typography>
    </Box>
  )
}

export default RedirectionButton
