import axios from 'axios'
import { BASE_FIREBASE_FUNCTIONS_URL } from '../config/config'

interface CreateAuthTokensRequestBody {
  userUidList: string[]
  expiresInSeconds?: number
}

interface CreateAuthTokensResponseBody {
  userList: Array<{ userUid: string; token: string }>
}

export const createAuthTokens = async (params: CreateAuthTokensRequestBody) => {
  return (await axios.post(`${BASE_FIREBASE_FUNCTIONS_URL}/createAuthTokens`, params)).data as CreateAuthTokensResponseBody
}
