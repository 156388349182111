import Box from '@mui/material/Box'
import { SxProps } from '@mui/material/styles'
import * as Sentry from '@sentry/react'
import { debounce } from 'lodash'
import get from 'lodash/get'
import React, { useEffect, useLayoutEffect, useRef } from 'react'
import SentryErrorBoundary from 'src/components/errors/SentryErrorBoundary'
import { useAuth } from 'src/context/AuthContext'
import { useLoadingData } from 'src/context/LoadingContext'
import { useTopbarContext } from 'src/context/TopbarContext'
import PreviewPage from 'src/pages/members/Preview'
import DialogBugReporting from 'src/pages/members/ReportBug/DialogBugReport'
import BackdropLoader from '../Loading/BackdropLoader'
import { NewFilterComponentProps } from '../NewFilterComponent/NewFilterComponent'
interface PropTypes {
  title: string | React.ReactNode
  children: React.ReactNode
  copyUrl?: boolean
  sx?: SxProps
  bgcolor?: string
  filterProps?: NewFilterComponentProps
  hideFilters?: boolean
  renderPageWrapper?: boolean
}

export default function Page(props: PropTypes) {
  const { renderPageWrapper = true } = props
  const { setTitle, setCopyUrl, setFilterProps, setHideFilters } = useTopbarContext()
  const { globalRoutes, orgConfig } = useAuth()
  const { backdropLoading, setBackdropLoading } = useLoadingData()

  const setTopbarContextStates = () => {
    setCopyUrl(get(props, 'copyUrl', true))
    setFilterProps(get(props, 'filterProps', {}))
    setHideFilters(get(props, 'hideFilters', false))
  }

  const ref = useRef({})

  useLayoutEffect(() => {
    if (!renderPageWrapper) return

    setTopbarContextStates()
  }, [get(props, 'filterProps', ref.current), renderPageWrapper])

  useEffect(() => {
    if (!renderPageWrapper) return

    setTitle(props.title)
  }, [props.title, renderPageWrapper])

  useEffect(() => {
    if (!renderPageWrapper) return

    scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant'
    })
    setBackdropLoading(true)
    setTimeout(() => {
      setBackdropLoading(false)
    }, 2000)
  }, [renderPageWrapper])

  useEffect(() => {
    if (!renderPageWrapper) return

    const event = debounce(() => {
      if (backdropLoading) {
        document.body.style.overflowY = 'hidden'
      } else {
        document.body.style.overflowY = ''
      }
    }, 500)

    return () => {
      event.cancel()
      document.body.style.overflowY = ''
    }
  }, [backdropLoading, renderPageWrapper])

  function getAllPreviewPagesPathnames(navConfig) {
    // Initialize an empty array to store UIDs of preview type routes
    const routeUidArray = []

    // Loop through navConfig and push the UID of preview type routes to routeUidArray
    navConfig?.forEach((navItem) => {
      if (navItem.type === 'preview') {
        routeUidArray.push(navItem.uid)
      }
    })

    // Filter the globalRoutes to match the UIDs from routeUidArray
    const routes = globalRoutes.filter((route) => routeUidArray.includes(route.uid)).map((route) => route.key)

    // Return the filtered routes
    return routes
  }

  const isPreviewPagePath = (pathname, previewPagesPathNames) => {
    if (previewPagesPathNames.includes(pathname)) {
      return true
    }
    const firstSegment = pathname.split('/')[0]
    return previewPagesPathNames.includes(firstSegment)
  }

  const isPreviewPage = React.useMemo(() => {
    const previewPagesPathNames = getAllPreviewPagesPathnames(orgConfig.navConfig)
    const pathnameUri = location.pathname.substring(1)

    if (isPreviewPagePath(pathnameUri, previewPagesPathNames)) {
      return true
    } else {
      return false
    }
  }, [location.pathname, orgConfig.navConfig])

  if (isPreviewPage) {
    return <PreviewPage modulePageName={props.title} />
  }

  return (
    <Sentry.ErrorBoundary
      fallback={({ error }) => {
        return <SentryErrorBoundary error={error} />
      }}>
      {renderPageWrapper ? (
        <>
          <Box
            height="100%"
            sx={{
              position: 'relative'
            }}
            bgcolor="transparent">
            <BackdropLoader />
          </Box>
          <Box
            height="100%"
            sx={{
              ...get(props, 'sx', {}),
              position: 'relative'
            }}
            bgcolor={get(props, 'bgcolor', '#FFFFFF')}>
            {props.children}
          </Box>
        </>
      ) : (
        props.children
      )}

      <DialogBugReporting />
    </Sentry.ErrorBoundary>
  )
}
