import { PsychologyAlt } from '@mui/icons-material'
import { Box, SxProps, Tooltip } from '@mui/material'
import { ReactNode } from 'react'

interface IconTooltipProps {
  title: string | ReactNode
  placement?:
    | 'top'
    | 'bottom'
    | 'left'
    | 'right'
    | 'bottom-end'
    | 'bottom-start'
    | 'left-end'
    | 'left-start'
    | 'right-end'
    | 'right-start'
    | 'top-end'
    | 'top-start'
  tooltipSx?: SxProps
  boxSx?: SxProps
  iconSx?: SxProps
}

const IconTooltip = ({ title, placement = 'top', tooltipSx, boxSx, iconSx }: IconTooltipProps) => {
  return (
    <Tooltip
      title={title}
      arrow
      placement={placement}
      componentsProps={{
        tooltip: {
          sx: {
            color: 'white',
            fontSize: 12,
            fontWeight: 500,
            background: 'black',
            maxWidth: 600,
            padding: '10px',
            ...tooltipSx
          }
        },
        arrow: {
          style: {
            color: 'black'
          }
        }
      }}>
      <Box
        sx={{
          display: 'flex',
          fontSize: '18px',
          color: 'rgba(18, 41, 55, 0.32)',
          p: '3px',
          borderRadius: '2px',
          bgcolor: '#E8E8E8',
          ...boxSx
        }}>
        <PsychologyAlt
          fontSize="inherit"
          color="inherit"
          sx={iconSx}
        />
      </Box>
    </Tooltip>
  )
}

export default IconTooltip
