export default function workerCreator(callback: (e: MessageEvent) => void) {
  const worker = new Worker(
    URL.createObjectURL(
      new Blob(
        [
          `
          importScripts('https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.17.21/lodash.min.js')
          importScripts('https://cdnjs.cloudflare.com/ajax/libs/PapaParse/5.4.1/papaparse.min.js')
    self.onmessage = function(e) {
      (${callback.toString()}).call(self, e)
    }
  `
        ],
        { type: 'application/javascript' }
      )
    )
  )

  return worker
}
