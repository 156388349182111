import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react'
import { createSearchParams, useLocation } from 'react-router-dom'
import { useAuth } from 'src/context/AuthContext'
import { firebaseDb } from 'src/services/firebase'
// import { getDataWithAxios } from './../utils/api';
import originalMoment from 'moment'
import { extendMoment } from 'moment-range'
import { DefaultService, UsersService } from 'src/services/openApi'
// import { AxiosResponse } from 'axios';
import { doc, onSnapshot } from 'firebase/firestore'
import get from 'lodash/get'
import some from 'lodash/some'
import uniqBy from 'lodash/uniqBy'
import { sendSlackNotification } from 'src/utils/api'
import { FiltersForQuery } from 'src/utils/apiV2/Api'
import { filterNames } from 'src/utils/config/config'
import { getLocal, setLocal } from 'src/utils/functions/localStorage'
import useValidateLocalData from 'src/utils/hooks/useValidateLocalData'
import { useDrillDownContext } from './DrillDownContext'
import { useErrorData } from './ErrorContext'
import { NewFilterContext } from './NewFilterContext/NewFilterContext'
// @ts-ignore
const moment = extendMoment(originalMoment)

export type FilterType = {
  key: string
  name: string
  selected: any[]
  selectedSetOfValues: Set<string>
  selectedSetOfValuesDisplayed: Set<string>
}
export interface FilterItem {
  name: string
  value: string
}
export interface FilterResponse {
  am_name: string
  b_name: string
  chain: string
  error_category: string
  error_subcategory: string
  slug: string
  vb_name: string
  vb_platform: string
}
export interface BusinessRefressResponse {
  chain: string
  lastDate: string
  success_rate: number
  successful_slugs: number
  total_slugs: number
}
export interface NewFilterValues {
  b_name: FilterItem[]
  vb_name: FilterItem[]
  am_name: FilterItem[]
  vb_platform: FilterItem[]
  error_category: FilterItem[]
  error_subcategory: FilterItem[]
}

export interface NewFilterValuesSet {
  b_name: Set<string>
  am_name: Set<string>
  vb_name: Set<string>
  error_category: Set<string>
  vb_platform: Set<string>
  error_subcategory: Set<string>
}
export interface FilterValuesType {
  b_name: any[]
  vb_name: any[]
  vb_platform: any[]
  am_name: any[]
  error_category: { value: string; name: string }[]
  error_subcategory: { value: string; name: string }[]
}
export type CustomFilterTagType = {
  label: string
  title: string
  multiple?: boolean
  options: {
    name: string
    value: string
  }[]
} & {
  multiple: true
  initailSelectAll?: boolean
}
interface FilterContextInterface {
  isLoading: boolean
  filters: FilterType[]
  filterValues: FilterValuesType
  handleFilterValueChange: (value: any, index: any, shouldPropagate?: boolean) => void
  getFilters: (filterList: any) => any
  onDateRangeSelect: (any) => void
  dateRange: any
  getUrlWithFilters: () => string
  initialFiltersData: any
  customDateFilterPages: any[]
  attribute: boolean
  setDateRange: any
  setAttribute: React.Dispatch<React.SetStateAction<boolean>>
  getFiltersV2: (filterList?: string[], dateWithHyphen?: boolean) => FiltersForQuery
  customFilterTags: CustomFilterTagType[]
  setCustomFilterTags: React.Dispatch<React.SetStateAction<CustomFilterTagType[]>>
  setCustomFilterValues: React.Dispatch<React.SetStateAction<{ [label: string]: string[] | string }>>
  bNames: { b_name: string }[]
  refreshFilters(uid: string, org: string): Promise<void>
  setVisibleBNames: React.Dispatch<React.SetStateAction<string[]>>
}

interface DateCheckerProps {
  start_date: string
  end_date: string
}

const initialState = {} as FilterContextInterface

const FilterContext = createContext<FilterContextInterface>(initialState)

export const useFilter = () => {
  const filterContextV1 = useContext(FilterContext)
  const filterContextV2 = useContext(NewFilterContext)
  const mapping = {
    ...filterContextV2,
    isLoading: filterContextV2.filterDataLoading
  }

  const shouldHaveNewFilter = React.useMemo(() => {
    return get(filterContextV2.filterConfig, 'showNewFilters', false)
  }, [filterContextV2.filterConfig])

  return { ...filterContextV1, ...(shouldHaveNewFilter ? { ...mapping } : {}) }
}

const FilterContextProvider = ({ children }: { children: React.ReactNode }) => {
  const { currentUser, logout, posthogCapture, setCurrentUser } = useAuth()
  const location = useLocation()
  const { handleError, asyncWrapper, handleAsync } = useErrorData()
  const { stack: drillDownStack, setDateRange: setDrillDownDateRange, getDrillDownFilter: getDrillDownContextFilter } = useDrillDownContext()
  const originalInitialFiltersData = useRef([])
  const [isLoading, setIsLoading] = useState(true)
  const [attribute, setAttribute] = useState(false)
  const [customDateFilterPages, setCustomDateFilterPages] = useState([])
  const { refreshNeeded, localData } = useValidateLocalData()
  const [customFilterTags, setCustomFilterTags] = useState<CustomFilterTagType[]>([])
  const [customFilterValues, setCustomFilterValues] = useState<{
    [label: string]: string | string[]
  }>({})
  const [bNames, setBNames] = useState<{ b_name: string }[]>([])
  const [bNamesIdMap, setBNamesIdMap] = useState<{ [b_name: string]: number[] }>(
    localStorage.getItem('bNamesIdMap') ? JSON.parse(localStorage.getItem('bNamesIdMap')) : {}
  )
  const [visibleBNames, setVisibleBNames] = React.useState<string[]>(undefined)

  const [filters, setFilters] = useState([
    {
      key: 'b_name',
      name: filterNames.b_name,
      selected: [],
      selectedSetOfValues: new Set<string>(),
      selectedSetOfValuesDisplayed: new Set<string>()
    },
    {
      key: 'vb_name',
      name: filterNames.vb_name,
      selected: [],
      selectedSetOfValues: new Set<string>(),
      selectedSetOfValuesDisplayed: new Set<string>()
    },
    {
      key: 'vb_platform',
      name: filterNames.vb_platform,
      selected: [],
      selectedSetOfValues: new Set<string>(),
      selectedSetOfValuesDisplayed: new Set<string>()
    },
    {
      key: 'am_name',
      name: 'Account Manager',
      selected: [],
      selectedSetOfValues: new Set<string>(),
      selectedSetOfValuesDisplayed: new Set<string>()
    }
  ])
  const [initialFiltersData, setInitialFiltersData] = useState([])
  const [dateRange, setDateRange] = useState(moment.range(moment(new Date()).subtract(29, 'days'), moment(new Date())))
  const [filterValues, setFilterValues] = useState({
    b_name: [],
    vb_name: [],
    vb_platform: [],
    am_name: [],
    error_category: [],
    error_subcategory: []
  })

  async function getFastFiltersData(org: string) {
    try {
      const response = await DefaultService.getChainFiltersChainFiltersPost({
        chain_in: org
      })
      return response
    } catch (error) {
      handleError(error.message)
    }
  }

  async function saveFilters(
    newFilterValuesSet: NewFilterValuesSet,
    businessRefressData: BusinessRefressResponse[],
    parameters: URLSearchParams,
    filtersData: FilterResponse[],
    bNames: { b_name: string }[],
    refreshFilters?: boolean
  ) {
    const tempNewFilters = [
      {
        key: 'b_name',
        name: filterNames.b_name,
        selected: [],
        selectedSetOfValues: new Set<string>(),
        selectedSetOfValuesDisplayed: new Set<string>()
      },
      {
        key: 'vb_name',
        name: filterNames.vb_name,
        selected: [],
        selectedSetOfValues: new Set<string>(),
        selectedSetOfValuesDisplayed: new Set<string>()
      },
      {
        key: 'vb_platform',
        name: filterNames.vb_platform,
        selected: [],
        selectedSetOfValues: new Set<string>(),
        selectedSetOfValuesDisplayed: new Set<string>()
      },
      {
        key: 'am_name',
        name: 'Account Manager',
        selected: [],
        selectedSetOfValues: new Set<string>(),
        selectedSetOfValuesDisplayed: new Set<string>()
      }
    ]
    const newFilters = refreshFilters ? tempNewFilters : [...filters]
    try {
      const newFilterValues: NewFilterValues = {
        b_name: bNames.map((item) => ({
          name: item.b_name,
          value: item.b_name
        })),
        am_name: [...newFilterValuesSet.am_name].map((item) => ({
          name: item,
          value: item
        })),
        vb_name: [...newFilterValuesSet.vb_name].map((item) => ({
          name: item,
          value: item
        })),
        vb_platform: [...newFilterValuesSet.vb_platform].map((item) => ({
          name: item,
          value: item
        })),
        error_category: [...newFilterValuesSet.error_category].map((item) => ({
          name: item,
          value: item
        })),
        error_subcategory: [...newFilterValuesSet.error_subcategory].map((item) => ({ name: item, value: item }))
      }
      let startDate = parameters.get('start_date')
      let endDate = parameters.get('end_date')
      if (!startDate || !endDate) {
        startDate = sessionStorage.getItem('start_date')
        endDate = sessionStorage.getItem('end_date')
      }
      newFilters.forEach((filter) => {
        if (!filter.selected.length) {
          filter.selected = [...newFilterValues[filter.key]]
        }
      })
      const tempobj = {
        ...newFilterValues,
        b_name: bNames.map((item) => ({
          name: item.b_name,
          value: item.b_name
        }))
      }
      setFilterValues(tempobj)
      setFilters(newFilters)

      let range = null

      if (startDate && endDate) {
        range = moment.range(moment(startDate, 'YYYYMMDD').clone(), moment(endDate, 'YYYYMMDD').clone())
      } else {
        if ((!businessRefressData || businessRefressData?.length === 0) && currentUser.org !== 'KGR' && location.pathname !== '/dashboard-main') {
          setIsLoading(false)
          return
        }
        const lastDate = get(businessRefressData, '[0].lastDate', null)
        if (lastDate) {
          const datePrev = new Date()
          const dateCurr = new Date()
          const lastDataDate = new Date([0, 5, 6].includes(dateCurr.getDay()) ? datePrev.setDate(datePrev.getDate() + (5 - 7 - datePrev.getDay())) : lastDate)
          range = moment.range(moment(lastDataDate).subtract(29, 'days'), moment(lastDataDate))
        } else {
          range = moment.range(moment(new Date()).subtract(29, 'days'), moment(new Date()))
        }
      }
      setDateRange(range)
      try {
        setLocal('dateRange', range.toString())
      } catch (error) {
        logout()
      }
    } catch (error) {
      handleError(error.message)
    }
  }

  async function fetchFilters(parameters: any, bNames, refreshFilters?: boolean, org?: string) {
    const localUser = currentUser
    const tempOrg = org || currentUser.org
    const host_kitchens = get(currentUser, 'host_kitchens', [])
    setLocal('host_kitchen_length', host_kitchens.length)
    if (localUser) setLocal('userOrg', tempOrg)
    try {
      if (!tempOrg) return
      localStorage.setItem('storageDate', new Date().toDateString())
      setIsLoading(true)
      const result = await Promise.all([getFastFiltersData(currentUser.org)])
      let filtersData = result[0]
      filtersData = bNames.items.length > 0 ? filtersData.filter((item) => some(bNames.items, { name: item.b_name })) : filtersData

      let tempBNames =
        bNames.items.length > 0
          ? uniqBy(bNames.items, 'name').map((item: any) => ({
              name: item.name,
              value: item.name,
              id: item.id
            }))
          : uniqBy(filtersData, 'b_name').map((item: any) => ({
              name: item.b_name,
              value: item.b_name,
              id: item.b_name_id
            }))
      originalInitialFiltersData.current = filtersData || []
      setInitialFiltersData(originalInitialFiltersData.current)
      const newFilterValuesSet: NewFilterValuesSet = {
        b_name: new Set(),
        vb_name: new Set(),
        vb_platform: new Set(),
        am_name: new Set(),
        error_category: new Set(),
        error_subcategory: new Set()
      }

      let b_name_id_map: typeof bNamesIdMap = {}

      filtersData.forEach((item) => {
        const b_name_id: number = item.b_name_id
        const b_name: string = item.b_name
        b_name_id_map[b_name] = [...new Set([...get(b_name_id_map, b_name, []), b_name_id])]
      })

      if (tempBNames.length > 0) {
        tempBNames.forEach((item) => {
          newFilterValuesSet.b_name.add(item.name)
        })
      } else {
        filtersData.forEach((item) => {
          newFilterValuesSet.b_name.add(item.b_name)
        })
      }

      localStorage.setItem('bNamesIdMap', JSON.stringify(b_name_id_map))
      setBNamesIdMap(b_name_id_map)

      filtersData.forEach((item) => {
        // newFilterValuesSet.b_name.add(item.b_name) // this field is coming from new api now
        newFilterValuesSet.vb_name.add(item.vb_name)
        newFilterValuesSet.am_name.add(item.am_name)
        newFilterValuesSet.vb_platform.add(item.vb_platform)
        newFilterValuesSet.error_category.add(item.error_category)
        newFilterValuesSet.error_subcategory.add(item.error_subcategory)
      })
      saveFilters(
        newFilterValuesSet,
        null,
        parameters,
        filtersData,
        tempBNames.map((item) => ({ b_name: item.name })),
        refreshFilters ? refreshFilters : true
      )
    } catch (error) {
      handleError(error.message)
    }
    setIsLoading(false)
  }

  async function refreshFilters(uid: string, org: string) {
    const queryString = window.location.search
    const parameters = new URLSearchParams(queryString)
    const bNames = await UsersService.getBnamesByUserNoAuthUserBnamesNoAuthGet(uid, 100000)
    await fetchFilters(parameters, bNames, true, org)
    window.document.location.reload()
  }

  useEffect(() => {
    let unsubscribe

    if (currentUser && currentUser.org) {
      const query = doc(firebaseDb, `users/${currentUser.uid}`)
      unsubscribe = onSnapshot(query, (snapshot) => {
        const data = snapshot.data()

        if (!data) {
          return
        }

        if (data?.org !== currentUser?.org) {
          setCurrentUser({
            ...currentUser,
            ...data
          })
          // setLocal('userOrg', data.org)
          refreshFilters(currentUser.uid, data.org)
        }
      })
    }

    return () => {
      if (unsubscribe) {
        unsubscribe()
      }
    }
  }, [currentUser])

  useEffect(() => {
    handleAsync(async () => {
      if (currentUser && currentUser.org) {
        const bNames = await UsersService.getBnamesByUserNoAuthUserBnamesNoAuthGet(currentUser.uid, 100000)
        // const bNames = await UsersService.getBnamesByUserUserBnamesGet(100000)
        let tempBNames = uniqBy(bNames.items, 'name').map((item) => ({
          name: item.name,
          value: item.name
        }))
        const queryString = window.location.search
        const parameters = new URLSearchParams(queryString)

        if (tempBNames.length > 0) setBNames(tempBNames.map((item) => ({ b_name: item.name })))
        let filterParams: any = JSON.parse(parameters.get('filters'))
        if (!filterParams) {
          filterParams = JSON.parse(sessionStorage.getItem('filters')) || {}
        }

        const userOrg = getLocal('userOrg')
        const host_kitchen_length = getLocal('host_kitchen_length')
        const bNameCondition = bNames.items.length > 0
        const fresh_host_kitchens_needed = get(currentUser, 'host_kitchens', []).length !== host_kitchen_length
        const orgCondition = get(currentUser, 'org') === userOrg
        if (
          (localData && refreshNeeded) ||
          !localData ||
          // !orgCondition ||
          fresh_host_kitchens_needed ||
          bNameCondition
        ) {
          fetchFilters(parameters, bNames)
        }
      }
    })
  }, [refreshNeeded, localData, currentUser])

  // THIS FUNCTION WILL CHECK IF FILTERVALUES ARE CHANGED
  // IF CHANGED THEN IT WILL UPDATE LOCAL DATA ALSO
  // THIS WILL RUN IN BACKGROUND
  // SHOULD NOT BLOCK UI
  // async function checkLatestFilter() {
  //   try {
  //     await new Promise(res => {
  //       setTimeout(() => {
  //         res(true)
  //       }, 3000)
  //     })
  //       let result: any = await Promise.allSettled([
  //         getFastFiltersData(currentUser.org),
  //         DefaultService.runProcedureAndGetDataV2RunQueryV2Post(
  //           'business_refresh_chain_level',
  //           {
  //             chain_in: currentUser.org || ''
  //           }
  //         )
  //       ])
  //       result = result.map(item => item.value)
  //       const host_kitchens = get(currentUser, 'host_kitchens', [])
  //       let filtersData = result[0]
  //       filtersData = host_kitchens.length > 0 ? filtersData.filter(item => host_kitchens.includes(item.b_name)): filtersData
  //       setInitialFiltersData(filtersData)

  //       const newFilterValuesSet: NewFilterValuesSet = {
  //         b_name: new Set(),
  //         vb_name: new Set(),
  //         vb_platform: new Set(),
  //         am_name: new Set(),
  //         error_category: new Set(),
  //         error_subcategory: new Set()
  //       }

  //       filtersData.forEach(item => {
  //         newFilterValuesSet.b_name.add(item.b_name)
  //         newFilterValuesSet.vb_name.add(item.vb_name)
  //         newFilterValuesSet.am_name.add(item.am_name)
  //         newFilterValuesSet.vb_platform.add(item.vb_platform)
  //         newFilterValuesSet.error_category.add(item.error_category)
  //         newFilterValuesSet.error_subcategory.add(item.error_subcategory)
  //       })

  //       if (
  //         newFilterValuesSet.b_name.size !== filterValues.b_name.length ||
  //         newFilterValuesSet.vb_name.size !== filterValues.vb_name.length ||
  //         newFilterValuesSet.am_name.size !== filterValues.am_name.length ||
  //         newFilterValuesSet.vb_platform.size !== filterValues.vb_platform.length ||
  //         newFilterValuesSet.error_category.size !== filterValues.error_category.length ||
  //         newFilterValuesSet.error_subcategory.size !== filterValues.error_subcategory.length
  //       ) {
  //         // THIS CONDITION WILL BE TRUE WHEN FILTER HAS ANY UPDATE
  //         const queryString = window.location.search
  //         const parameters = new URLSearchParams(queryString)

  //         saveFilters(
  //           newFilterValuesSet,
  //           result[1],
  //           parameters,
  //           filtersData
  //         )
  //       } else {
  //       }
  //   } catch (error) {
  //     handleError(error.message)
  //   }
  // }

  // React.useEffect(() => {
  //   if (
  //     filterValues.b_name.length > 0 &&
  //     filterValues.am_name.length > 0 &&
  //     filterValues.vb_name.length > 0 &&
  //     filterValues.vb_platform.length > 0 &&
  //     filterValues.error_category.length > 0 &&
  //     filterValues.error_subcategory.length > 0
  //   ) {
  //     checkLatestFilter()
  //   }
  // },[
  //   filterValues.b_name,
  //   filterValues.am_name,
  //   filterValues.vb_name,
  //   filterValues.vb_platform,
  //   filterValues.error_category,
  //   filterValues.error_subcategory
  // ])

  const isSelectedAll = (index) => {
    const key = filters[index].key
    const filterKey = index === 0 && bNames.length > 0 ? bNames : initialFiltersData
    const uniqueValues = [...new Map(filterKey.map((item) => [item[key], item])).values()]
    const selectedItemObj = filters[index].selected.reduce(function (acc, cur) {
      if (cur && cur.value) acc[cur.value] = cur.value
      return acc
    }, {})
    return uniqueValues.filter((item) => item[key] && item[key].length > 0).every((item) => selectedItemObj[item[key]])
  }

  const getFilters = (filterList = []) => {
    const host_kitchens = bNames
    return filterList.map((filter) => {
      const drillDownContextValue = getDrillDownContextFilter ? getDrillDownContextFilter(filter) : null
      if (drillDownContextValue) {
        return drillDownContextValue
      }
      if (filter === 'b_name') {
        return isSelectedAll(0) && host_kitchens.length === 0
          ? ''
          : filters[0].selected
              .filter((item) => item.value && item.value.length > 0)
              .map((item) => item.value)
              .join('|')
      }
      if (filter === 'b_name_id') {
        return isSelectedAll(0) && host_kitchens.length === 0
          ? ''
          : filters[0].selected
              .filter((item) => item.value && item.value.length > 0 && item.value in bNamesIdMap)
              .map((item) => bNamesIdMap[item.value].join('|'))
              .join('|')
      }
      if (filter === 'vb_name') {
        return isSelectedAll(1) && host_kitchens.length === 0
          ? ''
          : filters[1].selected
              .filter((item) => item.value && item.value.length > 0)
              .map((item) => item.value)
              .join('|')
      }
      if (filter === 'vb_platform') {
        return isSelectedAll(2) && host_kitchens.length === 0
          ? ''
          : filters[2].selected
              .filter((item) => item.value && item.value.length > 0)
              .map((item) => item.value)
              .join('|')
      }
      if (filter === 'am_name') {
        return isSelectedAll(3) && host_kitchens.length === 0
          ? ''
          : filters[3].selected
              .filter((item) => item.value && item.value.length > 0)
              .map((item) => item.value)
              .join('|')
      }
      if (filter === 'chain') {
        return currentUser.org || ''
      }
      if (filter === 'start_date') {
        return dateRange?.start.format('YYYYMMDD') || ''
      }
      if (filter === 'end_date') {
        return dateRange?.end.format('YYYYMMDD') || ''
      }
      if (filter in customFilterValues) {
        const value = customFilterValues[filter]
        if (Array.isArray(value)) {
          return value.join('|')
        } else {
          return value
        }
      }
      if (filter[0] === '_') {
        return filter.slice(1)
      }
      if (filter === 'granularity') {
        let start_date = dateRange?.start
        let end_date = dateRange?.end
        if (start_date && end_date) {
          return findByDates(start_date, end_date)
        } else {
          return ''
        }
      }
      return ''
    })
  }

  React.useEffect(() => {
    if (drillDownStack.length > 0) {
      return
    }
    const { start_date_in: start, end_date_in: end } = getFiltersV2(['start_date', 'end_date'], true)
    if (start && end) {
      setDrillDownDateRange({ start: moment(start), end: moment(end) })
    }
  }, [drillDownStack, ...getFilters(['start_date', 'end_date'])])

  const updateFilters = (filters, index?: any) => {
    const newFilterValues = { ...filterValues }

    const filtersData = initialFiltersData ? [...initialFiltersData] : []

    // compute unique values for each filter before filtering
    const uniqueValues = {}
    filtersData.forEach((item) => {
      Object.keys(item).forEach((key) => {
        if (!uniqueValues[key]) {
          uniqueValues[key] = new Set()
        }
        uniqueValues[key].add(item[key])
      })
    })

    // compute selected values for each filter
    const selectedObjects = {}
    filters.forEach((filter) => {
      selectedObjects[filter.key] = filter.selected
    })

    // compute selected values for each filter optimized for performance
    const selectedObjectsAsMapOfSets = {}
    filters.forEach((filter) => {
      selectedObjectsAsMapOfSets[filter.key] = new Set(filter.selected.map((item) => item.value))
      filter.selectedSetOfValues = selectedObjectsAsMapOfSets[filter.key]
    })

    // for each column, create a filtered data view that filters based on all other columns. Take the unique values and set it as newFilterValues
    Object.keys(uniqueValues).forEach((key) => {
      // create a filtered data view that filters based on all other columns
      const filteredData = filtersData.filter((item) => {
        // check if the item passes all other filters
        let passesAllOtherFilters = true

        // for each filter, check if the item passes the filter
        filters.forEach((filter) => {
          // if the filter is the current column, skip it
          if (filter.key === key) {
            return
          }
          // if the filter is not selected, skip it
          if (filter.selected.length === 0) {
            return
          }
          // if the item does not pass the filter, set passesAllOtherFilters to false
          if (!selectedObjectsAsMapOfSets[filter.key].has(item[filter.key])) {
            passesAllOtherFilters = false
          }
        })
        // return the item if it passes all other filters
        return passesAllOtherFilters
      })

      // get the unique items for the column
      const uniqueItems = uniqBy(filteredData, key)

      // set the new filter items for the column
      newFilterValues[key] = uniqueItems.map((item) => {
        return {
          value: item[key],
          name: item[key]
        }
      })
    })

    // also show selected values in the filter if they are not in the filtered data and not all values are selected.
    // But do it only if less than half of the unique values are selected.

    try {
      // this will break if user has no filters available
      Object.keys(selectedObjects).forEach((key) => {
        // if select values are atleast half of the unique values, skip it
        try {
          if (selectedObjects[key].length >= uniqueValues[key].size / 2) {
            return
          }

          // for each selected value, check if it is in the filtered data
          selectedObjects[key].forEach((selected) => {
            // if the selected value is not in the filtered data, add it
            if (!newFilterValues[key].find((item) => item.value === selected.value)) {
              newFilterValues[key].push(selected)
            }
          })
        } catch (error) {
          handleError('no filters available')
        }
      })
    } catch (error) {
      handleError('No filters available')
    }

    //populate selectedSetOfValuesDisplayed for each filter based on newFilterValues and selectedSetOfValues
    filters.forEach((filter) => {
      filter.selectedSetOfValuesDisplayed = new Set(
        newFilterValues[filter.key].map((item) => item.value).filter((value) => filter.selectedSetOfValues.has(value))
      )
    })

    const hasVisibleBNames = Array.isArray(visibleBNames) && visibleBNames.length >= 0
    setFilters(filters)
    const tempobj = {
      ...newFilterValues,
      b_name: hasVisibleBNames
        ? visibleBNames.filter((val) => bNames.map((b) => b.b_name).includes(val)).map((val) => ({ name: val, value: val }))
        : bNames.length > 0
          ? bNames.map((item) => ({ name: item.b_name, value: item.b_name }))
          : newFilterValues.b_name
    }
    setFilterValues(tempobj)
  }

  const originalBNames = useRef([])
  const selectedBNames = useRef([])
  useEffect(() => {
    const hasVisibleBNames = Array.isArray(visibleBNames) && visibleBNames.length > 0
    if (!hasVisibleBNames) {
      selectedBNames.current = filters.find((e) => e.key === 'b_name')?.selected || []
    }
  }, [filters])
  useEffect(() => {
    // selectedBNames.current = selectedBNames.current.length === 0 ? filters.find((e) => e.key === 'b_name')?.selected || [] : selectedBNames.current
    if (Array.isArray(visibleBNames) && visibleBNames.length > 0) {
      const visibleBNamesList = visibleBNames.map((val) => ({ name: val, value: val }))
      let newFilters: any
      setFilterValues((prev) => {
        originalBNames.current = originalBNames.current.length === 0 ? prev.b_name : originalBNames.current
        newFilters = {
          ...prev,
          b_name: visibleBNames
        }
        return newFilters
      })
      setInitialFiltersData((prev) => {
        return prev.filter((e) => visibleBNames.includes(get(e, 'b_name', undefined)))
      })
      setTimeout(() => {
        handleFilterValueChange(
          // filters.map((e) => (e.key === 'b_name' ? { ...e, selected: visibleBNamesList } : { ...e })),
          visibleBNamesList,
          0
        )
      }, 500)
    } else if (Array.isArray(originalBNames.current) && originalBNames.current.length > 0) {
      let newFilters: any
      setFilterValues((prev) => {
        newFilters = {
          ...prev,
          b_name: originalBNames.current
        }
        return newFilters
      })
      setInitialFiltersData(originalInitialFiltersData.current)
      let modifiedFilters: typeof filters
      setFilters((prev) => {
        modifiedFilters = prev.map((e) => (e.key === 'b_name' ? { ...e, selected: selectedBNames.current } : { ...e }))
        return modifiedFilters
      })
      setTimeout(() => {
        handleFilterValueChange(selectedBNames.current, 0)
      }, 500)
    }
  }, [visibleBNames])

  const handleFilterValueChange = (value, index, shouldPropagateToOtherFilters = true) => {
    const newFilters = [...filters]
    const values = value
    if (values.length > 0) {
      const lastElement = values[values.length - 1]
      if (lastElement) {
        const filtered = values.slice(0, values.length - 1)
        const idx = filtered.findIndex((item) => item.value === lastElement.value)
        if (idx === -1) {
          filtered.push(lastElement)
        } else {
          filtered.splice(idx, 1)
        }
        newFilters[index].selected = filtered
      }
    } else {
      newFilters[index].selected = []
    }
    setFilters(newFilters)
    if (shouldPropagateToOtherFilters) {
      updateFilters(newFilters, index)
      navigateToFilterAndDateChange(newFilters, dateRange)
    }

    const tempValues = value
      .filter((item) => item)
      .map((item) => item.value)
      .join('|')
    posthogCapture({
      label: 'Filter Change',
      data: {
        email: currentUser?.email,
        filter: filters[index].key,
        value: isSelectedAll(index) ? 'All Selected' : tempValues
      }
    })
    sendSlackNotification({
      title: 'Filter Change',
      message: `${currentUser?.email} changed Filter: ${filters[index].key} to Value: ${isSelectedAll(index) ? 'All Selected' : tempValues}`,
      channel: 'fe-logs'
    })
  }

  const navigateToFilterAndDateChange = (filters, dateRange) => {
    const filterParams = {
      b_name: filters[0].selected.map((item) => item.value).join('|'),
      vb_name: filters[1].selected.map((item) => item.value).join('|'),
      vb_platform: filters[2].selected.map((item) => item.value).join('|'),
      am_name: filters[3].selected.map((item) => item.value).join('|')
    }
    sessionStorage.setItem('filterdata', JSON.stringify(filters))
    sessionStorage.setItem('filters', JSON.stringify(filterParams))
    sessionStorage.setItem('start_date', dateRange?.start.format('YYYYMMDD') || '')
    sessionStorage.setItem('end_date', dateRange?.end.format('YYYYMMDD') || '')
    // navigate({
    //     pathname: location.pathname,
    //     search: createSearchParams({
    //         filters: JSON.stringify(filterParams),
    //         start_date: dateRange?.start.format('YYYYMMDD') || '',
    //         end_date: dateRange?.end.format('YYYYMMDD') || '',
    //     }).toString()
    // });
  }

  const getUrlWithFilters = () => {
    const filterParams = {
      b_name: filters[0].selected.map((item) => item.value).join('|'),
      vb_name: filters[1].selected.map((item) => item.value).join('|'),
      vb_platform: filters[2].selected.map((item) => item.value).join('|'),
      am_name: filters[3].selected.map((item) => item.value).join('|')
    }
    const params = createSearchParams({
      filters: JSON.stringify(filterParams),
      start_date: dateRange?.start.format('YYYYMMDD') || '',
      end_date: dateRange?.end.format('YYYYMMDD') || ''
    }).toString()

    return `${window.location.origin}${location.pathname}?${params}`
  }

  const onDateRangeSelect = (value) => {
    setDateRange(value)
    setLocal('dateRange', value.toString())
    navigateToFilterAndDateChange(filters, value)
  }

  const getFiltersV2 = (filterList = [], dateWithHyphen: boolean = true) => {
    const obj: FiltersForQuery = { b_name_ids_in: '', chain_in: '' }
    filterList.forEach((item) => {
      const drillDownContextValue = getDrillDownContextFilter ? getDrillDownContextFilter(item) : null
      if (drillDownContextValue) {
        obj[`${item}_in`] = drillDownContextValue
        return
      }
      if (item === 'b_name') {
        // obj['b_name_in'] = isSelectedAll(0)
        //   ? ''
        //   : filters[0].selected.map((item) => item.value).join('|')
        const bNameData =
          isSelectedAll(0) && bNames.length === 0
            ? ''
            : filters[0].selected
                .filter((item) => item.value && item.value.length > 0)
                .map((item) => item.value)
                .join('|')
        obj['b_name_in'] = bNameData
      } else if (item === 'b_name_id') {
        obj['b_name_ids_in'] =
          isSelectedAll(0) && bNames.length === 0
            ? ''
            : filters[0].selected
                .filter((item) => item.value && item.value.length > 0 && item.value in bNamesIdMap)
                .map((item) => bNamesIdMap[item.value].join('|'))
                .join('|')
      } else if (item === 'vb_name') obj['vb_name_in'] = isSelectedAll(1) ? '' : filters[1].selected.map((item) => item.value).join('|')
      else if (item === 'vb_platform') {
        obj['vb_platform_in'] = isSelectedAll(2) ? '' : filters[2].selected.map((item) => item.value).join('|')
        obj['platform_in'] = isSelectedAll(2) ? '' : filters[2].selected.map((item) => item.value).join('|')
      } else if (item === 'am_name') obj['am_name_in'] = isSelectedAll(3) ? '' : filters[3].selected.map((item) => item.value).join('|')
      else if (item === 'chain') obj['chain_in'] = currentUser.org || ''
      if (item === 'start_date') obj['start_date_in'] = dateRange?.start.format(dateWithHyphen ? 'YYYY-MM-DD' : 'YYYYMMDD') || ''
      if (item === 'end_date') obj['end_date_in'] = dateRange?.end.format(dateWithHyphen ? 'YYYY-MM-DD' : 'YYYYMMDD') || ''
      else if (item[0] === '#') {
        const data = item.split('#')
        obj[`${data[1]}_in`] = data[2]
      } else if (item === 'granularity') {
        let start_date = dateRange?.start
        let end_date = dateRange?.end
        if (start_date && end_date) {
          obj[`${item}_in`] = findByDates(start_date, end_date)
          obj['should_adjust_dates'] = true
        }
      }
    })
    return obj
  }
  let findByDates = React.useCallback(
    function ({ start_date, end_date }: DateCheckerProps) {
      let diff = moment(end_date).diff(moment(start_date), 'days')
      if (diff > 180) {
        return 'month'
      } else if (diff > 30) {
        return 'week'
      } else {
        return 'day'
      }
    },
    [...getFilters(['start_date', 'end_date'])]
  )

  React.useEffect(() => {}, [...getFilters(['start_date', 'end_date'])])

  const contextValue = useMemo(
    () => ({
      isLoading,
      filters,
      filterValues,
      handleFilterValueChange,
      getFilters,
      onDateRangeSelect,
      dateRange,
      setDateRange,
      getUrlWithFilters,
      initialFiltersData,
      attribute,
      setAttribute,
      getFiltersV2,
      customDateFilterPages,
      customFilterTags,
      setCustomFilterTags,
      setCustomFilterValues,
      bNames,
      refreshFilters,
      setVisibleBNames
    }),
    [
      isLoading,
      filters,
      filterValues,
      handleFilterValueChange,
      getFilters,
      onDateRangeSelect,
      dateRange,
      setDateRange,
      getUrlWithFilters,
      initialFiltersData,
      attribute,
      setAttribute,
      getFiltersV2,
      customDateFilterPages,
      customFilterTags,
      setFilterValues,
      setCustomFilterValues,
      bNames,
      refreshFilters,
      setVisibleBNames
    ]
  )
  return <FilterContext.Provider value={contextValue}>{children}</FilterContext.Provider>
}

export default FilterContextProvider
