import { Grid } from '@mui/material'
import { debounce, get } from 'lodash'
import { useEffect, useMemo, useRef, useState } from 'react'
import { TooltipLabelsType } from 'src/components/Charts/SyncedChart/ChartTooltip'
import SyncedChart from 'src/components/Charts/SyncedChart/SyncedChart'
import { useFilter } from 'src/context/FilterContext'
import { useSnackData } from 'src/context/SnackContext'
import DrillDownWrapper, { DrillDownWrapperProps } from 'src/pages/members/DrillDown/components/DrillDownWrapper'
import { useSyncedChartsContext } from 'src/pages/members/MarketingPages/Trends/context/SyncedChartsContext'
import { AdsDetailsAggregate, AdsDetailsDatewise, DefaultService } from 'src/services/openApi'
import { useMarketingTrendsLocalContext } from '../../Trends/Trends'

const defaultTooltipLabels: TooltipLabelsType = {
  marketing_driven_sales: {
    label: 'Marketing Driven Sales',
    shortLabel: 'Mktg Sales',
    format: 'currency',
    loading: true
  },
  marketing_spend: {
    label: 'Marketing Spend',
    shortLabel: 'Mktg Spend',
    format: 'currency',
    loading: true
  },
  impressions: {
    label: 'Impressions',
    format: null,
    loading: true
  },
  clicks: {
    label: 'Clicks',
    format: null,
    loading: true
  },
  impressions_to_clicks: {
    label: 'Impressions to Clicks Ratio',
    shortLabel: 'I/C Ratio',
    format: null,
    loading: true
  },
  orders: {
    label: 'Orders',
    format: null,
    loading: true
  },
  clicks_to_order: {
    label: 'Clicks to Orders Ratio',
    shortLabel: 'C/O Ratio',
    format: null,
    loading: true
  }
}

type AdsCampaignType = AdsDetailsDatewise & {
  marketing_driven_sales: number
  marketing_spend: number
}

const ConversionMetricsCharts = () => {
  const { openError } = useSnackData()
  const { overview, ads } = useSyncedChartsContext()
  const { channel, fulfillment } = useMarketingTrendsLocalContext()
  const { aggregateData: overviewData } = overview
  const { activeIndex, setActiveIndex, visibleTooltip, setVisibleTooltip, aggregateData, setAggregateData } = ads
  const { getFilters, getFiltersV2 } = useFilter()
  const chartFiltersArr = ['v_name', 'vb_name', 'b_name', 'am_name', 'start_date', 'end_date', 'chain', 'vb_platform', 'granularity']
  const parentRef = useRef(null)
  const [parentWidth, setParentWidth] = useState(null)
  const [adsData, setAdsData] = useState<AdsCampaignType[]>([])
  const [adsDataAggregate, setAdsDataAggregate] = useState<AdsDetailsAggregate>(null)
  const [adsDataLoading, setAdsDataLoading] = useState(false)
  const [drillDownName, setDrillDownName] = useState<DrillDownWrapperProps['drillDownName']>(null)

  const filterObj = useMemo(() => {
    return {
      channel_in: channel?.join('|'),
      fulfillment_in: fulfillment?.join('|'),
      ...getFiltersV2(chartFiltersArr, true)
    }
  }, [channel, fulfillment, ...getFilters(chartFiltersArr)])

  const tooltipLabels = useMemo(
    () => ({
      ...defaultTooltipLabels,
      marketing_driven_sales: { ...defaultTooltipLabels.marketing_driven_sales, loading: adsDataLoading },
      marketing_spend: { ...defaultTooltipLabels.marketing_spend, loading: adsDataLoading },
      impressions: { ...defaultTooltipLabels.impressions, loading: adsDataLoading },
      clicks: { ...defaultTooltipLabels.clicks, loading: adsDataLoading },
      impressions_to_clicks: { ...defaultTooltipLabels.impressions_to_clicks, loading: adsDataLoading },
      orders: { ...defaultTooltipLabels.orders, loading: adsDataLoading },
      clicks_to_order: { ...defaultTooltipLabels.clicks_to_order, loading: adsDataLoading }
    }),
    [adsDataLoading]
  )

  async function getAdsData() {
    setAdsDataLoading(true)
    try {
      const res = await DefaultService.callAdsDetailsSummaryApiAdsDetailsSummaryPost(filterObj)
      const sortedData = res.ads_details_datewise.sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
      const dataWithOverviewData = sortedData.map((aData) => {
        const overview = overviewData.find((oData) => oData.date === aData.date)
        return {
          ...aData,
          marketing_driven_sales: get(overview, 'marketing_driven_sales', 0),
          marketing_spend: get(overview, 'marketing_spend', 0)
        }
      })

      setAdsData(dataWithOverviewData)
      setAdsDataAggregate(res.ads_details_aggregate)
      setAggregateData(dataWithOverviewData)
    } catch (error) {
      openError(error.message)
    }
    setAdsDataLoading(false)
  }

  useEffect(() => {
    getAdsData()
  }, [filterObj])

  useEffect(() => {
    if (overviewData) {
      const prev = adsData
      const updatedData = []

      prev?.map((aData) => {
        const overview = overviewData.find((oData) => oData.date === aData.date)
        updatedData.push({
          ...aData,
          marketing_driven_sales: get(overview, 'marketing_driven_sales', 0),
          marketing_spend: get(overview, 'marketing_spend', 0)
        })
      })

      setAdsData(updatedData)
      setAggregateData(updatedData)
    }
  }, [overviewData])

  useEffect(() => {
    const debouncedSetBoxWidth = debounce((width) => {
      setParentWidth(width)
    }, 500)

    const resizeObserver = new ResizeObserver((entries) => {
      for (const entry of entries) {
        debouncedSetBoxWidth(entry.contentRect.width)
      }
    })

    if (parentRef.current) {
      resizeObserver.observe(parentRef.current)
    }

    return () => {
      if (parentRef.current) {
        resizeObserver.unobserve(parentRef.current)
      }
      debouncedSetBoxWidth.cancel()
    }
  }, [])

  return (
    <>
      <DrillDownWrapper
        drillDownName={drillDownName}
        onClose={() => {
          setDrillDownName(null)
        }}
      />
      <Grid
        container
        spacing={2}>
        <Grid
          ref={parentRef}
          item
          lg={6}
          xs={12}>
          <SyncedChart
            showLegend={false}
            syncId="conversion"
            title="Impressions"
            loading={adsDataLoading}
            data={adsData || []}
            dataKey="impressions"
            parentWidth={parentWidth}
            tooltipLabels={tooltipLabels}
            summaryData={adsDataAggregate}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
            onTitleClick={() => {
              setDrillDownName('Impressions')
            }}
          />
        </Grid>

        <Grid
          item
          lg={6}
          xs={12}>
          <SyncedChart
            showLegend={false}
            syncId="conversion"
            title="Clicks"
            loading={adsDataLoading}
            data={adsData || []}
            dataKey="clicks"
            parentWidth={parentWidth}
            tooltipLabels={tooltipLabels}
            summaryData={adsDataAggregate}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
            onTitleClick={() => {
              setDrillDownName('Clicks')
            }}
          />
        </Grid>

        <Grid
          item
          lg={6}
          xs={12}>
          <SyncedChart
            showLegend={false}
            syncId="conversion"
            title="Impressions to Clicks Ratio"
            loading={adsDataLoading}
            data={adsData || []}
            dataKey="impressions_to_clicks"
            parentWidth={parentWidth}
            tooltipLabels={tooltipLabels}
            summaryData={adsDataAggregate}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
            onTitleClick={() => {
              setDrillDownName('ImpressionsToClicks')
            }}
          />
        </Grid>

        <Grid
          item
          lg={6}
          xs={12}>
          <SyncedChart
            syncId="conversion"
            title="Orders"
            loading={adsDataLoading}
            data={adsData || []}
            dataKey="orders"
            parentWidth={parentWidth}
            tooltipLabels={tooltipLabels}
            summaryData={adsDataAggregate}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
            onTitleClick={() => {
              setDrillDownName('Orders')
            }}
          />
        </Grid>

        <Grid
          item
          lg={6}
          xs={12}>
          <SyncedChart
            showLegend={false}
            syncId="conversion"
            title="Clicks to Orders Ratio"
            loading={adsDataLoading}
            data={adsData || []}
            dataKey="clicks_to_order"
            parentWidth={parentWidth}
            tooltipLabels={tooltipLabels}
            summaryData={adsDataAggregate}
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
            visibleTooltip={visibleTooltip}
            setVisibleTooltip={setVisibleTooltip}
            aggregateData={aggregateData}
            onTitleClick={() => {
              setDrillDownName('ClicksToOrders')
            }}
          />
        </Grid>
      </Grid>
    </>
  )
}

export default ConversionMetricsCharts
