import { Box } from '@mui/material'
import { useLoadingData } from 'src/context/LoadingContext'
import useLayoutDimension from 'src/utils/hooks/useLayoutDimension'
import LottieLoader from './LottieLoader'

const BackdropLoader = () => {
  const { headerHeightPx } = useLayoutDimension()
  const { backdropLoading } = useLoadingData()
  if (!backdropLoading) return null

  return (
    <Box
      position="absolute"
      top={0}
      left={0}
      zIndex={1000}
      width="100%"
      height={`calc(100vh - ${headerHeightPx} + 5px)`}
      display="flex"
      alignItems="center"
      justifyContent="center"
      bgcolor="#0004">
      <LottieLoader white />
    </Box>
  )
}

export default BackdropLoader
