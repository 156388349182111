import { Box, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { NavLink } from 'react-router-dom'
import HomeIcon from 'src/assets/icons/sidebar/home.svg'
import { useAuth } from 'src/context/AuthContext'
import { drawerCloseWidth, drawerWidth } from '..'
import { activeColor, inactiveColor } from './SubNav'

type HomeLinkProps = {
  open: boolean
  closeDrawer: () => void
  sm: boolean
}

const HomePageLink = ({ open, closeDrawer, sm }: HomeLinkProps) => {
  const { posthogCapture } = useAuth()

  return (
    <NavLink
      to={'/home'}
      onClick={() => {
        posthogCapture('sidebar_link_clicked', { to: '/home' })
        closeDrawer()
      }}
      style={{ textDecoration: 'none' }}>
      {({ isActive }) => {
        if (isActive) document.title = 'Loop - My Dashboard'
        return (
          <div>
            <ListItem
              disablePadding
              sx={{
                color: isActive ? activeColor : inactiveColor,
                '&.Mui-selected': { bgcolor: 'transparent' }
              }}>
              <ListItemButton
                selected={isActive}
                sx={{
                  justifyContent: open ? 'initial' : 'center',
                  p: 0,
                  bgcolor: isActive ? '#164B5C !important' : '#122937',
                  '&:hover': { bgcolor: '#143E4E !important' },
                  '& span': { fontWeight: isActive && 600 },
                  transition: 'background-color 150ms'
                }}>
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    width: drawerCloseWidth - 2,
                    transitionDelay: open && '225ms',
                    py: 1.5,
                    bgcolor: !open && 'inherit',
                    zIndex: 10,
                    color: 'inherit',
                    justifyContent: 'center'
                  }}>
                  <img
                    src={HomeIcon}
                    alt="Home Icon"
                    width="22px"
                    height="22px"
                  />
                </ListItemIcon>

                <Box
                  width={sm ? '100%' : drawerWidth - 100}
                  display="flex"
                  alignItems="center">
                  <Box
                    width={sm ? '100%' : drawerWidth - 100}
                    display="flex"
                    alignItems="center"
                    justifyContent="space-between"
                    ml={open || sm ? 0 : -(drawerWidth - drawerCloseWidth)}
                    mr={sm && 2}
                    sx={{
                      transition: '225ms cubic-bezier(0.4, 0, 0.6, 1)'
                    }}>
                    <ListItemText
                      primary="My Dashboard"
                      color="inherit"
                    />
                  </Box>
                </Box>
              </ListItemButton>
            </ListItem>
          </div>
        )
      }}
    </NavLink>
  )
}

export default HomePageLink
